// Customizable Area Start
import React from "react";

import { Box, Button, Checkbox, Dialog, InputAdornment, Switch, TextField, Typography } from "@material-ui/core";
import LinearProgress from '@material-ui/core/LinearProgress';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import AddIcon from '@material-ui/icons/Add';
import {
  createTheme,
  ThemeProvider,
  withStyles
} from "@material-ui/core/styles";
import Select from "react-select";
import FormApprovalWorkflowController, {
  ErrorModalProps,
  Props,
} from "./FormApprovalWorkflowController.web";
import { BackButton, checkImg, checkImgSvg, coverimg, unchecked,Delete, VectorIcon,closeIcon,systemErrorImg } from "./assets";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { configJSON } from "./FormApprovalWorkflowController";
import { styled,Select as MuiSelect, MenuItem, FormControl, InputLabel } from "@material-ui/core";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const IOSSwitch = withStyles((theme) => ({
  root: {
    width: "48px",
    height: "30px",
    padding: 0,
    margin: 0,
    borderRadius: "15px",

  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,
      '& + $track': {
        backgroundColor: '#6551EA',
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: 'red',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: "26px",
    height: "26px",
  },
  track: {
    borderRadius: "15px",
    border: "1px solid #DBDBDB",
    backgroundColor:"#DBDBDB",
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}))(({ classes, ...props }:any) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 7,
    borderRadius: 8,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 8,
    backgroundColor: '#6551EA',
  },
}))(LinearProgress);

const ErrorModal = ({ onClose,errroDescription,className }:ErrorModalProps) => {
  return (
    <ErrorModalContainer>
    <Box className="modal-overlay">
      <Box className="modal-container">
        <Button className="modal-close" onClick={onClose}>
          <img src={closeIcon} alt="closeIocn" width={"40px"} height={"40px"}/>
        </Button>
        <Box className="modal-content" >
          <Box className={className}>
          <img src={systemErrorImg} alt="Error Icon" className="modal-icon" width={"140.34px"} height={"119px"}/>
          <Typography className="system_error_text">{configJSON.systemError}</Typography>
          <Typography className="system_error_desc" >{errroDescription}.</Typography>
          </Box>
          <Button className="modal-button" onClick={onClose}>
             {configJSON.tryAgain}
          </Button>
        </Box>
      </Box>
    </Box>
    </ErrorModalContainer>
  );
};
export default class FormApprovalWorkflow extends FormApprovalWorkflowController {
  constructor(props: Props) {
    super(props);
  }
 
  skipQuestion = (value:string) => {
    this.setState({SurveyQuestion:value})
    localStorage.setItem("surveyQuestion",value)
  }

 
  renderBeautyServiceOptions = () => {
    const { beautyServicesQuestionsList, selectedBeautyServices } = this.state;
  
    return beautyServicesQuestionsList.map((service) => {
      const { id, beauty_service_name } = service.attributes;
      return (
        <Option
          key={id.toString()}
          selected={selectedBeautyServices.includes(id)}
          onClick={() => this.toggleServiceSelection(id)}
        >
          <Typography className="typotext">{beauty_service_name}</Typography>
          <CustomRadio selected={selectedBeautyServices.includes(id)} />
        </Option>
      );
    });
  };

  toggleServiceSelection = (id:number) => {
    const { selectedBeautyServices,businessTypeError } = this.state;
  
    if (selectedBeautyServices.includes(id)) {
      this.setState({
        selectedBeautyServices: selectedBeautyServices.filter((item) => item !== id),
      });
    } else {
      this.setState({
        businessTypeError:false,
        selectedBeautyServices: [...selectedBeautyServices, id],
      });
    }
  };

  renderTeamSizeOptions = () => {
     const { teamSizesQuestionsList, selectedTeamSize } = this.state;
  const initialOption = (
    <Option
      key="initial"
      selected={selectedTeamSize === 0}
      onClick={() => this.selectTeamSizeSelection(0)}
    >
      <Typography className="typotext" style={{textTransform:"none"}}>{configJSON.itsme}</Typography>
      <CustomRadio selected={selectedTeamSize === 0} />
    </Option>
  );

  const optionsFromApi = teamSizesQuestionsList.map((service) => {
    const { id, team_size } = service.attributes;

    return (
      <Option
        key={id.toString()}
        selected={selectedTeamSize === id}
        onClick={() => this.selectTeamSizeSelection(id)}
      >
        <Typography className="typotext" style={{textTransform:"none"}}>{`${team_size} people`}</Typography>
        <CustomRadio selected={selectedTeamSize === id} />
      </Option>
    );
  });

  return [initialOption,...optionsFromApi]
  };

  selectTeamSizeSelection = (id: number) => {
    this.setState({
      selectedTeamSize: id,
      teamSizeError:false
    });
  };

  renderLocalityOptions=()=>{
    const { selectedLocality} = this.state;

    const localityOptions = [
      {
        id: "home",
        label: "At my home",
        description: "Clients visit my home for services.",
      },
      {
        id: "commercial",
        label: "At commercial space(s) I work in",
        description:
          "I provide services in one or more commercial spaces I own or work in.",
      },
      {
        id: "client",
        label: "At the client’s location",
        description: "I travel to the client’s location to provide services.",
      },
    ];
    return(
    <div>
    {localityOptions.map((option) => (
      <Option2
      key={option.id}
      selected={selectedLocality === option.label}
      onClick={() => this.handleLocalitySelect(option.label)}
      data-test-id="locality-option-home"
      >
      <div style={{ display: "flex", flexDirection: "column", padding: "0px 10px" }}>
          <Typography className="typotext2">{option.label}</Typography>
          <Typography className="typosubtext">{option.description}</Typography>
      </div>
          <CustomRadio selected={selectedLocality === option.label} />
       </Option2>
      ))}
      </div>
        )
      }

  handleLocalitySelect = (locality:string) => {
    this.setState({ selectedLocality: locality, localityError: false });
  };
  Question1(){
    return <CenterDiv>
      <div>
      <span className="setup">Account setup</span>
      <Que1HeadingMain>
      What’s your business name?
      </Que1HeadingMain>
      <Sub1Heading>
      This is the brand name your client will see. Your billing can be added later.
      </Sub1Heading>
      <div style={{margin:"30px 0px"}}>
        <div style={{display:"flex",flexDirection:"column"}}>
          <label className="textType">Business name</label>
        <BoxContainer>
        {(this.state.nameError) && (
    <Box className="email_error_width" sx={{ ...webStyle.customTooltip }}>
      <Typography style={webStyle.validationText}>
        {configJSON.businessNameErrorText}
      </Typography>
      <Box sx={webStyle.positionContainer} />
    </Box>
  )}
      </BoxContainer>
      <BoxContainer>
        {(this.state.invalidBusinessName) && (
    <Box className="email_error_width" sx={{ ...webStyle.customTooltip }}>
      <Typography style={webStyle.validationText}>
      {configJSON.invalidBusinessNameErrorText}
      </Typography>
      <Box sx={webStyle.positionContainer} />
    </Box>
  )}
      </BoxContainer>
          <input className="inputField"  data-test-id="business-name-input"  style={{
    border: this.state.nameError || this.state.invalidBusinessName ? "1px solid #E53881" : "1px solid #D9D9D9",
  }} value={this.state.answerOfQuestion.business_name} placeholder="Type your business name here" name="businessname" type="text" id="businessname" onChange={(event)=>{this.inputvalue(event.target.value,"business_name")}} />
        </div>
        <div style={{display:"flex",flexDirection:"column",marginTop:"20px"}}>
          <label className="textType">Website</label>
          <BoxContainer>
        {this.state.websiteError &&(
          <Box className="email_error_width" sx={{...webStyle.customTooltip,}}
          >
            <Typography style={webStyle.validationText}>{configJSON.validUrl}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </BoxContainer>
          <input className="inputField"  data-test-id="website-input" style={{
    border: this.state.websiteError? "1px solid #E53881" : "1px solid #D9D9D9",
  }}  value={this.state.answerOfQuestion.web}  onChange={(event)=>{this.inputvalue(event.target.value,"web")}} placeholder="www.yoursite.com" name="web" type="text" id="web"  />
        </div>
      </div>
      </div>
      <div style={{marginBottom:"20px"}}>
      {this.state.showErrorModal && (
  <ErrorModal onClose={() => this.setState({ showErrorModal: false })} errroDescription={configJSON.businessNameSystemErrorDesc} className="modal_sub_content modal_businees_type_sub_content"/>
)}
      <ContinueBtn data-test-id="continue-button" disabled={this.state.answerOfQuestion.business_name === "" && this.state.answerOfQuestion.web === ""}  onClick={this.handleBusinessNameContinueClick}>Continue</ContinueBtn>
      <SkipBtn onClick={()=>this.skipQuestion("business_type")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv>
  }
  Question2(){
    return <CenterDiv2>
      <div>
      <HeadingMain type="Question2">
      Select your beauty service categories
      </HeadingMain>
      <SubHeading>
      This are the beauty services of your business. They will help customers find you. Select all that apply
      </SubHeading>
      <MainOptionContainer>
      {this.renderBeautyServiceOptions()}
      </MainOptionContainer>
      </div>
      <div style={{margin:"10px 0px"}}>
      <BoxContainer>
      <Box className="center_align">
        {this.state.businessTypeError &&(
          <Box  sx={{...webStyle.customTooltip,...webStyle.businessTypeErrorBgColor}}
          >
            <Typography style={webStyle.validationText}>{configJSON.businessTypeErrorText}</Typography>
            <Box sx={{...webStyle.positionContainer,...webStyle.businessTypeErrorPositionBgColor}}
            />
          </Box>
        )}
        </Box>
      </BoxContainer>
      {this.state.showErrorModal && (
  <ErrorModal onClose={() => this.setState({ showErrorModal: false })} errroDescription={configJSON.businessTyleSystemErrorDesc} className="modal_sub_content modal_businees_type_sub_content"/>
)}
      <ContinueBtn  data-test-id="continue-button" onClick={this.handleBusinessTypeContinueClick}>{configJSON.continue}</ContinueBtn>
      <SkipBtn onClick={this.skipQuestion.bind(this,"team_size")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv2>
  }
  goBack=()=>{
    switch(this.state.SurveyQuestion){
      case "license_expiration":return  this.setState({SurveyQuestion:"certification_number"});
      case "certification_number":return  this.setState({SurveyQuestion:"state_license_validation"});
      case "state_license_validation":return  this.setState({SurveyQuestion:"business_hours"});
      case "business_hours":return  this.setState({SurveyQuestion:"privacy_concerns"});
      case "privacy_concerns":return  this.setState({SurveyQuestion:"business_address"});
      case "business_address":return  this.setState({SurveyQuestion:"locality"});
      case "locality":return  this.setState({SurveyQuestion:"team_size"});
      case "team_size":return  this.setState({SurveyQuestion:"business_type"});
      case "business_type":return  this.setState({SurveyQuestion:"business_name"});

    }
  }
  Question3(){
    return <CenterDiv2>
      <div>
      <HeadingMain type="Question3">
      What’s your team size?
      </HeadingMain>
      <Sub1Heading >
      This will help us set up your calendar correctly and also asses better your roles and permissions.
      </Sub1Heading>
      <MainOptionContainer style={{marginTop:"30px"}}>
      {this.renderTeamSizeOptions()}
      </MainOptionContainer>
      </div>
      <div style={{marginBottom:"20px"}}>
      <BoxContainer>
      <Box className="center_align">
        {this.state.teamSizeError &&(
          <Box  sx={{...webStyle.customTooltip,...webStyle.businessTypeErrorBgColor}}
          >
            <Typography style={webStyle.validationText}>{configJSON.teamSizeErrorText}</Typography>
            <Box sx={{...webStyle.positionContainer,...webStyle.businessTypeErrorPositionBgColor}}
            />
          </Box>
        )}
        </Box>
      </BoxContainer>
      {this.state.showErrorModal && (
  <ErrorModal onClose={() => this.setState({ showErrorModal: false })} errroDescription={configJSON.teamSizeSysytemErrorDesc} className="modal_sub_content modal_team_size_sub_content"/>
)}
      <ContinueBtn onClick={this.handleTeamSizeContinueClick}>{configJSON.continue}</ContinueBtn>
      <SkipBtn onClick={this.skipQuestion.bind(this,"locality")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv2>
  }
  Question4(){
    const {selectedLocality}=this.state
    return <CenterDiv2 style={{height:"100%"}}>
      <div>
      <Que1HeadingMain style={{width:"243px"}}>
      Where do you work?
      </Que1HeadingMain>
      <Sub1Heading >
      Do your clients come to you or do you go to them?
      (Select all that apply)
      </Sub1Heading>
      <MainOptionContainer style={{marginTop:"30px"}}>
      {this.renderLocalityOptions()}
      </MainOptionContainer>
      </div>
      <div style={{marginBottom:"20px"}}>
      <BoxContainer>
      <Box className="center_align">
        {this.state.localityError &&(
          <Box  sx={{...webStyle.customTooltip,...webStyle.businessTypeErrorBgColor}}
          >
            <Typography style={webStyle.validationText}>{configJSON.localityErrorText}</Typography>
            <Box sx={{...webStyle.positionContainer,...webStyle.businessTypeErrorPositionBgColor}}
            />
          </Box>
        )}
        </Box>
      </BoxContainer>
      {this.state.showErrorModal && (
  <ErrorModal onClose={() => this.setState({ showErrorModal: false })} errroDescription={configJSON.localitySystemErrorDesc} className="modal_sub_content modal_businees_type_sub_content"/>
)}
      <ContinueBtn data-test-id="continue-button" onClick={this.handleLocalityContinueClick}>Continue</ContinueBtn>
      <SkipBtn onClick={this.skipQuestion.bind(this,"business_address")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv2>
  }
  Question5() {
    return <CenterDiv3>
      <div>
      <div style={{ height: "100px", display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
        <div className="mainHeading" style={{width:"307px",whiteSpace:"nowrap"}}>
          Set your location address
        </div>
        <SubHeading style={{fontSize:"16px",lineHeight:"22.4px"}}>
          Add your business location so your clients can easily find you.
        </SubHeading>
      </div>
      <div style={{height:"150px",display:"flex",justifyContent:"space-between",flexDirection:"column",marginTop:"15px"}}>
          <div className="typotext" style={{width:"176px",whiteSpace:"nowrap",margin:"5px 0px"}}>Wheres your business located?</div>
          <TextField id="outlined-basic"  variant="outlined" style={{height:"44px",maxWidth:"358px",borderRadius:"6px" }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon style={{color:"#D9D9D9"}} />
                </InputAdornment>
              ),
            }}
          />
          <AddLocationButton>
            <AddIcon />
            <span>Add Location</span>
          </AddLocationButton>
          <div style={{display:"flex",justifyContent:"center",alignItems:"center", marginTop:"5px"}}>
          <CustomizeCheckBox
                            data-test-id="checkBox"
                            checked={this.state.isChecked}
                            checkedIcon={<img src={checkImgSvg} />}
                            icon={<img src={unchecked} />}
                            onChange={()=>this.setState({isChecked:!this.state.isChecked})}
                        />
            <div className="locationCheckbox">
            I don’t have a business address (mobile and online services only)
            </div>
          </div>
      </div>
      </div>
      <div style={{ marginBottom:"20px" }}>
        <ContinueBtn onClick={this.nextQuestion.bind(this, "privacy_concerns")}>Continue</ContinueBtn>
        <SkipBtn onClick={this.skipQuestion.bind(this, "privacy_concerns")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv3>
  }
  Question6(){
    return <CenterDiv2 style={{height:"100%"}}>
      <div>
      <div style={{height:"100px",display:"flex",justifyContent:"space-between",flexDirection:"column"}}>
      <div className="mainHeading">
      Manage Your Privacy
      </div>
      <div className="subHeading5" style={{width:"350px",height:"45px"}}>
      Control your account visibility. By default, your account is public. You can make it private to limit access and hide your availability.
      </div>
      </div>
      <div style={{display:"flex",marginTop:"60px"}}>
      <div style={{height:"90px",display:"flex",justifyContent:"space-between",flexDirection:"column"}}>
      <div className="mainHeading2">
      Private Account
      </div>
      <div className="subHeading5" style={{width:"275px",height:"45px"}}>
      When private, your account is hidden from users. They won’t be able to view your profile or schedule with you.
      </div>
      </div>
      <div style={{display:"flex",justifyContent:"center",alignItems:"center",paddingLeft:"20px"}}>
      <FormControlLabel
        control={<IOSSwitch data-test-id="privacy-switch" checked={this.state.privacyConcerns} onChange={this.switchToggle}  name="checkedB" />}
        label=""
      />
      </div>
      </div>
      </div>
      <div style={{marginBottom:"20px"}}>
      {this.state.showErrorModal && (
  <ErrorModal onClose={() => this.setState({ showErrorModal: false })} errroDescription={configJSON.privacyConcernSystemErrorDesc} className="modal_sub_content modal_privacy_sub_content"/>
)}
      <ContinueBtn data-test-id="continue-button" onClick={this.handlePrivacyConcernsContinueClick}>Continue</ContinueBtn>
      <SkipBtn onClick={this.skipQuestion.bind(this,"business_hours")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv2>
  }
  handleHrChange = (event:any) => {
    this.setState({ openingHr: event.target.value });
  };
  renderModal=()=>{
    
    return  <CustomDialog
    open={this.state.hourPopUp}
  >
    <MainDialogContainer>
        <div style={{width:"100%"}}>
          <CustomArrowBackwardIcon onClick={this.openHrPopUp} />
          <span style={{fontFamily:"DegularSemibold",fontSize:"28px",fontWeight:700,lineHeight:"39.2px",letterSpacing:"1%"}}>Monday</span>
        </div>
        <div className="subHeading0">
        Set your business hours here. Head to Opening Calendar from Settings menu if you need to adjust hours for a  single day.
        </div>
        <div className="opnghrs">Opening hours
      </div>
      <div style={{display:"flex", gap:"6px",position:"relative",margin:"5px 0px"}}>
       <CustomFormControl>
        <CustomMUISelect
          labelId="time-select-label"
          value={this.state.openingHr}
          onChange={this.handleHrChange}
          IconComponent={ExpandMoreIcon} 
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {this.state.timeList.map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </CustomMUISelect>
      </CustomFormControl> 
      <CustomFormControl>
        <MuiSelect
          labelId="time-select-label"
          value={this.state.openingHr}
          onChange={this.handleHrChange}
          IconComponent={ExpandMoreIcon} 
          MenuProps={{
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            getContentAnchorEl: null,
          }}
        >
          {this.state.timeList.map((time) => (
            <MenuItem key={time} value={time}>
              {time}
            </MenuItem>
          ))}
        </MuiSelect>
      </CustomFormControl>      
      </div>
      {   this.state.breakList.length > 0  && 
      <>
         <div className="opnghrs">Break</div>
         {
        
          this.state.breakList.map((item)=>(
            <div style={{display:"flex", gap:"6px",position:"relative",margin:"5px 0px",alignItems:"center"}}>
            <CustomFormControl>
             <CustomMUISelect
               labelId="time-select-label"
               value={this.state.openingHr}
               onChange={this.handleHrChange}
               IconComponent={ExpandMoreIcon} 
               MenuProps={{
                 anchorOrigin: {
                   vertical: "bottom",
                   horizontal: "left",
                 },
                 transformOrigin: {
                   vertical: "top",
                   horizontal: "left",
                 },
                 getContentAnchorEl: null,
               }}
             >
               {this.state.timeList.map((time) => (
                 <MenuItem key={time} value={time}>
                   {time}
                 </MenuItem>
               ))}
             </CustomMUISelect>
           </CustomFormControl> 
           <CustomFormControl>
             <MuiSelect
               labelId="time-select-label"
               value={this.state.openingHr}
               onChange={this.handleHrChange}
               IconComponent={ExpandMoreIcon} 
               MenuProps={{
                 anchorOrigin: {
                   vertical: "bottom",
                   horizontal: "left",
                 },
                 transformOrigin: {
                   vertical: "top",
                   horizontal: "left",
                 },
                 getContentAnchorEl: null,
               }}
             >
               {this.state.timeList.map((time) => (
                 <MenuItem key={time} value={time}>
                   {time}
                 </MenuItem>
               ))}
             </MuiSelect>
           </CustomFormControl>   
           <img src={Delete} alt="delete" style={{height:"20px",width:"18px"}} onClick={()=>this.deleteList(item.key)} />
           </div>
          ))
         }
     
      </>
      }
    <div style={{marginTop:"10px",width:"100%",borderBottom:"1px solid #D9D9D9",display:"flex",alignItems:"center",gap:8,padding:"5px 0px",fontSize:"16px",fontWeight:400,fontFamily:"Degular"}}>
       <AddIcon style={{color:"#D9D9D9"}} onClick={this.addBreak} /> <span style={{color:"#6551EA",cursor:"pointer"}} onClick={this.addBreak}> Add Break </span>
    </div>
    <div style={{display:"flex",justifyContent:"flex-end",alignItems:"center",marginTop:"50px",gap:7,marginBottom:"10px"}}>
      <ContinueBtn style={{width:"86px",marginBottom:0,color:"black",height:"45px",background:"white",border:"1px solid #D9D9D9"}} onClick={this.openHrPopUp}>Cancel</ContinueBtn>
      <ContinueBtn style={{width:"86px",height:"45px",marginBottom:0}} onClick={this.openCopyPopUp}>Save</ContinueBtn>
    </div>
    </MainDialogContainer>
  </CustomDialog>
  }
  renderCopyHrsModal = ()=>{
    return <CustomDialog open={this.state.copyPopUp}>
      <MainDialogContainer>
      <div style={{width:"100%"}}>
          <span style={{fontFamily:"DegularSemibold",fontSize:"28px",fontWeight:700,lineHeight:"39.2px",letterSpacing:"1%"}}>Copy business hours?</span>
        </div>
        <div className="subHeading0">
        Would you like to apply the set business hours to other days? If so, select days from the list
        </div>
        <CopyContainer style={{marginTop:"5px"}}>
          {
            this.state.weekDay.map((item)=>(
              <DayContainer>
            <CheckboxCustom
              defaultChecked
              color="primary"
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
           <span style={{fontSize:"16px",fontFamily:"Degular",fontWeight:600}}> {item.value}</span>
            </DayContainer>
            ))
          }
            
        </CopyContainer>
        <div style={{marginTop:"30px",display:"flex",justifyContent:"center",flexDirection:"column"}}>
          <ContinueBtn style={{marginBottom:"0px"}} onClick={this.openCopyPopUp}>Continue</ContinueBtn>
          <ContinueBtn style={{backgroundColor:"white",color:"black",border:"1px solid #D9D9D9",marginTop:"5px"}} onClick={this.openCopyPopUp}>No, Thanks</ContinueBtn>
        </div>
      </MainDialogContainer>
    </CustomDialog>
  }
  Question7(){
    return <CenterDiv2 style={{ width: "353px", minHeight: "709px" }}>
      <div>
      <div style={{ height: "100px", display: "flex", justifyContent: "space-between", flexDirection: "column", marginBottom:"50px"}}>
        <div className="mainHeading7">
          Add Your Business Hours
        </div>
        <div className="subHeading7" >
          Add your business location so your clients can easily find you.
        </div>
      </div>
        <div className="hrsMainContainer">
          {
            this.state.weekDay.map((item) => (
              <div key={item.value} className="hourContainer">
                <div style={{ width: "100px", whiteSpace: "nowrap" }}>
                  <IOSSwitch checked={item.clicked} onClick={() => this.handleDaySwitch(item.value)} />
                  <span className="day">{item.value}</span>
                </div>
                <span className="typotext" style={{ marginLeft: "10px", fontWeight: 400, width: "111px", whiteSpace: "nowrap" }}>{item.clicked ? "10:00 AM - 7:00 PM" : "Closed"}</span>
                <CustomArrowForwardIosIcon onClick={this.openHrPopUp} />
              </div>
            ))
          }
        </div>
         
      </div>
      <div style={{ marginBottom: "20px" }}>
        <ContinueBtn onClick={this.nextQuestion.bind(this, "state_license_validation")}>Continue</ContinueBtn>
        <SkipBtn onClick={this.skipQuestion.bind(this, "state_license_validation")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv2>
  }
  Question9(){
    return <CenterDiv2 style={{ width: "360px", minHeight: "609px" }}>
      <div>
      <div style={{ display: "flex", gap:15, justifyContent: "space-between", flexDirection: "column", marginBottom:"30px"}}>
        <div className="mainHeadingLast">
           {configJSON.lincenseExpHeading}
        </div>
        <div className="subHeading7" style={{width:"350px"}} >
        {configJSON.lincenseSubHeading}
        </div>
      </div>
      <div>
        <div className="licenseLabel">License or Certification number*</div>
        <input type="text" className="inputField"/>
      </div>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <ContinueBtn onClick={this.nextQuestion.bind(this, "license_expiration")}>Continue</ContinueBtn>
        <SkipBtn onClick={this.skipQuestion.bind(this, "license_expiration")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv2>
  }
  handleDateChange=(data:any)=>{
    this.setState({licenseDate:data.target.value})
  }
  handleMonthChange = (data:any)=>{
    this.setState({monthPlaceholder:data.target.value})
  }
  handleYearChange=(data:any)=>{
    this.setState({yearPlaceholder:data.target.value})
  }
  renderDate = (days:any,monthData:any,years:any) => {
    const customIcon = <ExpandMoreIcon style={{ position: "absolute", top: "50%", right: "5px", transform: "translateY(-50%)" }}
    />;
    return (
      <Box className="input_field_box" gridGap={5}>
        <FormControl className="date_select_field">
          <MuiSelect
            data-test-id="dobTestID"
            defaultValue={Date}
            className="date_select_field"
            variant="outlined"
            displayEmpty
            labelId="Date"
            inputProps={{ "aria-label": "Without label" }}
            value={this.state.licenseDate}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleDateChange({ target: { value: parseInt(event.target.value as string, 10) } })}
            open={this.state.dateFeildOpen} 
            onClose={this.handleDateFieldClose}
            onOpen={this.handleDateFieldOpen} 
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "5px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => <div data-test-id="dobDate" onClick={this.handleDateIconClick} style={{cursor:"pointer"}}>
            {customIcon} 
          </div>}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.datePlaceholder}>{this.state.datePlaceholder}</MenuItem>
            {days.map((item: number) => (
              <MenuItem key={item} value={item} style={{fontSize:"14px",fontWeight:400}}
              >
                {item}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
        <FormControl className="date_select_field">
          <MuiSelect
            data-test-id="dobMonthTestID"
            className="date_select_field"
            variant="outlined"
            displayEmpty
            labelId="Month"
            inputProps={{ "aria-label": "Without label" }}
            placeholder="Month"
            value={this.state.monthPlaceholder}
            onChange={this.handleMonthChange}
            open={this.state.monthFeildOpen} 
            onClose={this.handleMonthFieldClose} 
            onOpen={this.handleMonthFieldOpen} 
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "5px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => <div data-test-id="dobDate" onClick={this.handleMonthIconClick} style={{cursor:"pointer"}}>
            {customIcon} 
          </div>}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.monthPlaceholder}>{this.state.monthPlaceholder}</MenuItem>
            {monthData.map((item:string) => (
              <MenuItem key={item} value={item} style={{fontSize:"14px",fontWeight:400}}>
                {item}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
        <>
        <Box>

      </Box>
        <FormControl className="date_select_field" style={{cursor:"pointer"}}>
          <MuiSelect
            data-test-id="dobYearTestID"
            className="date_select_field"
            variant="outlined"
            displayEmpty
            labelId="Year"
            inputProps={{ "aria-label": "Without label" }}
            onChange={this.handleYearChange}
            open={this.state.yearFeildOpen} 
            onClose={this.handleYearFieldClose} 
             onOpen={this.handleYearFieldOpen}  
            placeholder="Year"
            value={this.state.yearPlaceholder}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "5px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => <div data-test-id="dobDate" onClick={this.handleYearIconClick} style={{cursor:"pointer"}}>
            {customIcon} 
          </div>}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.yearPlaceholder}>{this.state.yearPlaceholder}</MenuItem>
            {years.map((item: number) => (
              <MenuItem key={item} value={item} style={{fontSize:"14px",fontWeight:400}}>
                {item}
              </MenuItem>
            ))}
          </MuiSelect>
        </FormControl>
        </>
      </Box>
    )
  }
  Question8() {
    return <CenterDiv3>
      <div>
      <div style={{display: "flex", justifyContent: "space-between", flexDirection: "column" }}>
        <div className="mainHeading" style={{width:"325px",whiteSpace:"nowrap"}}>
        Where is your license valid?
        </div>
        <SubHeading style={{height:"65px",textAlign:"justify",letterSpacing:"-1%"}}>
         Select the state or country where your license is valid to verify your professional credentials and help clients trust your expertise.
        </SubHeading>
      </div>
      <div className="stateLabel">
      State or country where your license is valid
      </div>
      {
        this.state.stateFields.map((item,index)=>(
          <div style={{width:"100%",margin:"10px 0px"}}>
          <CustomFormControl2>
            <CustomMUISelect
              labelId="time-select-label"
              value={this.state.stateFields[index]}
              onChange={(event) => this.handleFieldChange(index, event)}
              IconComponent={ExpandMoreIcon} 
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left",
                },
                getContentAnchorEl: null,
              }}
            >
              {this.state.stateList.map((time) => (
                <MenuItem key={time} value={time}>
                  {time}
                </MenuItem>
              ))}
            </CustomMUISelect>
          </CustomFormControl2> 
          </div>
        ))
      }
      <div style={{marginTop:"10px",width:"100%",display:"flex",alignItems:"center",gap:8,padding:"5px 0px",fontSize:"16px",fontWeight:400,fontFamily:"Degular"}}>
       <AddIcon style={{color:"#D9D9D9"}} onClick={this.addField} /> <span style={{color:"#6551EA",cursor:"pointer"}} onClick={this.addField}> Add Another State </span>
    </div>
      </div>

      <div style={{ marginBottom:"20px" }}>
        <ContinueBtn onClick={this.nextQuestion.bind(this, "certification_number")}>Continue</ContinueBtn>
        <SkipBtn onClick={this.skipQuestion.bind(this, "certification_number")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv3>
  }
  Question10(){
    const currentYear = new Date().getFullYear();
    const days = Array.from({ length: 31 }, (_, i) => i + 1);
    const monthData = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const years = Array.from({ length: 8 }, (_, i) => currentYear + i);
  
    return <CenterDiv2 style={{ width: "360px", minHeight: "509px" }}>
      <div>
      <div style={{ display: "flex", gap:15, justifyContent: "space-between", flexDirection: "column", marginBottom:"30px"}}>
        <div className="mainHeadingLast">
        What is the expiration date
        of your license?
        </div>
        <div className="subHeading7" style={{width:"350px"}} >
        This are the beauty services of your business. They will help customers find you. Select all that apply
        </div>
      </div>
      <div>
        <div className="licenseLabel">Expiration Date</div>
          {this.renderDate(days,monthData,years)}
      </div>
      </div>
      <div style={{ marginBottom: "20px" }}>
        <ContinueBtn onClick={this.nextQuestion.bind(this, "license_expiration")}>Continue</ContinueBtn>
        <SkipBtn onClick={this.skipQuestion.bind(this, "license_expiration")}>Skip This Step</SkipBtn>
      </div>
    </CenterDiv2>
  }
  render() {
    return (
      <MainContainer>
      <LeftSideDiv>
        {this.renderModal()}
        {this.renderCopyHrsModal()}
        <ButtonContainer data-test-id="navFormButtons" onClick={()=>this.goBack()}>
          <div style={{display:"flex",justifyContent:"ceter",alignItems:"center"}} >
          <img src={BackButton} alt="BackButton" className="backbtn"/>
          <span className="backText">Back</span>
          </div>
          <div style={{width:"140px",marginTop:"8px"}}>
          <BorderLinearProgress variant="determinate" value={50} />
          </div>
          <img src={VectorIcon} alt="logo" style={{height:"39px",width:"40px"}} />
        </ButtonContainer>
        {(()=>{
          switch(this.state.SurveyQuestion){
            case "business_type": return this.Question2()
            case "team_size": return this.Question3()
            case "locality": return this.Question4()
            case "business_address": return this.Question5()
            case "privacy_concerns": return this.Question6()
            case "business_hours": return this.Question7()
            case "state_license_validation": return this.Question8()
            case "certification_number": return this.Question9()
            case "license_expiration": return this.Question10()

            default :{return this.Question1()}
          }
        })()
        }
      </LeftSideDiv>
      <RightSideDiv>
        <Box style={{height:"100%",width:"100%",backgroundImage:`url(${coverimg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
        </Box>
      </RightSideDiv>
  </MainContainer >

      // Customizable Area End
    );
  }
}
const MainContainer = styled(Box)({
  "& .backbtn": {
    height: "40px",
    width: "40px",
    cursor: "pointer",
    marginRight: "10px",
  },
  "& .backText": {
    color: "#757676",
    fontSize: "16px",
    display: "block",
    fontWeight: 500,
    fontFamily: "Inter",
    cursor:"pointer",
    "@media (max-width:598px)": {
      display: "none",
    }
  },
  "& .stateLabel":{
    fontSize:"12px",
    fontFamily:"inter",
    fontWeight:500,
    lineHeight:"16px",
    letterSpacing:"-2%",
    color:"#344054",
    marginBottom:"10px"
  },
  "& .inputField":{
    width:"358px",
    height:"44px",
    borderRadius:"6px",
    fontSize:"14px",
    marginBottom:"5px",
    fontWeight:500,
    fontFamily:"Degular",
    color:"#000000",
    padding:"0px 10px", 
    border: "1px solid #D9D9D9",
    "&::placeholder": {
      color: "#757676",
      fontSize: "14px",
      fontWeight:400,
      fontFamily:"Degular"
    },
    "@media (max-width:598px)": {
      width: "353",
    },
  },  
  "& .setup":{
    fontFamily:"Degular",
    fontSize:"14px",
    fontWeight:600,
    color:"#757676",
    lineHeight:"19.6px",
    letterSpacing:"-1%"
  },
  "& .textType":{
    fontSize:"12px",
    fontWeight:500,
    fontFamily:"DegularSemibold",
    lineHeight:"16.8px",
    marginBottom:"10px"
  },
  "& .typotext":{
    fontSize:"12px",
    fontWeight:500,
    fontFamily:"inter",
    lineHeight:"16.8px",
    letterSpacing:"-1%",
    textTransform:"capitalize"
  },
  "& .typotext2":{
    fontSize:"12px",
    fontWeight:600,
    fontFamily:"inter",
    lineHeight:"16.8px",
    letterSpacing:"-1%",
    color:"#000000"
  },
  "& .typosubtext":{
    width:"277px",
    fontSize:"12px",
    fontWeight:400,
    fontFamily:"inter",
    lineHeight:"16.8px",
    letterSpacing:"-1%",
    margin:"2px 0px",
    color:"#8C8B88"
  },
  "& .mainHeading":{
    width:"264px",
    height:"36px",
    fontSize:"26px",
    fontWeight:700,
    fontFamily:"inter",
    letterSpacing:"-1%",
    lineHeight:"36.4px"
  },

  "& .locationCheckbox":{
    width:"315px",
    height:"34px",
    fontSize:"12px",
    fontWeight:400,
    fontFamily:"inter",
    letterSpacing:"-1%",
    lineHeight:"16.8px",
    marginLeft:"5px"
  },
  "& .mainHeading2":{
    fontSize:"18px",
    fontWeight:600,
    fontFamily:"inter",
    letterSpacing:"-1%",
    lineHeight:"25.2px"
  },
  "& .subHeading5":{
    fontSize:"14px",
    fontWeight:400,
    fontFamily:"inter",
    letterSpacing:"-1%",
    lineHeight:"19.6px",
    color:"#757676"
  },

  "& .mainHeading7":{
    height:"39px",
    width:"299px",
    fontSize:"28px",
    fontWeight:700,
    fontFamily:"DegularSemibold",
    letterSpacing:"-1%",
    lineHeight:"38.2px",
    color:"#2E2E2E"
  },
  "& .mainHeadingLast":{
    height:"68px",
    width:"353px",
    fontSize:"28px",
    fontWeight:700,
    fontFamily:"DegularSemibold",
    lineHeight:"33.6px",
    color:"#2E2E2E"
  },
  "& .subHeading7":{
    fontSize:"16px",
    fontWeight:400,
    fontFamily:"Degular",
    letterSpacing:"-1%",
    lineHeight:"22.4px",
    color:"#757676"
  },
  "& .day":{
    fontSize:"14px",
    marginLeft:"10px",
    fontWeight:600,
    fontFamily:"inter",
    letterSpacing:"-1%",
    lineHeight:"19.6px",
    color:"#000000"
  },
  "& .hourContainer":{
    width:"100%",
    padding:"10px 0px",
    borderBottom:"1px solid #D9D9D9",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center"
  },
  "& .hrsMainContainer":{
    width:"100%",
    display:"flex",
    flexDirection:"column",
  },
  "& .input_field_box": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom:"20px",
    position:"relative"
  },
  "& .date_select_field": {
    width: "100%",
    height: "44px",
    color: "#757676",
    borderRadius: "6px",
    "@media (max-width:598px)": {
      width: "108px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px"
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":{
       border:"none",
    }
  },
  "& .licenseLabel":{
    fontSize:"14px",
    fontWeight:500,
    fontFamily:"Degular",
    lineHeight:"19.6px",
    marginBottom: "5px",
  },
  display: "flex",
  flexDirection: "row",
});
const MainDialogContainer = styled(Box)(({theme})=>({
  maxHeight:"700px",
  [theme.breakpoints.down('sm')]: {
    width: '330px', 
  },

}))

const CheckboxCustom = styled(Checkbox)({
height:"18px",
width:"18px",
"& .MuiSvgIcon-root":{
  fontSize:"2rem"
},
"& .MuiCheckbox-root": {
  borderRadius: '8px'
}
})

const LeftSideDiv = styled(Box)({
  width: "46vw",
  position:"relative",
  padding: "0px 30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media (max-width:598px)": {
    width: "100vw",
    padding: "0px 10px",
  },

})
const CustomArrowForwardIosIcon = styled(ArrowForwardIosIcon)({
  color:"#757676",
  "&.MuiSvgIcon-root":{
    fontSize:"14px"
  }
})
const CustomArrowBackwardIcon = styled(ArrowBackIosIcon)({
  color:"#757676",
  marginRight:"30px",
  "&.MuiSvgIcon-root":{
    fontSize:"14px"
  }
})

const CopyContainer = styled(Box)({
  display:"flex",
  flexDirection:"column"
})

const DayContainer = styled(Box)({
  display:"flex",
  gap:5,
  padding:"10px 0px",
  alignItems:"center",
  borderBottom:"1px solid #D9D9D9"
})
const CustomMUISelect = styled(MuiSelect)({
  "& .MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded":{
    top:"450px",
 },
})
const CustomFormControl = styled(FormControl)({
 "& .MuiSelect-root":{
  border:"1px solid #D9D9D9",
  borderRadius:"6px",
  margin:0,
  width:"90px",
  display:"flex",alignItems:"center",
  height:"44px",
  padding:"0px 15px",
  fontSize:"14px",
  fontFamily:"inter",
  fontWeight:500,
 },

 "& .MuiOutlinedInput-root": {
        border: "none", 
        "&:hover":{
          borderBottom:"2px solid red"
        }
      },
 "& .MuiInput-underline::before":{
  borderBottom:"none",
  "&:hover":{
    borderBottom:"2px solid red"
  }
 },
 "& .MuiInput-underline::after":{
  borderBottom:"none",
  "&:hover":{
    borderBottom:"2px solid red"
  }
 },
 "& .MuiInput-underline":{
  borderBottom:"none !important",

 },
 "& .MuiInput-underline:hover:not(.Mui-disabled)::before":{
  borderBottom:"none"
 },
 "& .MuiPopover-paper":{
    top:"450px",
    backgroundColor:"red"
 }


})
const CustomFormControl2 = styled(FormControl)({
  "& .MuiSelect-root":{
   border:"1px solid #D9D9D9",
   borderRadius:"6px",
   margin:0,
   width:"353px",
   display:"flex",alignItems:"center",
   height:"44px",
   padding:"0px 15px",
   fontSize:"14px",
   fontFamily:"inter",
   fontWeight:500,
  },
 
  "& .MuiOutlinedInput-root": {
         border: "none", 
         "&:hover":{
           borderBottom:"2px solid red"
         }
       },
  "& .MuiInput-underline::before":{
   borderBottom:"none",
   "&:hover":{
     borderBottom:"2px solid red"
   }
  },
  "& .MuiInput-underline::after":{
   borderBottom:"none",
   "&:hover":{
     borderBottom:"2px solid red"
   }
  },
  "& .MuiInput-underline":{
   borderBottom:"none !important",
 
  },
  "& .MuiInput-underline:hover:not(.Mui-disabled)::before":{
   borderBottom:"none"
  },
  "& .MuiPopover-paper":{
     top:"450px",
     backgroundColor:"red"
  }
 
 
 })

const MainOptionContainer = styled(Box)({
  width:"353px",
  maxWidth:"353px",
  height:"350px",
  overflow: "scroll",
  display: "flex",
  scrollbarWidth:"none",
  flexDirection: "column",
  margin:"10px 0px",
  alignItems: "center",
  "@media (max-width:598px)": {

  }
})

const CustomizeCheckBox = styled(Checkbox)({
  marginLeft:"-10px",
"& .MuiSvgIcon-root":{
              width:"23px",
              height:"23px",
              borderRadius:"6px"
            },
            "& .MuiCheckbox-root":{
              color:"#6551EA !important",
            }
})

const ButtonContainer = styled(Box)({
  width: "100%",
  height: "50px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  alignContent:"center",
  padding:"20px",
})
const HeadingMain = styled("div")(({ type }: any) => ({
  width:type === "Question3" ? "278px":"353px",
  height:type === "Question3" ? "39px":"68px",
  fontSize:"28px",
  fontWeight:600,
  lineHeight:"33.6px",
  letterSpacing:"1%",
  color:"#292D31",
  fontFamily:"Degular",
  margin:"10px 0px"

}))
const Que1HeadingMain = styled(Box)({
  width:"341px",
  height:"39px",
  fontSize:"28px",
  fontWeight:600,
  lineHeight:"39.2px",
  letterSpacing:"1%",
  color:"#292D31",
  fontFamily:"Degular",
  margin:"10px 0px 0px 0px"

})
const SubHeading = styled(Box)({
  width:"350px",
  height:"45px",
  fontSize:"16px",
  fontWeight:400,
  lineHeight:"22.4px",
  color:"#757676",
  fontFamily:"Degular",
  letterSpacing:"-1%",
  margin:"20px 0px"
})

const AddLocationButton = styled("div")({
  width:"116px",
  minHeight:"41px",
  fontSize:"12px",
  borderRadius:"6px",
  border:"1px solid #D9D9D9",
  fontWeight:600,
  fontFamily:"inter",
  letterSpacing:"-1%",
  display:"flex",
  justifyContent:"center",
  alignItems:"center",
  marginTop:"20px"
})
const Sub1Heading = styled(Box)({
  width:"350px",
  height:"45px",
  fontSize:"16px",
  fontWeight:400,
  lineHeight:"22.4px",
  color:"#757676",
  fontFamily:"Degular",
  letterSpacing:"-1%",
  margin:"10px 0px"
})
const ContinueBtn = styled(Button)({
  backgroundColor:"#6551EA",
  width:"358px",
  maxWidth:"358px",
  fontFamily: "Degular",
  fontSize: "18px",
  height:"44px",
  color:"white",
  fontWeight: 400,
  textAlign: "center",
  textTransform:"capitalize",
  borderRadius:"6px",
  marginBottom:"30px",
  "&.MuiButton-root.Mui-disabled":{
    color:"white !important",
    backgroundColor:"#D9D9D9 !important",
    
  },
    // backgroundColor:"#D9D9D9 !important"
  
  "&:hover":{
    backgroundColor:"#6551EA"
  },
  "@media (max-width:598px)": {
    width: "353px",
  },
})

const CenterDiv = styled(Box)({
  width: "370px",
  display: "flex",
  minHeight:"690px",
  height:"690px",
  flexDirection:"column",
  marginTop:"120px",
  alignItems:"center",
  justifyContent:"space-between",
})
const CenterDiv2 = styled(Box)({
  width: "370px",
  minHeight:"690px",
  height:"690px",
  display: "flex",
  flexDirection:"column",
  marginTop:"50px",
  justifyContent:"space-between",
  alignItems:"center"
})
const CenterDiv3 = styled(Box)({
  width: "370px",
  minHeight:"600px",
  height:"600px",
  display: "flex",
  flexDirection:"column",
  marginTop:"150px",
  justifyContent:"space-between",
  alignItems:"center"
})

const SkipBtn = styled(Box)({
  width: "370px",
  display: "flex",
  justifyContent:"center",
  alignItems:"center",
  fontSize:"12px",
  fontWeight:500,
  fontFamily:"inter",
  lineHeight:"16.8px",
  letterSpacing:"-2%",
  cursor:"pointer",
  color:"#6551EA",
  "@media (max-width:598px)": {
    width: "353px",
  },
})
const Option = styled("div")(({ selected }: any) => ({
  display: "flex",
  width:"100%",
  minHeight: "67px",
  alignItems: "center",
  justifyContent: "space-between",
  border: selected ? "2px solid #6551EA" : "2px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "6px",
  padding: "0px 10px",
  marginBottom: "10px",
  boxSizing:"border-box",
  cursor: "pointer",
  backgroundColor: selected ? "rgba(101, 81, 234, 0.15)" : "#fff",
  transition: "border 0.3s",
  "&:hover": {
    borderColor: "#6551EA",
  },
}));
const Option2 = styled("div")(({ selected }: any) => ({
  display: "flex",
  width:"353px",
  minHeight: "99px",
  alignItems: "center",
  justifyContent: "space-between",
  border: selected ? "2px solid #6551EA" : "2px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "6px",
  padding: "0px 5px",
  marginBottom: "10px",
  boxSizing:"border-box",
  cursor: "pointer",
  transition: "border 0.3s",
  "&:hover": {
    borderColor: "#6551EA",
  },
}));

const CustomRadio = styled("div")(({ selected }: any) => ({
  width: "24px",
  height: "24px",
  border: `2px solid ${selected ? "#6e00ff" : "rgba(0, 0, 0, 0.3)"}`,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "border 0.3s",
  backgroundColor: selected ? "#6e00ff" : "#fff",
  color: "#fff",
  "&::before": {
    content: selected ? "'✔'" : "''",
    fontSize: "14px",
    fontWeight: "bold",
  },
}));

const CustomDialog = styled(Dialog)({
    "& .MuiBackdrop-root":{
      width: "50% !important",
      "@media (max-width:598px)": {
        width: "100% !important",
      }
    },
    "& .MuiSelect-icon":{right:10},
    "& .MuiDialog-container":{
      width: "50%",
      "@media (max-width:598px)": {
        width: "100%",
      }
    },
    "& .MuiDialog-paper":{
      paddingTop:"20px",
      maxHeight:"800px",
      maxWidth:"379px",
      width:"379px",
      borderRadius:"8px",
      alignItems:"center",
      "@media (max-width:598px)": {
        maxWidth: "100%",
        margin:"32px 32px 32px 10px"

      }
    },
    "& .subHeading0":{
      marginTop:"20px",
      fontSize:"14px",
      width:"343px",
      height:"45px",
      fontWeight:400,
      paddingRight:"5px",
      boxSizing:"border-box",
      fontFamily:"Degular",
      letterSpacing:"1%",
      lineHeight:"19.6px",
      color:"#757676"
    },
    "& .opnghrs":{
      marginTop:"25px",
      width:"350px",
      height:"45px",
      fontSize:"16px",
      fontFamily:"Degular",
      fontWeight:400,
      lineHeight:"22.4px",
      display:"flex",alignItems:"center",
    }
    
});

const RightSideDiv = styled(Box)({
  width: "50vw",
  height: "100vh",
  position:"fixed",
  right:0,
  "@media (max-width:598px)": {
    display: "none"
  }
})
const webStyle = {
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  customTooltip: {
    marginTop: '-25px',
    backgroundColor: '#E53881',
    color: 'white',
    padding: '8px 10px',
    position: 'relative',
    zIndex:99,
    borderRadius:"3px",
  },
  positionContainer: {
     position: 'absolute',
     top: '100%',
     left: '20%',
     transform: 'translateX(-50%)',
     borderTop: '10px solid #E53881',
     borderLeft: '10px solid transparent',
     borderRight: '10px solid transparent',          
  },
  validationText:{
    fontSize:"10px",
    color:"#fff",
    fontWeight:600,
    fontFamily:"Degular"
  },
  businessTypeErrorBgColor:{
    backgroundColor:"#6551EA",
    marginBottom:"20px",
  },
  businessTypeErrorPositionBgColor:{
    borderTop: '10px solid #6551EA',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent', 
    left:"50%" 
  }
};

const BoxContainer=styled(Box)({
  "& .email_error_width":{
    width:"60%",
    position:'relative'
  },
  "& .center_align":{
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  }
})

const ErrorModalContainer = styled(Box)({
  "& .modal-overlay": {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "#11111180",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    zIndex: 1000,
    backdropFilter: "blur(12px)",
    "@media (max-width:598px)":{
      justifyContent: "center",
    } ,
    "@media (min-width:602px) and (max-width:962px)":{
      justifyContent: "center",
    },
  },

  "& .modal-container": {
    background: "#fff",
    width: "400px",
    height:'603px',
    padding: "20px",
    borderRadius: "12px",
    textAlign: "center",
    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.3)",
    position: "relative",
    "@media (max-width:598px)":{
      width:"353.1px",
      height:"532.3px",
      margin:"22px"
    },
    "@media (min-width:601px)":{
       left:130
    },
    "@media (min-width:601px) and (max-width:962px)":{
      left:90
   },
    "@media (min-width:1900px)":{
      left:270
   },
  },
  "& .modal-content":{
     display:"flex",
     flexDirection:"column",
     alignItems:"center",
     justifyContent:"space-between",
     height:'100%',
  },
"& .modal_sub_content":{
  display:"flex",gap:"25px",padding:"35px",flexDirection:"column",alignItems:"center",justifyContent:'center',height:'100%'
},
"& .modal_businees_type_sub_content":{
 padding:"45px",
 '@media (max-width:598px)':{
   padding:"32px"
 }
},
"& .modal_privacy_sub_content":{
  padding:"45px",
  '@media (max-width:598px)':{
    padding:"28px"
  }
},
"& .modal_team_size_sub_content":{
  padding:"41px",
  "@media (max-width:598px)":{
    padding:"35px"
  }
},

  "& .modal-close": {
    position: "absolute",
    top: "10px",
    right: "3px",
    border: "none",
    background: "transparent",
    fontSize: "18px",
    cursor: "pointer",
  },

  "& .modal-icon": {
    marginBottom: "20px",
  },

  "& .modal-button": {
    backgroundColor: "#6551EA",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: "6px",
    width:"284px",
    fontSize: "18px",
    cursor: "pointer",
    fontWeight:600,
    fontFamily:"Degular",
    height:"44px",
    lineHeight:"25.2px",
    textAlign:'center',
    textTransform:"none",
    marginBottom:"20px"
  },

  "& .system_error_text":{
    fontSize:"42px",
    fontFamily:"Degular",
    fontWeight:700,
    lineHeight:"42px",
    color:"#2E2E2E"
  },
  "& .system_error_desc":{
    fontSize:"16px",
    fontFamily:"Degular",
    fontWeight:500,
    lineHeight:"22.4px",
    color:"#6E6E6E"
  }
});


// Customizable Area End
