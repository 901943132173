import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
}
export interface OtpResponseData {
  data: {
    id: string,
    type: string,
    attributes: {
      activated: boolean,
      email: string,
      phone_number: null,
      account_exists: boolean
    }
  },
  meta: {
    message: string,
    token: string
  }
}
export interface EmailResponse {
  data: {
    id: number,
    type: string,
    attributes: {
        id: number,
        valid_until: string,
        email: string
    }
},
meta: {
    token: string,
    message: string
}
}
 export interface AlredyEmailResponse{
   message:[
     {
       account:string
     }
   ]
 }

export interface ErrorotpResponse {
  errors: [
    {
      message: string;
    }
]
}
export interface ErrorResopnseEmailotp {
  errors: [
    {
      account: string;
    }
  ]
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface S {
  // Customizable Area Start
  otp: string;
  otpAuthToken: string;
  userAccountID: string;
  labelInfo: string;
  toMessage: string;
  isFromForgotPassword: boolean;
  otpInput: string;
  otpView: boolean;
  verifyEmail: boolean;
  emaildata:Object;
  emailError:Object;
  resendError:Object;
  resendData:Object;
  emailOtpSuccessMsg:string;
  emailOtpFailureMsg:string;
  otpSuccessMsg:string;
  otpFailureMsg:string | null;
  emailCheck:string;
  validatetOtp:boolean;
  selectedOption:any;
  inputvalue:string;
  emailErrorMsg:string | null,
  tabValue:any,
  Otptimer:any,
  timeInterval:any,
  phoneInput:number | null,
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class OTPInputAuthController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  otpAuthApiCallId: any;
  btnTxtSubmitOtp: string;
  placeHolderOtp: string;
  labelInfo: string = "";
  submitButtonColor: any = configJSON.submitButtonColor;
  getOtpCallID:string = "";
  postEmailCallID:string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.SessionRequestMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.sendEmployeeIdProps)
      // Customizable Area End
    ];

    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    // Customizable Area Start
    this.state = {
      otp: "",
      otpAuthToken: "",
      userAccountID: "",
      labelInfo: configJSON.labelInfo,
      toMessage: "",
      Otptimer: Number(localStorage.getItem("OTPTimer")) ,
      isFromForgotPassword: false,
      otpInput: "",
      otpView: false,
      verifyEmail: false,
      emaildata:{},
      phoneInput:null,
      emailError:{},
      resendError: {},
      resendData: {},
      emailOtpSuccessMsg:"",
      emailOtpFailureMsg:"",
      otpSuccessMsg:"",
      otpFailureMsg:null,
      emailCheck:"",
      validatetOtp: false,
      selectedOption:localStorage.getItem("SelectedOption"),
      inputvalue:"",
      emailErrorMsg:null,
      timeInterval:null,
      tabValue:localStorage.getItem("CurrentTab") ? localStorage.getItem("CurrentTab") : "Tab1",
    };

    this.btnTxtSubmitOtp = configJSON.btnTxtSubmitOtp;
    this.placeHolderOtp = configJSON.placeHolderOtp;
    // Customizable Area End
  }

  async receive(from: String, message: Message) {
    // Customizable Area Start
   this.handleApiResponse(message)
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.otpAuthApiCallId != null &&
      this.otpAuthApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (
        responseJson &&
        (responseJson.messages ||
          (responseJson.meta && responseJson.meta.token))
      ) {
        if (responseJson.meta && responseJson.meta.token) {
          this.setState({
            otpAuthToken: responseJson.meta.token,
          });
        }

        if (this.state.isFromForgotPassword) {
          // runEngine.debugLog("about to send NavigationNewPasswordMessage");
          const msg: Message = new Message(
            getName(MessageEnum.NavigationNewPasswordMessage)
          );
          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        } else {
          const msg: Message = new Message(
            getName(MessageEnum.NavigationMobilePhoneAdditionalDetailsMessage)
          );

          msg.addData(
            getName(MessageEnum.AuthTokenDataMessage),
            this.state.otpAuthToken
          );

          msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

          this.send(msg);
        }
      } else {
        //Check Error Response
        this.parseApiErrorResponse(responseJson);
      }

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (errorReponse != null) {
        this.parseApiCatchErrorResponse(errorReponse);
      }
    } else if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const phoneAuthToken = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );

      const phoneNumber = message.getData(
        getName(MessageEnum.AuthTokenPhoneNumberMessage)
      );

      const forgotPasswordBool = message.getData(
        getName(MessageEnum.EnterOTPAsForgotPasswordMessage)
      );

      const emailValue = message.getData(
        getName(MessageEnum.AuthTokenEmailMessage)
      );

      const userAccountID = phoneNumber ? "" + phoneNumber : "" + emailValue;

      let updatedLabel = this.state.labelInfo;
      if (userAccountID && userAccountID !== "undefined") {
        updatedLabel = updatedLabel.replace("phone", userAccountID);
      }

      this.setState({
        otpAuthToken:
          phoneAuthToken && phoneAuthToken.length > 0
            ? phoneAuthToken
            : this.state.otpAuthToken,
        userAccountID: userAccountID,
        labelInfo: updatedLabel,
        isFromForgotPassword:
          forgotPasswordBool === undefined
            ? this.state.isFromForgotPassword
            : forgotPasswordBool
      });
    }
    
    // Customizable Area End
  }

  // Customizable Area Start
  async submitOtp() {
    if (!this.state.otp || this.state.otp.length === 0) {
      this.showAlert(configJSON.errorTitle, configJSON.errorOtpNotValid);
      return;
    }

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    if (this.state.isFromForgotPassword) {
      // console.log("entered is from forgot password!");
      const header = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
      };

      //GO TO REQUEST STATE
      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyForgotPasswordOtpEndPoint
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      const data = {
        token: this.state.otpAuthToken ? this.state.otpAuthToken : "",
        otp_code: this.state.otp ? this.state.otp : "",
      };

      const httpBody = {
        data: data,
      };

      //requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "POST");

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    } else {
      const headers = {
        "Content-Type": configJSON.apiVerifyOtpContentType,
        token: this.state.otpAuthToken,
      };

      this.otpAuthApiCallId = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.apiVerifyOtpEndPoint + this.state.otp
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(JSON.stringify({}))
      );
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiVerifyOtpMethod
    );
    // console.log("requestMessage.id is: " + requestMessage.id);
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  btnSubmitOTPProps = {
    onPress: () => this.submitOtp(),
  };

  txtMobilePhoneOTPWebProps = {
    onChangeText: (text: string) => this.setState({ otp: text }),
  };

  txtMobilePhoneOTPMobileProps = {
    ...this.txtMobilePhoneOTPWebProps,
    keyboardType: "numeric",
  };

  txtMobilePhoneOTPProps = this.isPlatformWeb()
    ? this.txtMobilePhoneOTPWebProps
    : this.txtMobilePhoneOTPMobileProps;

    handleOTPChange = (otp: string) => {
      this.setState({ otpInput: otp , validatetOtp: false,otpFailureMsg:""});
    };
  
    handleNavigate = () => {
      this.setState({ otpView: true })
    };
  
    handleNavigateVerifyEmail = () => {
      if(this.state.otpInput.length === 0){
       this.setState({validatetOtp: true})
      }
      else 
      {
        this.getOTPFunction()
      }
    };
    setStorage=()=>{
      localStorage.removeItem("SelectedOption")
      this.setState({tabValue:"Tab1"})
      localStorage.setItem("CurrentTab","Tab1")
      localStorage.removeItem("OTPTimer")
    }
      
    navigateToLoginPage = () => {
      if(this.state.tabValue === "Tab3"){
        this.setState({tabValue:"Tab2"})
        localStorage.setItem("CurrentTab","Tab2")
      }
      else if(this.state.tabValue === "Tab2"){
       this.setStorage()
        clearInterval(this.state.timeInterval)
        this.setState({Otptimer:30})
      }
      else{
        const navigation = new Message(getName(MessageEnum.NavigationMessage));
        navigation.addData(getName(MessageEnum.NavigationTargetMessage), "EmailAccountRegistration");
        navigation.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(navigation);
        localStorage.removeItem("CurrentTab")

      }
    };
    
    apiCall = async (data: APIPayloadType) => {
      const { contentType, method, endPoint, body } = data;
  
      const requestMessageAPI = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      const headerColllection = {
        "Content-Type": contentType,
      };
      
      requestMessageAPI.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        endPoint
      );
      
      requestMessageAPI.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerColllection)
      );
      requestMessageAPI.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        method
      );
      body && requestMessageAPI.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
      runEngine.sendMessage(requestMessageAPI.id, requestMessageAPI);
      return requestMessageAPI.messageId;
    };
  
  getOTPFunction = async () => {
    this.getOtpCallID = await this.apiCall({
      contentType:configJSON.apiVerifyOtpContentType,
      method: configJSON.get,
      endPoint: `${configJSON.getOtpEndPoint}?email=${this.state.inputvalue}&otp=${this.state.otpInput}`,
    });
  };
  
  
  responseSuccessCalls = async (apiRequestCallId: string, responseJson: OtpResponseData & ErrorotpResponse) => {
    if (apiRequestCallId === this.getOtpCallID) {
      this.otpSuccessCallBack(responseJson);
    }
  };
  
  responseFailureCalls = async (apiRequestCallId: string, errorReponse: OtpResponseData & ErrorotpResponse) => {
    if (apiRequestCallId === this.getOtpCallID) {
      this.otpFailureCallBack(errorReponse);
    }
  };
  
  otpSuccessCallBack = (responseJson: OtpResponseData) => {
    if(responseJson.meta){
      this.setState({tabValue:"Tab3"})
      localStorage.setItem("CurrentTab","Tab3")
      this.setState({emaildata:responseJson.data.attributes,otpSuccessMsg:responseJson.meta.message})
    }
    this.setState({ verifyEmail: true })
  };
  
  otpFailureCallBack = (responseJson: ErrorotpResponse) => {
    this.setState({emailError:responseJson.errors[0].message, otpFailureMsg:responseJson.errors[0].message})
  };
  
  postEmailOtpFunction = async () => {
    let apiData = {
      "data": {
        "attributes": {
            "email": this.state.inputvalue
        }
    }
    }
  this.postEmailCallID = await this.apiCall({
    contentType: configJSON.apiVerifyOtpContentType,
    method: configJSON.apiVerifyOtpMethod,
    endPoint: configJSON.emailOtpEndPoint,
    body: apiData
  });
  };
  
  responseEmailOtpSuccessCalls = async (apiRequestCallId: string, responseJson: EmailResponse & AlredyEmailResponse) => {
    if (apiRequestCallId === this.postEmailCallID) {
      this.emailOtpSuccessCallBack(responseJson);
    }
  };
  
  responseEmailOtpFailureCalls = async (apiRequestCallId: string, errorReponse: ErrorResopnseEmailotp) => {
    if (apiRequestCallId === this.postEmailCallID) {
      this.emailOtpFailureCallBack(errorReponse);
    }
  };
  
  emailOtpSuccessCallBack = (responseJson: any) => {
    if(responseJson.meta){
      this.setState({tabValue:"Tab2"})
      this.setState({resendData:responseJson.meta.message,emailOtpSuccessMsg:responseJson.meta.message})
    }else{
      this.setState({emailErrorMsg:responseJson.message[0].account})
    }
  };

  emailOtpFailureCallBack = (responseJson: ErrorResopnseEmailotp) => {
    this.setState({resendError:responseJson.errors[0].account,emailOtpFailureMsg:responseJson.errors[0].account})
  };

  showDiv=(value:string)=>{
    this.setState({selectedOption:value})
  }

  

  handleApiResponse = (message:Message) =>{
    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      let emailId = message.getData(getName(MessageEnum.sendEmployeeIdProps));
      this.setState({emailCheck:emailId})
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id){
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
  
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        this.responseSuccessCalls(apiRequestCallId, responseJson);
        this.responseEmailOtpSuccessCalls(apiRequestCallId, responseJson);
      } else if (responseJson && responseJson.errors) {
        this.responseFailureCalls(apiRequestCallId, responseJson);
        this.responseEmailOtpFailureCalls(apiRequestCallId, responseJson);
      }
    }
  }

  startTimer = () => {
    let timer;
    if (this.state.timeInterval) { return; }
    timer = setInterval(() => {
      this.setState(
        (prevState):any => {
          const newTime = prevState.Otptimer && prevState.Otptimer - 1;
          localStorage.setItem("OTPTimer", String(newTime));
          if (newTime && newTime <= 0) {
            clearInterval(this.state.timeInterval)
            return { Otptimer: 0, timeInterval: null };
          }
          return { Otptimer: newTime };
        },
        () => {
          if (this.state.Otptimer === 0) {
            clearInterval(this.state.timeInterval);
            timer=null
            this.setState({ timeInterval: null });
          }});
    }, 1000);
    this.setState({ timeInterval: timer });
  };
  resendOTP = () => {
    if (this.state.Otptimer === 0) {
        this.postEmailOtpFunction()
      if (this.state.timeInterval) {
        clearInterval(this.state.timeInterval);
        this.setState({ timeInterval: null });
      }
      this.setState({ Otptimer: 30 }, () => {
        localStorage.setItem("OTPTimer", "30");
        this.startTimer();
      });
    }
  };
    // Customizable Area End
}
