export const userProfile = require("../assets/user-profile.png");
export const homeIcon = require("../assets/homeIcon.svg");
export const exploreIcon = require("../assets/exploreIcon.svg");
export const notificationIcon = require("../assets/Notification.svg");
export const msgIcon = require("../assets/messagesIcon.svg");
export const appointmentIcon = require("../assets/appointmentIcon.svg");
export const savedIcon = require("../assets/Saved.svg");
export const moodIcon = require("../assets/moodboardIcon.svg");
export const settingsIcon = require("../assets/setingsIcon.svg");
export const porifleIcon = require("../assets/profileIcon.svg");
export const createPostIcon = require("../assets/postIcon.svg");
export const supportsIcon = require("../assets/Support.svg");
export const quilaLogo = require("../assets/quilalogo.png");
export const logoutIcon = require("../assets/Logout.svg");
export const profileImg = require("../assets/profileImg.png");
export const albumImg = require("../assets/album.svg");
export const backIcon = require("../assets/backIcon.svg");
export const copyIcon = require("../assets/copyIcon.svg");
export const zoomInIcon = require("../assets/zoomIn.svg");
export const postImg = require("../assets/Getty_Images.png");
export const smileImg = require("../assets/smileIcon.svg");
export const mapIcon = require("../assets/mapIcon.svg");
export const downIcon = require("../assets/downArrow.svg");
export const backArrow = require("../assets/backArrow.svg");
export const rightArrow = require("../assets/RightArrow.svg");
export const cutomerIcon = require("../assets/customer.svg");
export const locationIcon = require("../assets/locationIcon.svg");
export const categoryIcon = require("../assets/categoryIcon.svg");
export const searchIcon=require("../assets/searchIcon.svg");
export const cartIcon=require("../assets/cartIcon.svg");
export const notificationIccon=require("../assets/notificationIcon.svg")
export const walletIcon=require("../assets/walletIcon.svg")
export const likesIcon=require("../assets/likesIcon.svg")
export const homeActiveIcon=require("../assets/activeHomeIcon.svg")
export const profileActiveIcon=require("../assets/activeProfileIcon.svg")
export const postActiveIcon=require("../assets/activeCameraIcon.svg")
export const exploreActiveIcon=require("../assets/activeExploreIcon.svg")
export const appointmentActiveIcon=require("../assets/activeAppointmentIcon.svg")
export const moodBoardActiveIcon=require("../assets/activeMoodBoardIcon.svg")
export const messagesActiveIcon=require("../assets/activeMessagesIcon.svg")
export const likesActiveIcon=require("../assets/activeLikesIcon.svg")
export const settingsActiveIcon=require("../assets/activeSettingsIcon.svg")
export const dataIcon=require("../assets/dateIcon.svg")
export const timeIcon=require("../assets/timeIcon.svg")
