import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { Platform } from "react-native";
export interface DataListItem {
    id: string,
    attributes: {
        first_name: string,
        last_name: string,
        email: string,
        is_follow: string
    }
}

export interface DataListItemTwo {
    id: string,
    attributes: { account_email: string, email: string, current_user_id: string, account_id: string }
}

export interface DataListItemThree {
    id: string,
    attributes: { account_email: string, email: string, current_user_id: string, account_id: string, user_name: string, is_follow: boolean }
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}


interface S {
    // Customizable Area Start
    tabPanelNo: number,
    listData: DataListItem[],
    followingData: DataListItem[],
    openModal: boolean,
    errorMsg: string,
    openFollowerModel: boolean
    postsCount: number,
    followersCount: string,
    userList: boolean,
    token: string,
    followerList: boolean,
    loder: boolean
    followingList: boolean,
    searchTerm: string,
    profilePhoto: string,
    showFollowersScreen: boolean,
    userListData: DataListItemThree[]
    userFollowerListData: DataListItemTwo[]
    userFollowingListData: DataListItemTwo[]
    // Customizable Area End
}

interface SS {
    id: string;
    // Customizable Area Start
    // Customizable Area End
}



export default class FollowersController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    followerCallId: string = "";
    userFollowerCallID: string = "";
    userFollowingCallID: string = '';
    addFollowerFromFollowingCallId: string = "";
    unFollowFromFollowingCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);


        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
            getName(MessageEnum.RestAPIResponceDataMessage),
            getName(MessageEnum.RestAPIResponceSuccessMessage),            
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabPanelNo: 0,
            listData: [],
            followingData: [],
            openModal: false,
            errorMsg: "",
            searchTerm: "",
            showFollowersScreen: true,
            userList: true,
            followerList: false,
            followingList: false,
            loder: false,
            userFollowerListData: [],
            userListData: [],
            userFollowingListData: [],
            followersCount: imgPasswordVisible,
            token: '',
            postsCount: 20,
            profilePhoto: 'https://via.placeholder.com/150',
            openFollowerModel: false,
            // Customizable Area End
        };
        
        // Customizable Area Start
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();

        // Customizable Area Start
        await this.getToken()
        setTimeout(async () => {
            await this.getListOfSuggestionData();
            await this.getListOfSuggestionDataFollowers()
            await this.getListOfSuggestionDataFollowing()
        }, 0)
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start
        this.handleResponseForSuggestion(from, message)
        this.handleResForUnFollowFromFollowing(from, message)
        this.handleResponseForAddFromFollowing(from, message)
        this.handleResponseForSuggestionFollowers(from, message)
        this.handleResponseForSuggestionFollowing(from, message)
        // Customizable Area End
    }

    // Customizable Area Start
    getToken = async () => {
        const token: string = Platform.OS == 'android' || Platform.OS == 'ios' ? await getStorageData('token-access') : localStorage.getItem('authToken')
        this.setState({ token: token })
    }

    getListOfSuggestionData = async () => {
        this.setState({ loder: true })
        const token = await this.state.token
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": token
        };
        const followerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.followerCallId = followerDataMessage.messageId;

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.userListEndPoint
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
    }

    getListOfSuggestionDataFollowers = async () => {
        const token = await this.state.token
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };
        const followerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userFollowerCallID = followerDataMessage.messageId;

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.userListFollowersEndPoint
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
    }

    getListOfSuggestionDataFollowing = async () => {
        const token = await this.state.token
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };
        const followerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.userFollowingCallID = followerDataMessage.messageId;

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.userListFollowingEndPoint
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        followerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(followerDataMessage.id, followerDataMessage);
    }

    addFromFollowing = async (account_id: string) => {
        this.setState({ loder: true })
        const token = await this.state.token
        let headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token

        };
        let httpBody = {}
        httpBody = {
            data: {
                attributes: {
                    account_id: account_id,

                },
            }
        }
        const addFollowerDataMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.addFollowerFromFollowingCallId = addFollowerDataMessage.messageId;

        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.addFollowingApiEndPoint}`
        );

        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );
        addFollowerDataMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `${configJSON.postApiMethodType}`
        );

        runEngine.sendMessage(addFollowerDataMessage.id, addFollowerDataMessage);

    }

    unFollowFromFollowing = async (account_id: string) => {
        this.setState({ loder: true })
        const token = await this.state.token
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };
        const unFollowDataUserMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.unFollowFromFollowingCallId = unFollowDataUserMessage.messageId;

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.unFollowApisApiEndPoint}/${account_id}`
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        unFollowDataUserMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            `${configJSON.deleteApiMethodType}`
        );

        runEngine.sendMessage(unFollowDataUserMessage.id, unFollowDataUserMessage);
    }

    followingOnPress = () => { this.setState({ userList: false, followerList: false, followingList: true }) };

    followerListOnPress = () => { this.setState({ userList: false, followerList: true, followingList: false }) };

    userListOnPress = () => { this.setState({ userList: true, followerList: false, followingList: false }) };

    handleResponseForSuggestion = (from: string, message: Message) => {

        if (this.followerCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ loder: false })
            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.errors })

            } else {
                this.setState({ userListData: apiResponse.data })
            }
        }
    }
    handleResponseForSuggestionFollowers = (from: string, message: Message) => {

        if (this.userFollowerCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ loder: false })

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.errors })
                if (apiResponse.errors[0].message == "Not following to a user.") {
                    this.setState({ userFollowerListData: [] })
                }

            } else {
                this.setState({ userFollowerListData: apiResponse.data })
            }
        }
    }
    handleResponseForSuggestionFollowing = (from: string, message: Message) => {

        if (this.userFollowingCallID === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.setState({ loder: false })

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.errors })

            } else {
                this.setState({ userFollowingListData: apiResponse.data })
            }
        }
    }
    handleResponseForAddFromFollowing = async (from: string, message: Message) => {
        if (this.addFollowerFromFollowingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );


            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.errors })
            } else {
                await this.getListOfSuggestionData();
                await this.getListOfSuggestionDataFollowers()
                await this.getListOfSuggestionDataFollowing()

            }
        }
    }
    handleResForUnFollowFromFollowing = async (from: string, message: Message) => {
        if (this.unFollowFromFollowingCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            const apiResponse = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiResponse.errors) {
                this.setState({ errorMsg: apiResponse.errors })


            } else {
                await this.getListOfSuggestionData();
                await this.getListOfSuggestionDataFollowers()
                await this.getListOfSuggestionDataFollowing()
            }
        }

    }

    //for Web File
    hanleTabs = (event: object, value: number) => {
        this.setState({ tabPanelNo: value })
    }

    // Customizable Area End
}
