import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Button,
  Input,
  Typography,
  InputAdornment,
  IconButton,
  Grid,
  TextField,
  Divider,
  styled,
  FormControlLabel
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import {
  backIcon,
  sideImage,
  pageNotFound,
  backBtn,
  blueSparkImg,
  googleLogoImg
} from "./assets";
export const configJSON = require("./config");
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';

const EmailAccount_Main_Container = styled(Box)({
  height: '100%',
  overflow: "auto",
  "@media (max-width: 352px)": {
    padding: "0 10px",
  },
  "& .input_config":{
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
    },
},
}); 

const SideImageeeContainer = styled("img")({
  width: "50%",
  right: 0,
  height: "100vh",
  position:"fixed",
  objectFit: "cover",
  opacity: "1",
  display: "flex",
  "@media (max-width: 800px)": {
    display: "none"
  }
})

const BackIcon = styled("img")({
  marginRight: "4px",
  cursor: "pointer",
  "&:hover": {
    cursor: "pointer",
  },
});

const webStyle = {
  textInputStyle: {
    borderRadius: "10px",
    border: "1px solid #E53935",
    background: "rgba(229, 57, 53, 0.15)",
  },
  textInputView: {
    "&.MuiOutlinedInput-input": {
      borderRadius: "20px"
    }
  },
  textInputStyleBorder: {
    borderRadius: "20px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "6px"
    }
  },
  welcomeTextStyle: {
    color: "#6551EA",
    fontSize:"28px",
    fontWeight:600,
    fontFamily:"Degular Display",
  },
  loginTextStyle: {
    color: "#757676",
    fontSize:"14px",
    fontFamily:"Degular Display",
    fontWeight:500,
  },
  backBtnContainer: {
    display:"flex",
    alignItems:"center",
    gap:10,
    cursor: "pointer",
    "&:hover": {
      cursor: "pointer",
    },
  },
  backBtnImgStyle: {
    width:'40px',
    height:"40px",
    cursor:"pointer",
  },
  loginTextt: {
    color:"#757676",
    fontSize:"16px",
    fontFamily:"Degular Display",
    fontWeight:500,
    cursor:"pointer"
  },
  scrollbar: {
    overflowY:"scroll" as "scroll",
    scrollbarWidth: "none" as "none" 
  },
  lableText:{
    color:"#344054",
    fontSize:"12px",
    lineHeight: "16.8px",
    marginBottom:"5px",
    fontWeight:600,
    fontFamily:"Degular",
  },
  customTooltip: {
    marginTop: '-25px',
    backgroundColor: '#E53881',
    color: 'white',
    padding: '8px 10px',
    position: 'relative',
    ZIndex:99,
    borderRadius:"6px",
  },
  emailErrorWidth:{width:"175px"},
  passwordErrorWidth:{width:"207px"},
  emailContainer:{position:'relative' as "relative", backgroundColor:"pink"},
  validationText:{
    fontSize:"10px",
    color:"#FFFFFF",
    fontWeight:500,
    fontFamily:"Degular",
    marginLeft:"5px",
  },
  positionContainer: {
    position: 'absolute',
    top: '100%',
    left: '20%',
    transform: 'translateX(-50%)',
    borderTop: '10px solid #E53881',
    borderLeft: '10px solid transparent',
    borderRight: '10px solid transparent',          
  },
  inputEmailStyle: {
    width: "100%",
    marginBottom: "20px",
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
      border: 'none', 
  },
  },
  loginButtonStyle: {
    height: "44px",
    borderRadius: "6px",
    backgroundColor: "#6551EA",
    color: "#FFFFFF",
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22.5px",
    width:"100%",
    fontFamily:"Inter",
    marginTop:"20px"
  },
  socialLoginBtn: {
    border: "1px solid #D9D9D9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "44px",
    borderRadius: "6px",
    cursor: "pointer",
    marginTop:"15px"
  },
  socialLoginImg: {
    left: "12px",
    top: "12px"
  },
  socialLoginText: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "22.4px",
    fontFamily:"Inter",
    color:"0E1518"
  },
  checkBoxContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "98%",
    marginBottom: "16px",
    left:0,
    marginLeft:"6px",
  },
  backBtnText: {
    marginLeft:"6px", 
    color:"#757676", 
    fontWeight:500, 
    fontSize:"16px",
    fontFamily:"Degular"
  },
  loginApiErrorBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: "35px",
    width: "329px",
    height: "40px",
    textAlign: "center",
  },  
  loginApiErrorText: {
    color: "#E53935",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "20px",
  },
  
};

const PageNotFoundContainer=styled(Box)({
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  marginTop:"150px",  
  "@media (max-width:599px)":{
      marginTop:"145px"
  },
  "& .tryAgainBtn":{
    backgroundColor:"#6551EA",
    color:"#FFFFFF",
    borderRadius:"6px",
    textTransform:"capitalize" as "capitalize",
    fontWeight:600,
    fontSize:"18px",
    fontFamilt:"Degular",
    lineHeight:"25.2px",
    width:"358px",
    "@media (max-width:599px)":{
      width:"358px",
      marginTop:"180px"
    }
  },
})

const pageNotFoundStyles={
  subContainer:{
    textAlign:"center" as "center",
    padding:"20px"
  },
  imgStyle:{
    width:"150px",
    height:"150px",
    marginBottom:30
  },
  notFoundText:{
    marginBottom:40,
    color:"#000000",
    fontSize:"50px",
    fontWeight:700,
    fontFamily:"Degular",
    lineHeight:"40px",
    width:"100%",
  },
  guestModeContainer:{
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  notFoundDesc:{
    marginBottom:80,
    color:"#757676",
    fontSize:"16px",
    fontWeight:400,
    fontFamily:"Degular",
    lineHeight:"22.4px",
    textAlign:"center" as "center",
    width:"80%"
  },
  tryAgainBtn:{
  backgroundColor:"#6551EA",
  color:"#fff",
  borderRadius:"6px",
  textTransform:"capitalize" as "capitalize",
  fontWeight:600,
  fontSize:"18px",
  fontFamily:"Degular",
  width:"100%",
  height:"44px"
  },
  width:{width:"60%"},
  textWidth:{fontSize:"40px"}
}

const FirstLoginBox = styled(Box)({
  display:"flex",
  justifyContent:"center",
  flexDirection:"column",
  alignItems:"center",
  gap:0,
  marginTop:"170px",
  "@media (max-width: 599px)": {
    marginTop: "40px",
  },

})

const BoxContainer=styled(Box)({
  display:"flex",
  alignItems:"center",
  justifyContent:"space-between",
  padding: "20px 22px 0 25px",
  "@media (max-width:598px)":{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    flexDirection:"row",
    padding:"15px 12px 0 12px"
  }
})

const BackText = styled(Typography)({
  color: "#757676",
  fontSize: "16px",
  display: "block",
  fontWeight:500,
  fontFamily:"Inter",
  "@media (max-width: 598px)": {
   display:"none"
  }
})


const DontHaveAccountText = styled(Typography)({
  color: "#757676",
  fontSize: "12px",
  fontWeight:500,
  fontFamily:"Inter",
  marginTop:"25px",
  textAlign:"center",
})

const SignupTxt = styled("span")({
  color: "#8057DA",
  fontSize: "12px",
  fontWeight:500,
  fontFamily:"Inter",
  cursor:"pointer"
})

const BackButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent:"center",
  cursor: "pointer",
  "&:hover": {
    cursor: "pointer",
  },
});

function StyledCheckbox(props: CheckboxProps) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 3,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#6551EA',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#6551EA',
    },
  },
});

const EmailBoxContainer=styled(Box)({
  "& .email_error_width":{
    width:"45%",
    "@media (max-width:598px)":{
       width:"45%"
    }
  }
})

const PasswordBoxContainer=styled(Box)({
  "& .password_error_width":{
    width:"80%",
    "@media (max-width:598px)":{
       width:"90%"
    }
  }
})
// Customizable Area End

import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
}
// Customizable Area Start

  renderLoginBox = ()=>{
    return(
      <FirstLoginBox>
        <Typography variant="body1" style={webStyle.welcomeTextStyle}>
          {configJSON.welcomeText}
        </Typography>
        <Typography variant="body1" style={webStyle.loginTextStyle}>
          {configJSON.loginText}
        </Typography>
      </FirstLoginBox>
    )
  }

  renderEmailField = () => {
    return (
      <>
      <Box >
        {this.state.emailField&& (
          <Box sx={{...webStyle.customTooltip,...webStyle.emailErrorWidth}}
          >
            <Typography style={webStyle.validationText}>{configJSON.emailErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </Box>
      <EmailBoxContainer >
        {this.state.loginError&& (
          <Box className="email_error_width" sx={{...webStyle.customTooltip}}
          >
            <Typography style={webStyle.validationText}>{this.state.loginError}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </EmailBoxContainer>
      <Box style={{}}>
      <TextField
        data-test-id="emailInputID"
        error={Boolean(this.state.emailField || this.state.loginError)}
        label={this.state.email ? "" : "Phone number, user name, or email"}
        variant="outlined"
        style={webStyle.inputEmailStyle}
        value={this.state.email}
        onChange={(e) => this.setEmail(e.target.value)}
        className="input_config"
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.emailField || this.state.loginError? "#E53881" : "#757676",
            opacity: 1,
          }
        }}
        InputProps={{
          style: {
            color: this.state.emailField || this.state.loginError? "#E53881" : "#757676",
            borderRadius:"6px"
          }
        }}
        inputProps={{
          style: {
              border: this.state.emailField || this.state.loginError ? "1px solid #E53881" : "",
              borderRadius: "6px"
          }
      }}
        />
        </Box>
      </>
    )
  };

  renderPasswordField = () => {
    return (
      <>
      <PasswordBoxContainer >
        {this.state.passwordField && (
          <Box className="password_error_width" sx={{...webStyle.customTooltip,...webStyle.passwordErrorWidth}}
          >
            <Typography style={webStyle.validationText}>{configJSON.passErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </PasswordBoxContainer>
      <>
      <TextField
        data-test-id="passwordInputID"
        error={this.state.passwordField}
        label={this.state.password ? "" : "Password"}
        variant="outlined"
        style={webStyle.inputEmailStyle}
        value={this.state.password}
        className="input_config"
        type="password"
        onChange={(e) => this.setPassword(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.passwordField ? "#E53881" : "#757676",
            opacity: 1,
          }
        }}
        InputProps={{
          style: {
            color: this.state.passwordField ? "#E53881" : "#757676",
            borderRadius:"6px"
          }
        }}
        inputProps={{
          style: {
              border: this.state.passwordField ? "1px solid #E53881" : "",
              borderRadius: "6px"
          }
      }}
      />
      </>
      </>
    )
  };

  renderPageNotFound=()=>{
    return(
       <PageNotFoundContainer className="page_not_found_container">
        <Box style={pageNotFoundStyles.subContainer}>
          <img src={pageNotFound} alt="notFoundImage" style={pageNotFoundStyles.imgStyle}/>
          <Box style={pageNotFoundStyles.guestModeContainer}>
            <Typography className="desktop-text" style={pageNotFoundStyles.notFoundText} data-test-id="pageNotFound">{configJSON.loginFailedText}</Typography>
            <Typography style={pageNotFoundStyles.notFoundDesc} className="not_found_desc">{configJSON.loginFailedDesc}</Typography>
          </Box>
          <Button className="tryAgainBtn" onClick={this.handleTryAgain}>{configJSON.tryAgainText}</Button>
          </Box>
       </PageNotFoundContainer>
    )
  }

// Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
        <EmailAccount_Main_Container>
          <Grid container>
            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
              <Box>
                <BoxContainer mb={2}>
                  <BackButtonContainer>
                    <BackIcon src={backBtn} alt="Back" data-test-id="backBtnClick" onClick={this.openEmailRegistrationPage}/>
                    <BackText variant="body1" style={webStyle.backBtnText}>Back</BackText>
                  </BackButtonContainer>
                  <Box>
                    <img src={blueSparkImg} alt="blueSparkIcon"/>
                  </Box>
                </BoxContainer>

                  {/* Main Content - Conditional Rendering */}
                  <Box style={webStyle.scrollbar}>
                    {this.state.isRedirectingToPageNotFound
                      ? this.renderPageNotFound()
                      : (
                        <form onSubmit={this.handleCheckValidation} data-test-id="submitTestID">
                          <Box
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flexDirection: "column",
                            }}
                          >
                            {this.renderLoginBox()}
                            <Box style={{ width: "358px", marginTop: "8%", }}>
                              <Typography
                                style={{
                                  ...webStyle.lableText,
                                  textAlign: "start",
                                  alignSelf: "flex-start",
                                }}
                              >
                                {configJSON.emailText}
                              </Typography>
                              {this.renderEmailField()}
                              <Typography style={webStyle.lableText}>
                                Password*
                              </Typography>
                              {this.renderPasswordField()}
                              <Box>
                                <Box style={webStyle.checkBoxContainer}>
                                  <FormControlLabel
                                    control={<StyledCheckbox disableRipple />}
                                    label={
                                      <Typography
                                        style={{
                                          fontFamily: "Inter",
                                          fontWeight: 500,
                                          fontSize: "12px",
                                          color: "#344054",
                                          marginLeft: "3px",
                                        }}
                                      >
                                        Remember this device
                                      </Typography>
                                    }
                                  
                                  />
                                  <Typography
                                    style={{
                                      fontSize: "12px",
                                      color: "#6551EA",
                                      cursor: "pointer",
                                      fontFamily: "Inter",
                                      fontWeight: 600,
                                    }}
                                    data-test-id="fogotPassTestID"
                                    onClick={this.openForgotPasswordPage}
                                  >
                                    Forgot password
                                  </Typography>
                                </Box>
                                <Button
                                  type="submit"
                                  style={{
                                    ...webStyle.loginButtonStyle,
                                    position: "relative",
                                    textTransform: "capitalize",
                                  }}
                                >
                                  {configJSON.loginBtnText}
                                </Button>
                                <Box
                                  style={{
                                    ...webStyle.socialLoginBtn,
                                    position: "relative",
                                  }}
                                >
                                  <img
                                    style={{
                                      ...webStyle.socialLoginImg,
                                      position: "absolute",
                                    }}
                                    src={googleLogoImg}
                                    alt="Google Logo"
                                  />
                                  <Typography style={webStyle.socialLoginText}>
                                    {configJSON.googleLoginBtnText}
                                  </Typography>
                                </Box>
                              </Box>
                              <DontHaveAccountText>
                                {configJSON.dontHaveAccountText}{" "}
                                <SignupTxt onClick={this.openEmailRegistrationPage}>
                                  {configJSON.signUpText}
                                </SignupTxt>
                              </DontHaveAccountText>
                            </Box>
                            <Box >
                              {this.state.loginApiError&& (
                                <Box sx={{...webStyle.loginApiErrorBox }}>
                                  <Typography style={{...webStyle.loginApiErrorText}}>{this.state.loginError}</Typography>
                                </Box>
                              )}
                          </Box>
                          </Box>
                        </form>
                      )}
                  </Box>
              </Box>
            </Grid>
            <Grid item xs={false} sm={6} md={6} lg={6} xl={6}>
              <Box>
                <SideImageeeContainer src={sideImage} alt="login" />
              </Box>
            </Grid>
          </Grid>
        </EmailAccount_Main_Container>
      </>
      // Customizable Area End
    );
  }
}
