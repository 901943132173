import React from "react";

// Customizable Area Start
import { Box, styled } from "@material-ui/core";
import { quilaLogo } from "./assets";
// Customizable Area End

import SplashscreenController, {
  Props,
  configJSON
} from "./SplashscreenController";

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <MainContainer>
        <Box className="box_container">
          <img src={quilaLogo} className="image_style" />
        </Box>
      </MainContainer>
    );
    // Customizable Area End
  }
}
// Customizable Area Start

const MainContainer = styled(Box)({
  "& .box_container": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "95vh"
  },
  "& .image_style": {
    width: "245px",
    height: "129px",
    "@media (max-width:598px)": {
      width: "135px",
      height: "71px"
    }
  }
});
// Customizable Area End
