// Customizable Area Start
Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "FormApprovalWorkflow";
exports.labelBodyText = "FormApprovalWorkflow Body";
exports.CreateIdApi='bx_block_formapprovalworkflow/formapprovalworkflows/create_customer_trip'
exports.btnExampleTitle = "CLICK ME";
exports.cancelAPI = "bx_block_formapprovalworkflow/formapprovalworkflows/cancel_trip";
exports.businessNameApi="bx_block_formapprovalworkflow/formapprovalworkflows";
exports.beautyServiceQuestions="/bx_block_admin/beauty_service_categories";
exports.teamSizeQuestions="bx_block_admin/team_sizes";
exports.businessNameErrorText="Please enter your business name to continue";
exports.invalidBusinessNameErrorText="Please only use letters and numbers";
exports.validUrl="Please enter a valid website URL";
exports.businessNameSystemErrorDesc="It seems there was an issue with your information. Please try again";
exports.businessTypeErrorText="Please select at least one category to continue";
exports.businessTyleSystemErrorDesc="It seems there was an issue with your selection. Please try selecting your categories again";
exports.continue="Continue";
exports.teamSizeErrorText="Please select your team size to proceed";
exports.teamSizeSysytemErrorDesc="It seem was not registered. Please try selecting your team size again";
exports.itsme="It’s just me";
exports.systemError="System Error";
exports.tryAgain="Try Again";
exports.cancelAPI = "bx_block_formapprovalworkflow/formapprovalworkflows/cancel_trip";
exports.lincenseExpHeading="What is your license or certification number?";
exports.lincenseSubHeading="This are the beauty services of your business. They will help customers find you. Select all that apply";
exports.localityErrorText="Please select at least one location to proceed";
exports.localitySystemErrorDesc="It seems there was an issue with your selection. Please try again";
exports.privacyConcernSystemErrorDesc="It seems there was an issue saving your privacy settings"
// Customizable Area End
