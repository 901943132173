export const QuilaLogo = require("../assets/QuilaLogo.png");
export const EmailIcon =require("../assets/emailICon.svg");
export const BackIcon =require("../assets/backIcon.svg");
export const CheckImg = require("../assets/check-circle.png");
export const coverimg= require("../assets/CoverImg.svg")
export const Logoicon= require("../assets/Vector.svg")
export const LockIcon= require("../assets/lock.svg")
export const Nail= require("../assets/nail.jpg")
export const Mobile= require("../assets/Mobile.svg")
export const Email= require("../assets/Email.svg")
export const CheckCongrats= require("../assets/Check.svg")
export const BackButton = require("../assets/backBtnImg.png");
