import React from "react";
// Customizable Area Start
import { Box, Button, Typography, styled } from "@material-ui/core";
import { coverimg, BackButton, Logoicon,LockIcon,Mobile,Email, CheckCongrats, Nail } from "./assets";
import OTPInputAuthController, { Props } from "./OTPInputAuthController";
export const configJSON = require("./config");
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import OtpInput from "react-otp-input";
// Customizable Area End

export default class OTPInputAuth extends OTPInputAuthController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount(){
    if(this.state.tabValue ==="Tab2"  && this.state.Otptimer >= 0){
      this.startTimer()
    }
  }
  async componentWillUnmount() {
    if (this.state.timeInterval) {
      clearInterval(this.state.timeInterval);
      this.setState({timeInterval:null})
    }
  }
 
  emailData=(value:string)=>{
    this.setState({inputvalue:value,emailErrorMsg:null})
  }
  phoneData=(value:any)=>{
    this.setState({phoneInput:value,emailErrorMsg:null})
  }
  validateEmail = (email:string) => {
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return regex.test(email);
  };
  setOTPTImer=()=>{
    localStorage.setItem("SelectedOption",this.state.selectedOption)
    localStorage.setItem("OTPTimer",JSON.stringify(30))
  }
  continueClicked=()=>{
   if (this.validateEmail(this.state.inputvalue) || this.state.phoneInput !==null){
    if(this.state.tabValue === "Tab1"){
      this.postEmailOtpFunction()
      localStorage.setItem("CurrentTab","Tab2")
      this.setOTPTImer()
      this.setState({Otptimer:30})
      this.startTimer()
    }
   }
   else{
    this.setState({emailErrorMsg:"Please enter valid email"})
   }
  }
  verifyClick =()=>{
      this.getOTPFunction()
  }

  renderOtpContainer = () => {
    return (
      <OtpMainContainer>
        {this.state.otpFailureMsg ? <OtpInput
          data-test-id="otpInputID"
          onChange={this.handleOTPChange}
          value={this.state.otpInput}
          numInputs={4}
          containerStyle={"otp-style"}
          inputStyle={{
            width: "64px",
            height: "64px",
            borderRadius: "8px",
            border: "2px solid #E53881",
            margin: "0px 5px",
            color: "#E53881",
            fontSize: "56px",
            fontWeight:600,
            textAlign: "center"
          }}
          renderInput={(props) => <input {...props} />}
        /> : <OtpInput
          data-test-id="otpInputID"
          onChange={this.handleOTPChange}
          value={this.state.otpInput}
          numInputs={4}
          containerStyle={"otp-style"}
          inputStyle={{
            width: "64px",
            height: "64px",
            borderRadius: "8px",
            border: "2px solid #6551EA",
            background: "#FFF",
            margin: "0px 5px",
            color: "#6551EA",
            fontSize: "56px",
            fontWeight:600,
            fontFamily:"inter",
            textAlign: "center"
          }}
          renderInput={(props) => <input {...props} placeholder="-" />}
        />}
      </OtpMainContainer>
    )
  }
  getInputField = ()=>{
    if( this.state.selectedOption ==="email"){
      return  <div style={{margin:"25px 0px"}}>
        <Box>
          {this.state.emailErrorMsg && (
            <Box sx={{ ...webStyle.customTooltip, ...webStyle.emailErrorWidth }} >
              <Typography style={webStyle.validationText}>{this.state.emailErrorMsg}</Typography>
              <Box sx={webStyle.positionContainer} />
            </Box>
          )}
        </Box>
        <Box>
          <input data-test-id = "inputField" className="inputField" type="email" name="email" placeholder="Type your email" onChange={(event) => { this.emailData(event.target.value)}} />
        </Box>
      </div>
  
    }
   else if( this.state.selectedOption ==="sms"){
      return  <div style={{margin:"30px 0px"}}>
        <PhoneNumberBox
          className="phoneNumberField">
          <PhoneInput
            enableSearch={true}
            disableSearchIcon={true}
            searchClass="search_country_code"
            searchPlaceholder="Search"
            onChange={(event) => { this.phoneData(event)}}
            containerStyle={{ border: "none" }}
            inputClass={`phone_number_inputField`}
            dropdownClass="register_drop_down_class"
            containerClass="regsiter_contaitner_class"
            country="us"
            placeholder="Your Phone Number"
            dropdownStyle={{width:"353px",borderTopRightRadius:"0",borderTopLeftRadius:"0px",marginTop:"3px"}}
          />
        </PhoneNumberBox>
    </div>
    }
    else {
      return null
    }
  }
  centerDivData = () => {
    console.log("value 22",this.state.phoneInput);
    
    return <CenterDiv>
      <div>
        <LogoContainer>
          <img src={LockIcon} alt="logoImage" style={{ height: "60px", width: "60px" }} />
        </LogoContainer>
        <TextContainer>
          <Typography className="mainHeading">{configJSON.verification}</Typography>

          <Typography className="subHeading">{configJSON.smsHeading}</Typography>
        </TextContainer>
        {this.getInputField()}

        <Option selected={this.state.selectedOption === "email"} onClick={this.showDiv.bind(this, "email")}>
          <Typography className="typotext">{configJSON.receiveMail}</Typography>
          <CustomRadio selected={this.state.selectedOption === "email"} />
        </Option>
        <Option selected={this.state.selectedOption === "sms"} onClick={this.showDiv.bind(this, "sms")} >
          <Typography className="typotext">{configJSON.receiveSms}</Typography>
          <CustomRadio selected={this.state.selectedOption === "sms"} />
        </Option>
      </div>
      <ContinueBtn  data-test-id = "ContinueButton" disabled={!this.state.selectedOption || this.state.phoneInput === null && this.state.inputvalue.length <= 0} onClick={ this.continueClicked.bind(this)}>Continue</ContinueBtn>
    </CenterDiv>
  }
  centerCongratsData = () => {
    return <CenterCongratsDiv>
      <div>
        <LogoContainer>
          <img src={CheckCongrats} alt="logoImage" style={{ height: "111px", width: "111px" }} />
        </LogoContainer>
        <TextCongratsContainer>
          <TypographyCustom  style={{textAlign:"center"}}>{configJSON.congratsMsg}</TypographyCustom>
         {
          this.state.selectedOption === "email" ?
          <>
          <Typography className="subEmailHeading">{configJSON.emailSubHeading} </Typography>
          <Typography className="subEmailHeading">{configJSON.emailSubHeading2} </Typography>
          </>
          :
          <Typography className="subPhoneHeading">{configJSON.phoneSubHeading} </Typography>
        }
        </TextCongratsContainer>
      </div>
      <ContinueBtn style={{width:"358px"}}>Continue</ContinueBtn>
    </CenterCongratsDiv>
  }
   getSrcLogo = ()=>{
      if(this.state.selectedOption == "email"){
        return Email
      }
      else{
        return Mobile
      }
  }
  centerOtpData = () => {
    return <CenterDiv>
      <div>
        <LogoContainer>
          <img src={this.getSrcLogo()} alt="logoImage" style={{ height: "60px", width: "60px" }} />
        </LogoContainer>
        <TextContainer>
          {
          this.state.otpFailureMsg ?
          <Typography className="mainHeading" style={{color:"#E53881"}}>{configJSON.WrongCode}</Typography>
            :
          <Typography className="mainHeading">{this.state.selectedOption === "email" ? configJSON.checkEmailText:configJSON.checkPhoneText}</Typography>
          }
          {this.state.selectedOption === "email" ? <p className="subOTPHeading"> {configJSON.sentMail}({this.state.inputvalue})</p>:
            <p className="subOTPHeading"> {configJSON.sentOnPhone}(***) ***-5757 </p>
          }
        </TextContainer>
        {this.renderOtpContainer()}
        <VerifyBtn disabled={this.state.otpInput.length < 4}  onClick={()=>this.verifyClick()}>Verify</VerifyBtn>
        <div className="resendContainer">
          <span data-test-id="receivemail" className="mailReceive">{this.state.selectedOption==="email"?configJSON.receiveEmailtext:configJSON.receivePhonetext} <span className="resend" onClick={this.resendOTP.bind(this)}>Click to resend</span></span>
        </div>
        <CancelBtn >Cancel</CancelBtn>
      </div>
      <div className="resendContaineTimer">
      <RensedTimer >{this.state.selectedOption==="email"?configJSON.resendEmail:configJSON.resendSms}{this.state.Otptimer}s</RensedTimer>
      </div>

    </CenterDiv>
  }

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      <MainContainer>
      <LeftSideDiv>
        <ButtonContauner data-test-id="navButtons" onClick={()=>this.navigateToLoginPage()}>
          <div style={{display:"flex",justifyContent:"ceter",alignItems:"center"}} >
          <img src={BackButton} alt="BackButton" className="backbtn"/>
          <span className="backText">Back</span>
          </div>
          <img src={Logoicon} alt="logo" style={{height:"39px",width:"40px"}} />
        </ButtonContauner>
        {(()=>{
          switch(this.state.tabValue){
            case "Tab2": return this.centerOtpData()
            case "Tab3": return this.centerCongratsData()
            default :{return this.centerDivData()}
          }
        })()
        
        }
      </LeftSideDiv>
      <RightSideDiv>
        <Box style={{height:"100%",width:"100%",backgroundImage:`url(${this.state.tabValue === "Tab3" ? Nail : coverimg})`,backgroundRepeat:"no-repeat",backgroundSize:"cover"}}>
        </Box>
      </RightSideDiv>
  </MainContainer >
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainContainer = styled(Box)({
  "& .logoStyle": {
    padding: "15px 20px",
    width: "11%",
    display: "block",
    "@media (max-width:598px)": {
      display: "none"
    }
  },
  "& .errorMsg":{
    color:"red",
    fontFamily:"inter",
    fontSize:"12px",
  },
  "& .backIcon": {
    display: "none",
    "@media (max-width:598px)": {
      height: "25px",
      width: "25px",
      padding: "20px",
      display: "block"
    },
  },
  "& .backbtn": {
    height: "40px",
    width: "40px",
    cursor: "pointer",
    marginRight: "10px",
  },
  "& .typotext": {
    fontSize: "13px",
    fontFamily: "inter",
    fontWeight: 500,
    color: '#000000'
  },
  "& .mainHeading":{
    fontSize: "28px", color: "#6551EA",letterSpacing:"1%",lineHeight:"33.6px", fontWeight: 600, whiteSpace: "nowrap",fontFamily:"Degular",textAlign:"center",margin:"10px 0px"
  },
  "& .mainCongratsHeading":{
    fontSize: "42px", color: "#2E2E2E", fontWeight: 700,fontFamily:"Degularbold",textAlign:"center",margin:"20px 0px"
  },
  "& .subHeading":{
    marginBottom:"20px",padding:"2px",height:"40px",boxSizing:"border-box", fontSize: "14px", color: "#757676", fontWeight: 500,fontFamily:"Degular_Regular",letterSpacing:"2%",textAlign:"center"
  },
  "& .subEmailHeading":{
    fontSize: "16px", color: "#6E6E6E", fontWeight: 500,width:"350px",fontFamily:"Degular",textAlign:"center"
  },
  "& .subPhoneHeading":{
    fontSize: "16px", color: "#6E6E6E", fontWeight: 500,width:"350px",fontFamily:"Degular",textAlign:"center"
  },
  "& .subOTPHeading":{
  width:"100%",height:"40px", fontSize: "14px", color: "#757676", fontWeight: 500,textAlign:"center",lineHeight:"19.6px",fontFamily:"Degular_Regular",letterSpacing:"2%",padding:"0px 25px"
  },
  "& .resendContainer":{
    width:"100%",
    display:"flex",
    justifyContent:"center"
  },
  "& .mailReceive":{
    fontSize:"14px",
    fontFamily:"inter",
    color:"#757676",
    fontWeight:700
  },
  "& .resend":{
    fontSize:"14px",
    fontFamily:"inter",
    color:"#6551EA",
    fontWeight:700,
    cursor:"pointer"
  },
  "& .backText": {
    color: "#757676",
    fontSize: "16px",
    display: "block",
    fontWeight: 500,
    fontFamily: "Inter",
    cursor:"pointer",
    "@media (max-width:598px)": {
      display: "none",
    }
  },
  "& .inputField":{
    width:"100%",
    height:"44px",
    borderRadius:"6px",
    outline:"5px solid rgba(101, 81, 234, 0.5)",
    fontSize:"14px",
    marginBottom:"5px",
    fontWeight:600,
    fontFamily:"inter",
    color:"#6551EA",
    padding:"0px 10px", 
    transition: "all 0.3s ease-in-out",
    border: "1.4px solid #6551EA",
    "&::placeholder": {
      color: "gray",
      fontSize: "14px",
      fontWeight:600,
      fontFamily:"inter"
    },
  },  
  "& .resendContaineTimer":{
    width:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center"
  },
 
  display: "flex",
  flexDirection: "row",
});
const LeftSideDiv = styled(Box)({
  width: "45vw",
  padding: "0px 30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  "@media (max-width:598px)": {
    width: "100vw",
    padding:"0px 15px"
  }
})
const TypographyCustom = styled(Typography)({
  fontSize: "42px",
  color: "#2E2E2E",
  fontWeight: 700,
  fontFamily:"Degularbold",
  textAlign:"center",
  margin:"20px 0px",

})

const webStyle = {
  textInput: {
    width: "48%",
  },
  inputEmailStyle: {
    width: "100%",
    marginBottom: "20px"
  },

  passwordText: {
    color: "#757676",
    fontSize: "12px",
    marginBottom: "15px",
    marginLeft: "10px"
  },


  customTooltip: {
    marginTop: '-34px',
    marginLeft:"30px",
    maxWidth:"179px",
    position:"absolute",
    boxShadow: '0px 4.412px 8.824px rgba(0, 0, 0, 0.12)',
    backgroundColor: '#E53881',
    color: 'white',
    padding: '10px 15px',
    display:"flex",
    justifyContent:"center",
    borderRadius:"6px",
    zIndex:"50px",
   

  },
  positionContainer: {
     position: 'absolute',
     top: '100%',
     left: '20%',
     transform: 'translateX(-50%)',
     borderTop: '10px solid #E53881',
     borderLeft: '10px solid transparent',
     borderRight: '10px solid transparent',    
   
  },
  validationText:{
    fontSize:"12px",
    color:"#fff",
    fontWeight:600,
   
  },
  cursor:{
    cursor:"pointer"
  },
  emailErrorWidth:{width:"60%"},
}

const PhoneNumberBox = styled(Box)(()=>({
  "& .react-tel-input":{
    border:"1px solid #2D3034",
    "& .search":{
      padding:"10px",
    },
    "& .search-box":{
      border:"none",
      borderRadius:"6px",
      marginLeft:0,
      width:"100%"
    }
  },
  "& .phone_number_inputField":{
    borderRadius:"6px",
    height:"44px",
    width:"100%"
  },

}))
const CenterDiv = styled("div")({
  height: "690px",
  marginTop: "74px",
  maxWidth: "353px",
  maxHeight: "690px",
  width: "353px",
  display: "flex",
  justifyContent:"space-between",
  boxSizing: "border-box",
  flexDirection: "column",
  "@media (max-width:598px)": {
    marginTop: "10px",
  }
})

const CenterCongratsDiv = styled("div")({
  height: "690px",
  marginTop: "74px",
  maxWidth: "370px",
  maxHeight: "690px",
  width: "370px",
  display: "flex",
  justifyContent:"space-between",
  boxSizing: "border-box",
  flexDirection: "column",
  "@media (max-width:598px)": {
    marginTop: "100px",
  }
})
const Option = styled("div")(({ selected }: any) => ({
  display: "flex",
  height: "67px",
  alignItems: "center",
  justifyContent: "space-between",
  border: selected ? "2px solid #6551EA" : "2px solid rgba(0, 0, 0, 0.12)",
  borderRadius: "8px",
  padding: "0px 16px",
  marginBottom: "10px",
  cursor: "pointer",
  backgroundColor: selected ? "rgba(101, 81, 234, 0.15)" : "#fff",
  transition: "border 0.3s",
  "&:hover": {
    borderColor: "#6551EA",
  },
}));
const CustomRadio = styled("div")(({ selected }: any) => ({
  width: "24px",
  height: "24px",
  border: `2px solid ${selected ? "#6e00ff" : "rgba(0, 0, 0, 0.3)"}`,
  borderRadius: "50%",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transition: "border 0.3s",
  backgroundColor: selected ? "#6e00ff" : "#fff",
  color: "#fff",
  "&::before": {
    content: selected ? "'✔'" : "''",
    fontSize: "14px",
    fontWeight: "bold",
  },
}));

const ButtonContauner = styled(Box)({
  width: "100%",
  height: "80px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
})
const TextContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop:"30px"
})
const TextCongratsContainer = styled("div")({
  display: "flex",
  flexDirection: "column",
  marginTop:"30px",
  height:"152px",
  width:"357px",
  justifyContent:"center",
  alignItems:"center"
})

const LogoContainer = styled("div")({
  display:"flex",
  justifyContent:"center",

})

const RightSideDiv = styled(Box)({
  width: "50vw",
  height: "100vh",
  position:"fixed",
  right:0,
  "@media (max-width:598px)": {
    display: "none"
  }
})
const ContinueBtn = styled(Button)({
  backgroundColor:"#6551EA !important",
  width:"100%",
  fontFamily: "Degular",
  fontSize: "18px",
  height:"44px",
  color:"white",
  fontWeight: 400,
  textAlign: "center",
  textTransform:"capitalize",
  borderRadius:"6px",
  marginBottom:"30px",
  "&.MuiButton-root.Mui-disabled":{
    color:"white !important",
    backgroundColor:"#D9D9D9 !important"
  }
})
const VerifyBtn = styled(Button)({
  backgroundColor:"#6551EA !important",
  fontWeight: 700,
  textAlign: "center",
  textTransform:"capitalize",
  borderRadius:"6px",
  width:"100%",
  fontFamily: "Degular",
  fontSize: "18px",
  height:"44px",
  color:"white",
  margin:"30px 0px",
  "&.MuiButton-root.Mui-disabled":{
    color:"white !important",
    backgroundColor:"#D9D9D9 !important"
  }
})
const CancelBtn = styled(Button)({
  backgroundColor:"none !important",
  width:"100%",
  fontFamily: "inter",
  fontSize: "16px",
  height:"44px",
  color:"#475467",
  fontWeight: 700,
  textAlign: "center",
  textTransform:"capitalize",
  borderRadius:"6px",
  margin:"20px 0px",
  "&.MuiButton-root.Mui-disabled":{
    color:"#475467 !important",
    backgroundColor:"none !important"
  }
})
const RensedTimer = styled("div")({
  backgroundColor:"#F9F9F9 !important",
  width:"302px",
  fontFamily: "inter",
  borderRadius:"6px",
  marginBottom:"30px",
  fontSize: "14px",
  height:"44px",
  color:"#475467",
  fontWeight: 700,
  textAlign: "center",
  textTransform:"capitalize",
  display:"flex",
  justifyContent:"center",
  alignItems:"center"

})

const VerifyEmailMainContainer = styled(Box)({
  width: "100vw",
  height: "100vh",
  display: "flex",
  justifyContent: "center",
  alignItems: "flex-start",
  padding: "25px 0px",
  "& .subContainer": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "15px",
    padding: "4%"
  },
  "& .emailIconContainer": {
    height: "56px",
    width: "56px",
    border: "1px solid #EAECF0",
    borderRadius: "12px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .emailIcon": {
    width: "28px",
    height: "28px",
    flexShrink: 0
  },
  "& .checkEmailText": {
    color: "#101828",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "-0.48px"
  },
  "& .verificationText": {
    color: "#757676",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "24px ",
    letterSpacing: "-0.14px",
    marginTop: "10px"
  },
  "& .cmailyText": {
    color: "#757676",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "24px",
    letterSpacing: "-0.14px",
    textAlign: "center"
  },
  "& .btnBox": {
    width: "358px",
    height: "44px",
    flexShrink: 0,
    borderRadius: "6px",
    background: "#0E1518",
    color: "white",
    textTransform: "initial",
    "& .MuiButton-root": {
      textDecoration: "none",
      backgroundColor: "black",
      textTransform: "initial"
    },
    "& .hover": {
      hover: "none",
      background: "#0E1518"
    },
    "& .MuiButton-root:hover": {
      textDecoration: "none",
      backgroundColor: "black"
    }
  },
  "& .btnText": {
    color: "#FFFFFF",
    fontFamily: "Inter",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    textTransform: "initial",
    "& .MuiButton-root:hover": {
      textDecoration: "none",
      backgroundColor: "black"
    }
  },
  "& .resentTextContainer": {
    display: "flex",
    alignItems: "center",
    marginTop: "10px"
  },
  "& .resendBtn": {
    color: "#6551EA",
    fontStyle: "normal",
    marginLeft: "5px"
  },
  "& .receiveEmailText": {
    color: "#475467",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWWeight: 400,
    lineHeight: "20px"
  },
  "& .checkVerifyEmailText": {
    color: " #757676",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "26px",
    letterSpacing: "-0.14px"
  },
  "& .verifyEmailTextBox": {
    top: "10px"
  },
  "& .verifyEmailTextStyle": {
    color: "#101828",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "32px",
    letterSpacing: "-0.48px",
    marginBottom: "10px"
  }
});

const OtpMainContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "15px 5px 10px",
 "& .otp-style input::placeholder" :{
    color: "#6551EA", 
    opacity: 1,
  }
});

// Customizable Area End
