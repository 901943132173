import React from "react";

// Customizable Area Start
import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  styled,
  Grid,
  Modal,
  TextareaAutosize,
  TextField
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import {
  userProfile,
  quilaLogo,
  savedIcon,
  settingsIcon,
  supportsIcon,
  appointmentIcon,
  notificationIcon,
  exploreIcon,
  moodIcon,
  msgIcon,
  porifleIcon,
  createPostIcon,
  logoutIcon,
  profileImg,
  backIcon,
  zoomInIcon,
  copyIcon,
  albumImg,
  postImg,
  smileImg,
  mapIcon,
  downIcon,
  backArrow,
  rightArrow,
  cutomerIcon,
  locationIcon,
  categoryIcon,
  searchIcon,
  cartIcon,
  notificationIccon,
  homeIcon,
  likesIcon,
  walletIcon,
  homeActiveIcon,
  profileActiveIcon,
  postActiveIcon,
  exploreActiveIcon,
  appointmentActiveIcon,
  moodBoardActiveIcon,
  messagesActiveIcon,
  likesActiveIcon,
  settingsActiveIcon,
  dataIcon,
  timeIcon
} from "./assets";
import MenuSharpIcon from '@material-ui/icons/MenuSharp';
// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

export default class NavigationMenu extends NavigationMenuController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  list = () => (
    <div
      className="left"
      style={{
        width: 250,
      }}
      role="presentation"
      onClick={this.toggleDrawer}
      onKeyDown={this.toggleDrawer}
    >
      <Box style={webStyle.userProfileWrapper}>
        <img src={userProfile} style={webStyle.userProfile} />
        <Typography variant="h6">{configJSON.userName}</Typography>
        <Typography variant="h6" style={webStyle.userDesignation}>
          {configJSON.userDesignation}
        </Typography>
        <Button
          onClick={this.toggleDrawer}
          variant="text"
          style={webStyle.logout}
        >
          {configJSON.logout}
        </Button>
      </Box>
      <Divider />
      <List>
        {this.state.drawerItems?.length > 0 &&
          this.state.drawerItems?.map((item: any) => {
            let data = item?.data?.attributes;

            if (data?.position !== "left") {
              return null;
            }

            return (
              <React.Fragment key={data?.id}>
                {data?.items?.map((mItem: any) => {
                  return (
                    <ListItem
                      button
                      key={mItem?.id?.toString()}
                      onClick={() => this.onPressMenuItem(mItem)}
                    >
                      <ListItemIcon>
                        <img src={item.icon} style={webStyle.drawerItemIcon} />
                      </ListItemIcon>
                      <ListItemText primary={mItem?.name} />
                    </ListItem>
                  );
                })}
              </React.Fragment>
            );
          })}
      </List>
    </div>
  );

  renderModal = () => {
    return (
      <div>
        <Modal
          open={this.state.openModal}
          onClose={this.handleClose}
          style={webStyle.modalStyle}
        >
          <ModalBox>
            <ModalMainBox>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                <CropBox>
                  <img src={backIcon} />
                  <Typography onClick={this.handleClose} className='cropTextStyle'>{configJSON.cropText}</Typography>
                  <Typography className='nextTextStyle'>{configJSON.nextText}</Typography>
                </CropBox>
                <ImageBox>
                  <img src={albumImg} />
                </ImageBox>
                <ZoomContainer>
                  <Box className='zoomOutInBox'>
                    <Box className='zoomOut'>
                      <img src={zoomInIcon} />
                    </Box>
                    <Box className='zoomOut'>
                      <img src={zoomInIcon} />
                    </Box>
                  </Box>
                  <Box className='zoomOut'>
                    <img src={copyIcon} />
                  </Box>
                </ZoomContainer>
              </Typography>
            </ModalMainBox>
          </ModalBox>
        </Modal>
      </div>
    )
  };

  renderModalSecond = () => {
    return (
      <div>
        <Modal
          open={this.state.shareModal}
          onClose={this.handleShareModalClose}
           style={webStyle.modalStyle}
        >
          <NewPostModal>
            <NewPost>
              <CreatePostBox>
                <img src={backIcon} />
                <Typography className='cropTextStyle'>{configJSON.creatpostText}</Typography>
                <Typography className='nextTextStyle'
                onClick={this.handleShareModalClose}
                >{configJSON.shareText}</Typography>
              </CreatePostBox>
              <ModalConatiner>
                <ImageContainer xs={8}>
                  <img src={postImg} className="postImageStyle" />
                </ImageContainer>
                <SideContainer xs={4}>
                  <Box className="profileBox">
                    <Box className="profileCircle" />
                    <Typography className="postTextStyle">{configJSON.postText}</Typography>
                  </Box>
                  <Box className="textInputBox">
                  <TextareaAutosize maxRows={10}
                    className="captionTextStyle"
                    aria-label="maximum height"
                    placeholder="Maximum 4 rows" defaultValue="Write a caption..." />
                    </Box>
                    <Box className="smileBox">
                       <img src={smileImg}/>
                       <Typography className="numberTestStyle">{configJSON.numberText}</Typography>
                    </Box>
                    <Box className="smileBox">
                       <Typography className="locationText">{configJSON.locationText}</Typography>
                       <img src={mapIcon}/>
                    </Box>
                    <Box className="smileBox">
                       <Typography className="locationText">{configJSON.accessibilityText}</Typography>
                       <img src={downIcon}/>
                    </Box>
                    <Box className="smileBox">
                       <Typography className="locationText">{configJSON.settingsText}</Typography>
                       <img src={downIcon}/>
                    </Box>
                </SideContainer>
              </ModalConatiner>
            </NewPost>
          </NewPostModal>
        </Modal>
      </div>
    )
  };
   renderDesktopSideBar = () => {
    const icons = [
      { src: homeIcon, alt: "Home",activeSrc:homeActiveIcon},
      { src: porifleIcon, alt: "Profile",activeSrc:profileActiveIcon },
      { src: createPostIcon, alt: "Post",activeSrc:postActiveIcon },
      { src: exploreIcon, alt: "Explore",activeSrc:exploreActiveIcon },
      { src: appointmentIcon, alt: "Appointments",activeSrc:appointmentActiveIcon },
      { src: moodIcon, alt: "Moodboards",activeSrc:moodBoardActiveIcon },
      { src: msgIcon, alt: "Messages",activeSrc:messagesActiveIcon },
      { src: likesIcon, alt: "Likes",activeSrc:likesActiveIcon },
      { src: settingsIcon, alt: "Settings",activeSrc:settingsActiveIcon },
      { src: walletIcon, alt: "Wallet",activeSrc:walletIcon },
    ];
    return (
    <Box className="sidebar_main_container">
    {icons.map((icon, index) => (
      <Box
      key={index}
      className={`sidebar_icon_box ${this.state.activeIndex === index ? "active" : ""}`}
      onClick={() => this.handleIconClick(index)}
      data-test-id={`icon-test-id-${index}`} 
      >
      <Box className="sidebar_icon_container">
      <img src={this.state.activeIndex===index?icon.activeSrc:icon.src} alt={icon.alt} data-test-id={`icon-img-test-id-${index}`} className="sidebar_icon_style" />
      <Divider className="divider_style" />
        </Box>
      <Box className='sidebar_icon_name_container'>
        <Typography className={`sidebar_icon-name ${this.state.activeIndex === index ? "active" : ""}`}
        >{icon.alt}</Typography>
      </Box>
      </Box>
      ))}
      </Box>
    );
  };

  renderMobileNavigation=()=>{
    const icons = [
      { src: homeIcon, alt: "Home",activeSrc:homeActiveIcon},
      { src: createPostIcon, alt: "Create Post",activeSrc:postActiveIcon },
      { src: exploreIcon, alt: "Explore",activeSrc:exploreActiveIcon },
      { src: appointmentIcon, alt: "Appointment",activeSrc:appointmentActiveIcon },
      { src: porifleIcon, alt: "Profile",activeSrc:profileActiveIcon },
    ];
    return (
    <Box className="sidebar_main_container">
    {icons.map((icon, index) => (
      <Box
      key={index}
      className={`sidebar_icon_box ${this.state.activeIndex === index ? "active" : ""}`}
      onClick={() => this.handleIconClick(index)}
      data-test-id={`mobile-icon-test-id-${index}`}
      >
         <Divider className="divider_style" />
      <img src={this.state.activeIndex===index?icon.activeSrc:icon.src} data-test-id={`mobile-icon-img-test-id-${index}`}  alt={icon.alt} className="sidebar_icon_style" />
      <Typography className="navigation_text">{icon.alt}</Typography>
      </Box>
      ))}
      </Box>
    );
  }

  renderResponsiveView = () => {
    return (
      <ResponsiveContainer>
        <Box className="mainBoxStyle">
          <Box className="newPostView">
            <img src={backArrow} />
            <Typography className="newPostText">{configJSON.newPostText}</Typography>
            <Typography className="shareTestStyle">{configJSON.shareText}</Typography>
          </Box>
          <Box className="imageContainer">
            <img src={postImg} className="postImageStyles" />
          </Box>
          <Box className="titleInputComponent">
            <TextareaAutosize maxRows={10}
              className="titleInput"
              aria-label="maximum height"
              placeholder="Maximum 4 rows" defaultValue="Look title..." />
          </Box>
          <Divider />
          <Box className="descriptionBox">
            <TextareaAutosize maxRows={10}
              className="titleInput"
              aria-label="maximum height"
              placeholder="Maximum 4 rows" defaultValue="Write a description..." />
          </Box>
          <Divider />

          <Box className="customerView">
            <Box className="customerContainer">
              <img src={cutomerIcon} />
              <Typography className="callobaretText">{configJSON.collaboratetext}</Typography>
            </Box>
            <img src={rightArrow} />
          </Box>

          <Box className="customerView">
            <Box className="customerContainer">
              <img src={locationIcon} />
              <Typography className="callobaretText">{configJSON.locationText}</Typography>
            </Box>
            <img src={rightArrow} />
          </Box>

          <Box className="customerView">
            <Box className="customerContainer">
              <img src={categoryIcon} />
              <Typography className="callobaretText">{configJSON.categoryText}</Typography>
            </Box>
            <img src={rightArrow} />
          </Box>

          <Box className="customerView">
            <Box className="customerContainer">
              <img src={cutomerIcon} />
              <Typography className="callobaretText">{configJSON.styleText}</Typography>
            </Box>
            <img src={rightArrow} />
          </Box>

          <ShareButton
            type="submit"
            fullWidth
            variant="contained">
            {configJSON.sharebtnText}
          </ShareButton>
        </Box>
      </ResponsiveContainer>
    )
  }

  renderHeader = () => {
    return (
      <HeaderMainContainer>
        <Box className="header">
          <MobileHeaderContainer className="mobile-header">
            <MenuSharpIcon />
            <img src={quilaLogo} alt="Logo" width="63px" height="33px" />
            <Box className="icons_container">
              <img src={cartIcon} alt="Cart" className="icons_style" />
              <img src={notificationIccon} alt="Notifications" className="icons_style" />
            </Box>
          </MobileHeaderContainer>
          <DeskTopHeaderContainer className="desktop-header">
            <Box>
              <img src={quilaLogo} alt="Logo" width="88px" height="46.56px" />
            </Box>
            <Box className="icons_container">
              <img src={searchIcon} alt="Search" className="icons_style" />
              <img src={cartIcon} alt="Cart" className="icons_style" />
              <img src={notificationIccon} alt="Notifications" className="icons_style" />
              <img src={profileImg} alt="Profile" className="icons_style" />
            </Box>
          </DeskTopHeaderContainer>
        </Box>
        <Divider className="divider_style"/>
      </HeaderMainContainer>
    );
  };

  renderTextFeilds=()=>{
    return(
      <Box style={webStyle.topContainerStyle}>
      <TextField
        type="search"
        placeholder="Search for service or business name"
        variant="outlined"
        style={webStyle.searchFeildWidth}
        className="search_input_style"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={searchIcon} width={"20px"} height={"20px"}/>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        type="text"
        placeholder="Location"
        variant="outlined"
        style={webStyle.locationFeildWidth}
        className="input_style"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={locationIcon} width={"16px"} height={"20px"}/>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        placeholder="Date"
        variant="outlined"
        data-test-id="date-test-id"
        value={this.state.dateValue}
        onChange={this.handleDateChange}
        style={webStyle.dateFeildWidth}
        className="input_style"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={dataIcon} width={"18px"} height={"20px"} data-test-id="date-icon-test-id"/>
            </InputAdornment>
          ),
          inputProps: {
            onFocus: (event) => {
              (event.target as HTMLInputElement).type = "date";
            },
            onBlur: (event) => {
              (event.target as HTMLInputElement).type = "text";
            },
          },
        }}
      />
      <TextField
        placeholder="Time"
        variant="outlined"
        data-test-id="time-test-id" 
        value={this.state.timeValue}
        onChange={this.handleTimeChange}
        style={webStyle.timeFeildWidth}
        className="input_style"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={timeIcon} width={"20px"} height={"20px"} data-test-id="time-icon-test-id"/>
            </InputAdornment>
          ),
          inputProps: {
            onFocus: (event) => {
              (event.target as HTMLInputElement).type = "time";
            },
            onBlur: (event) => {
              (event.target as HTMLInputElement).type = "text";
            },
          },
        }}
      />
    </Box>
    )
  }

  renderCategoryImages=()=>{
    const categoryData=[
      {id:1,img:postImg,alt:"Massages",name:"Massages"},
      {id:2,img:postImg,alt:"Hair Salon",name:"Hair Salons"},
      {id:3,img:postImg,alt:"Barbershop",name:"Barbershop"},
      {id:4,img:postImg,alt:"Nail Salon",name:"Nail Salon"},
      {id:5,img:postImg,alt:"Skin & Beauty",name:"Skin & Beauty"},
      {id:6,img:postImg,alt:"Massages",name:"Massages"},
      {id:7,img:postImg,alt:"Hair Salon",name:"Hair Salons"},
      {id:8,img:postImg,alt:"Barbershop",name:"Barbershop"},
      {id:9,img:postImg,alt:"Nail Salon",name:"Nail Salon"},
      {id:10,img:postImg,alt:"Skin & Beauty",name:"Skin & Beauty"},
      {id:11,img:postImg,alt:"Massages",name:"Massages"},
      {id:12,img:postImg,alt:"Hair Salon",name:"Hair Salons"},
      {id:13,img:postImg,alt:"Barbershop",name:"Barbershop"},
      {id:14,img:postImg,alt:"Nail Salon",name:"Nail Salon"},  
     
    ]
    return(
      <Box className="images_main_container">
        {categoryData.map((eachData)=>(
          <Box className="images_sub_container" key={eachData.id}>
            <img src={eachData.img} alt={eachData.alt} className="img_style"/>
            <Typography className="text_style">{eachData.name}</Typography>
          </Box>
        ))}
       </Box>
    )
  }
  
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <>
          {this.renderHeader()}
          <MainContainer style={{height:'100vh',display:"flex"}}>
            {this.renderDesktopSideBar()}
             <Box className="feed_container">
                  {this.renderTextFeilds()}
                  {this.renderCategoryImages()}
             </Box>
          </MainContainer>
          <MobileNavigation>
          {this.renderMobileNavigation()}
          </MobileNavigation>
      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const webStyle = {
  mainContainer:{
    display:"flex",
  },
  topContainerStyle:{display:"flex",
    alignItems:"center",
    gap:20,
    },
   searchFeildWidth:{
     width:"100%",
  },
  locationFeildWidth:{
    width:"70%"
  },
  dateFeildWidth:{
    width:"40%"
  },
  timeFeildWidth:{
     width:"40%"
  },
  userProfileWrapper: {
    display: "flex",
    flexDirection: "column" as "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 30,
  },
  userProfile: {
    width: 100,
  },
  userDesignation: {
    fontSize: 14,
    color: "#ccc",
  },
  logout: {
    color: "#2196F3",
    marginTop: 15,
    marginBottom: 5,
  },
  drawerItemIcon: {
    width: 20,
    selfAlign: "center",
    marginLeft: 7,
  },

  dividerStyle:{
    margin:"15px"
  },

  dividerStyleTwo:{
    margin:"15px 0px"
  },

  boxConatinerTwo: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width:"90%",
    margin:"10px"
  },

  usersImgStyle: {
    width: "40px",
    height: "40px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "200px"
  },

  logoutBtnStyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around"
  },

  userNameTexts: {
    color: "#344054",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px"
  },

  userEmailText: {
    color: "#475467",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "20px"
  },

  usersProdileBoxStyle:{
    width:"70%"
  },

  modalStyle:{
    overflow: "scroll" 
  }
};

const QualImage = styled("img")({
  padding: "10px",
  width: "50%",
  xheight: "10x0%",
  objectFit: "cover",
  "@media (max-width:598px)": {
    flexDirection: "column",
    width: "33%"
  }
});


const ModalBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  margimg: "10px",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const ModalMainBox = styled(Box)({
  width: "620px",
  background: "#D9D9D9",
  height: "662px",
  boxShadow: "24",
  borderRadius: "15px",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const CropBox = styled(Box)({
  flexShrink: 0,
  background: "#262626",
  alignItems: "center",
  padding: "0 10px",
  display: "flex",
  justifyContent: "space-between",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",
  height: "43px",

  "& .cropTextStyle": {
    fontSize: "18px",
    fontStyle: "normal",
    color: "#FFF",
    fontFamily: "Inter",
    fontWeight: 700,
    lineHeight: "140%",
    letterSpacing: "-0.36px"
  },

  "& .nextTextStyle": {
    color: "#0095F6",
    fontFamily: "Inter",
    lineHeight: "140 %",
    letterSpacing: "-0.28px",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 700,
  },
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const ImageBox = styled(Box)({
  width: "620px",
  height: "560px",
  flexShrink: 0,
  background: "#D9D9D9",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const ZoomContainer = styled(Box)({
  height: "43px",
  flexShrink: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "0 10px",
  marginBottom: "20px",
  "& .zoomOut": {
    height: "40px",
    width: "40px",
    borderRadius: "20px",
    background: "#2b2b2b",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },

  "& .zoomOutInBox": {
    display: "flex",
    alignItems: "center",
    width: "100px",
    justifyContent: "space-between"
  },
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const ModalConatiner = styled(Box)({
  display:"flex",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const NewPost = styled(Box)({
  width: "961px",
  height: "619px",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const NewPostModal = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "20px",
  margimg: "10px",
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const CreatePostBox = styled(Box)({
  height: "43px",
  flexShrink: 0,
  background: "#262626",
  justifyContent: "space-between",
  alignItems: "center",
  display: "flex",
  padding: "0 10px",
  borderTopLeftRadius: "12px",
  borderTopRightRadius: "12px",

  "& .cropTextStyle": {
    fontWeight: 700,
    lineHeight: "140%",
    letterSpacing: "-0.36px",
    color: "#FFF",
    fontFamily: "Inter",
    fontSize: "18px",
    fontStyle: "normal"
  },

  "& .nextTextStyle": {
    color: "#0095F6",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 700,
    lineHeight: "140 %",
    fontStyle: "normal",
    letterSpacing: "-0.28px"
  }
});

const ImageContainer = styled(Grid)({
  "& .postImageStyle":{
    width:"100%"
  },
  "@media (max-width: 600px)": {
    display:"none"
  }
})

const SideContainer = styled(Grid)({
  background:"#262626",
  "& .profileCircle":{
    width: "30px",
    height: "30px",
    backgroundColor: "#D9D9D9",
    borderRadius:"20px"
  },

  "& .postTextStyle":{
    color: "#FFFFFF",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.28px",
    marginLeft:"20px"
  },

  "& .textInputBox":{
    height:"150px"
  },

  "& .profileBox":{
    display:"flex",
    padding:"20px",
    alignItems:"center"
  },

  "& .captionTextStyle":{
    margin: "15px",
    height: "200px",
    overflow: "hidden",
    width: "295px",
    backgroundColor: "#262626",
    border: "none",
    color: "#737373",
    fontSsize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "-0.32px",
    resize:"none"
  },

  "& .smileBox":{
    display:"flex",
    margin:"20px",
    justifyContent:"space-between"
  },

  "& .numberTestStyle": {
    color: "#737373",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "-0.24px"
  },

  "& .locationText": {
    color: "#A8A8A8",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "-0.32px"
  },
  "@media (max-width: 600px)": {
    display:"none"
  }
});

const MainContainer = styled(Box)({
  zIndex: -1,
  overflowX:"hidden",
  "& .sidebar_main_container":{
     background: 'rgba(255, 255, 255, 1)', 
      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      width:"45px",
      position:"absolute",
      zIndex:99,
      transition: "width 0.3s ease",
      "&:hover": {
        width:"200px"
      },
      display:"flex",
      flexDirection:"column",
      justifyContent:"space-between",
      top:75,
      bottom:0,
      padding:"25px 35px 45px 30px",
    "@media (max-width:598px)":{
      display:'none',
    }
},

"& .sidebar_icon_name_container": {
  marginTop:"-8px",
  opacity: 0,
  transition: "opacity 0.3s ease, transform 0.3s ease",
  transform: "translateX(-10px)",
  pointerEvents: "none", 
},

"& .sidebar_main_container:hover .sidebar_icon_name_container": {
  opacity: 1, 
  transform: "translateX(0)",
  pointerEvents: "auto", 
},
"& .sidebar_icon_container":{
   display:"flex",
   flexDirection:"column",
   alignItems:"center",
   gap:"8px"
},
"& .sidebar_icon_box": {
  cursor:"pointer",
  display:'flex',
  alignItems:'center',
  gap:"12px"
},
"& .sidebar_icon-name":{
    fontFamily:"Degular",
    fontWeight:500,
    fontSize:"20px",
    color:"#8F8F8F",
    transition: "color 0.3s ease", 
},
"& .sidebar_icon-name.active": {
  color: "#6551EA",
  fontWeight:700
},
"& .sidebar_icon_style": {
  width: "30px",
  height: "30px",
  transition: "color 0.3s ease",
},
"& .divider_style": {
  height:"6px",
     width:'44px',
     backgroundColor:"#6551EA",
     borderRadius:"2px",
  visibility: "hidden",
},
"& .active": {
  "& .sidebar_icon_style": {
    width:"30px",
    height:"30px"
  },
  "& .divider_style": {
    visibility: "visible",
    height:"6px",
    width:'44px',
    backgroundColor:"#6551EA",
    borderRadius:"2px",
  },
},
  "& .sidebar_container": {
    display:'flex',
  "@media (max-width:598px)":{
    display:'none',
    background:'red'
  },
     
  },


  "& .feed_container":{
    flex:2,
    padding:"15px 10px",
    overflow:"auto",
    marginLeft:"110px",
    "@media (max-width:598px)":{
        marginLeft:0
    }
  },
  "& .text_feilds_container":{
    display:'flex',
    gap:15,
  },
  "& .search_input_style":{
    padding:"0px",
    "@media (max-width:550px)":{
      padding:"0 18px",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      color: "#404040",
      width:'100%',
      height:"40px",
      fontSize:"14px",
      fontWeight:400,
      fontFamily:"Inter",
      border:"1px",
      "& fieldset": {
      borderColor: "#D9D9D9", 
      borderWidth: "1px", 
    },
    "&:hover fieldset": {
      borderColor: "#D9D9D9", 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D9D9D9", 
      borderWidth: "1px", 
    },
    },
    "& input::placeholder": {
    color: "#404040", 
    opacity: 1, 
  },
    "& .MuiInputAdornment-root": {
      marginRight: "8px",
    },
  },
  "& .input_style":{
    display:'block',
    "@media (max-width:550px)":{
       display:"none"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "6px",
      color: "#404040",
      width:'100%',
      height:"40px",
      fontSize:"14px",
      fontWeight:400,
      fontFamily:"Inter",
      border:"1px",
      "& fieldset": {
      borderColor: "#D9D9D9", 
      borderWidth: "1px", 
    },
    "&:hover fieldset": {
      borderColor: "#D9D9D9", 
    },
    "&.Mui-focused fieldset": {
      borderColor: "#D9D9D9", 
      borderWidth: "1px", 
    },
    },
    "& input::placeholder": {
    color: "#404040", 
    opacity: 1, 
  },
   
  },
  "& .img_style":{
    width:"80px",
    height:"80px",
    borderRadius:"100px"
  },
  "& .images_sub_container":{
    display:"flex",
    flexDirection:'column',
    gap:10,
    justifyContent:"center",
    alignItems: "center", 
  },
  "& .images_main_container":{
    display:"flex",
    justifyContent:"space-between",
    marginTop:"25px",
    overflowX: "auto",
    scrollbarWidth: "none",
    gap:10 
  },
  "& .text_style":{
    fontSize:"10px",
    fontWeight:500,
    fontFamily:"Inter",
    textAlign:'center'
  }
  
})

const ResponsiveContainer = styled(Box)({
   display:"none",
   "@media (max-width: 600px)": {
    display:"block"
   },

   "& .mainBoxStyle":{
      padding:"20px"
   },

   "& .newPostView":{
    height:"40px",
    witdh:"30%",
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
   }, 

  "& .newPostText": {
    color: "#010101",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: -"0.32px"
  },

  "& .shareTestStyle": {
    color: "#6551EA",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.32px"
  },

  "& .imageContainer":{
    height:"250px",
    width:"100%",
    display:"flex",
    alignItems:"center",
    justifyContent:"center"
  },

  "& .postImageStyles":{
    height:"200px",
    width:"200px"
  },

  "& .titleInput": {
    border: "none",
    color: "#898989",
    fontSize: "13px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "140%",
    letterSpacing: "-0.26px",
    resize:"none",
    width:"100%"
  },

  "& .titleInputComponent":{
    height:"50px"
  },

  "& .customerView":{
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between"
  },

  "& .descriptionBox":{
    height:"60px",
    marginTop:"7%"
  },

  "& .customerContainer":{
    display:"flex",
    marginTop:"20px",
  },

  "& .callobaretText": {
    color: "#010101",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "140%",
    letterSpacing: "-0.28px",
    marginLeft:"10px"
  }  
});

const ShareButton = styled(Button)({
  marginTop: '3rem',
  textTransform: 'none',
  backgroundColor: '#0e1518',
  height: '45px',
  color: "#fff",
  fontSize: "15px",
  fontWeight: 600,
  marginBottom: "15px",
  "&:hover": {
    backgroundColor: '#0e1518'
  }
});

const HeaderMainContainer=styled(Box)({
  position:"sticky",
  top:0,
  background:"#fff",
  ZIndex:99,
      "& .divider_style":{
      display:"flex",
      "@media (max-width:797px)":{
        display:"none"
      }
    }
})

const DeskTopHeaderContainer = styled(Box)({
  display: 'none',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '15px 35px',
  '& .icons_container': {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  '& .icons_style': {
    width: '30px',
    height: '30px',
  },
  '@media (min-width: 601px)': {
    display: 'flex',
  },
});


const MobileHeaderContainer = styled(Box)({
  display: 'flex', 
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '25px 20px',
  '& .icons_container': {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  '& .icons_style': {
    width: '20px',
    height: '20px',
  },
  '@media (min-width: 601px)': {
    display: 'none',
  },
});
const MobileNavigation=styled(Box)({
  "& .sidebar_main_container":{
position:"relative",
bottom:0,
display:"flex",justifyContent:"space-between",alignItems:"center",padding:"10px 20px 30px 20px",
"@media (min-width:598px)":{
 display:'none',
}
},
"& .sidebar_icon_box": {
cursor:"pointer",
display:'flex',
flexDirection:"column",
alignItems:'center',
gap:"8px"
},
"& .sidebar_icon_style": {
width: "16px",
height: "16px",
transition: "color 0.3s ease",
},
"& .divider_style": {
height:"3px",
width:'40px',
backgroundColor:"#6551EA",
borderRadius:"1px",
visibility: "hidden",
marginTop:-10
},
"& .active": {
"& .sidebar_icon_style": {
width:"16px",
height:"16px"
},
"& .divider_style": {
visibility: "visible",
height:"3px",
width:'40px',
backgroundColor:"#6551EA",
borderRadius:"1px",
},

},
"& .navigation_text":{
  fontSize:"10px",
  fontWeight:600,
  fontFamily:"Degular",
  color:"#7F7F7F"
}
})
// Customizable Area End
