Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "Log in with Google";
exports.labelOr = "OR";
exports.labelTitle = "Log in";

exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.emailRegx = /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.loggedinApiEndpoint = "logins" ;
exports.labelSignup = "Sign up";
exports.forgotPassword="Don't have an account ?";
exports.forgotPassTwo = "Forgot password";
exports.passReg = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
exports.passOneReg = /\d/;
exports.passSecondReg = /^\S+$/;
exports.welcomeText = "Welcome back!";
exports.loginText = "Log in to your account.";
exports.emailText = "Email*";
exports.passErrorText="Must be 8 characters, with at least one lowercase letter, one uppercase letter, one number, and one special character.";
exports.emailErrorText="Invaild email address. Please try again.";
exports.loginBtnText = "Log in";
exports.googleLoginBtnText = "Log in with Google";
exports.dontHaveAccountText = "Don't have an account?";
exports.signUpText = "Sign Up";
exports.back = "Back";
exports.tryAgainText = "Try Again";
exports.loginFailedText = "Login Failed";
exports.loginFailedDesc = "We couldn’t log you in. Please check your credentials and try again.";
exports.formApprovalAPiMethod = "POST";
exports.formApprovalApiEndPoint = "bx_block_formapprovalworkflow/formapprovalworkflows";
// Customizable Area End