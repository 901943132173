Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "GoogleCalendarSync";
exports.labelBodyText = "GoogleCalendarSync Body";

exports.btnExampleTitle = "CLICK ME";

exports.syncWithGoogleCalendar = "Synch With Google Calendar";
exports.cancelText = "Close";
exports.guestText = "Guests";
exports.addEventText = "Add Event";
exports.saveText = "Create";
exports.successMsg = "Event created!";
exports.errorMsg = "Something went wrong! Please try again...";
exports.signOut = "Signout";

exports.summaryPlaceholder = "Summary";
exports.startDatePlaceholder = "Start Date";
exports.endDatePlaceholder = "End Date";
exports.locationPlaceholder = "Location";
exports.descriptionPlaceholder = "Description";
exports.attendeesPlaceholder = "Attendees Email Id";
exports.attendeesHelperText = "Enter attendeed email with separated by common";

exports.googleEventEntPoint =
  "https://www.googleapis.com/calendar/v3/calendars/primary/events";
exports.googleEventApiContentType = "application/json";
exports.googleCalendarIOSClientId =
  "649592030497-1p8g6ec1k5ssja8sqirs2du94cu6auno.apps.googleusercontent.com";
exports.googleCalendarAndroidClientId =
  "855520919327-cktsoaac70dtudhartgir8l26337lki1.apps.googleusercontent.com";
exports.googleCalendarClientId =
  "784473325733-dic4tjt1sqe9pti5ovgle8ci4lc7dilp.apps.googleusercontent.com";
exports.googleCalendarScope = "https://www.googleapis.com/auth/calendar";
exports.getEventApiMethod = "GET";
exports.addEventApiMethod = "POST";
// Customizable Area End
