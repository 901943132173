import React from "react";

// Customizable Area Start
import { Box, Typography, Button, Divider, Grid } from '@material-ui/core';
import styled from '@material-ui/styles/styled';
import { rightBgImg, backIcon, blueSparkImg} from "./assets"

import TermsConditionsController, {
  Props,
} from "./TermsConditionsController";
// Customizable Area End

export default class TermsConditions extends TermsConditionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { termsConds } = this.state;
    return (
      <Box>
      <Grid container>
        {/* Content Side */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <ContentContainer>
            <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
              <BackButtonContainer>
                <BackIcon onClick={() => (window.location.href = "/EmailAccountRegistration")} src={backIcon} alt="Back" />
                <BackText variant="body1" style={webStyle.backBtnText}>Back</BackText>
              </BackButtonContainer>
              <img src={blueSparkImg} alt="Icon" style={{ width: '40px', height: '39px' }} />
            </Box>
            <ResponsiveBox>
            <TermsTitle>Terms of Service</TermsTitle>
            {termsConds && (
              <>
              <Typography variant="subtitle2" gutterBottom style={webStyle.lastUpdatedText}>
                Last updated on {termsConds.created_at}
              </Typography>
              <Divider />
              <MainContentBox mt={2} >              
                <Typography variant="body2" paragraph style={webStyle.firstPara}>
                  <div
                      dangerouslySetInnerHTML={{
                        __html: termsConds.description,
                      }}
                    />
                </Typography>           
              </MainContentBox>
            </>
            )}
            <ActionsContainer>
              <StyledButtonAgree variant="contained" onClick={this.getTermsAgree} 
                  data-test-id="agrreButtonClick" >
                Agree
              </StyledButtonAgree>
              <StyledButtonCancel variant="outlined" onClick={this.goToRegistrationPage} 
                  data-test-id="cancelButtonClick" >
                Cancel
              </StyledButtonCancel>
            </ActionsContainer>
            </ResponsiveBox>
          </ContentContainer>
        </Grid>

        {/* Image Side */}
        <Grid item xs={false} sm={false} md={6} lg={6} xl={6}>
           <Box>
              <StyledImage
                src={rightBgImg}
                alt="login image"
              />
          </Box>
        </Grid>
      </Grid>
    </Box>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const ContentContainer = styled(Box)({
  padding: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  "@media (max-width: 800px)": {
    padding: "15px",
  },
});

const ActionsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  marginTop: '80px',
});

const StyledButtonCancel = styled(Button)({
  height:"44px",
  width: '100%',
  maxWidth: '358px',
  textTransform: 'capitalize',
  fontSize: '18px',
  fontWeight:600,
  borderRadius: '8px',
  fontFamily:"Degular",
  lineHeight:"25.2px",
  border:"1px solid #000000",
  color:"#000000"
});

const StyledButtonAgree = styled(Button)({
  height:"44px",
  width: '100%',
  maxWidth: '358px',
  textTransform: 'capitalize',
  fontSize: '18px',
  fontWeight:600,
  borderRadius: '8px',
  fontFamily:"Degular",
  lineHeight:"25.2px",
  backgroundColor:"#6551EA",
  color:"#FFFFFF",
  "&:hover": {
    cursor: "pointer",
    color:"#FFFFFF",
    backgroundColor:"#6551EA",
  },
});

const TermsTitle = styled(Typography)({
  color: '#6551EA',
  marginTop:"10px",
  fontWeight: 700,
  fontSize: '28px',
  marginBottom: '6px',
  fontFamily:"Degular"
});

const StyledImage = styled("img")({
  width: "50%",
  right: 0,
  height: "100vh",
  position:"fixed",
  objectFit: "cover",
  opacity: "1",
  display: "flex",
  "@media (max-width: 800px)": {
    display: "none"
  }
});

const ResponsiveBox = styled(Box)({
  padding: "28px",
  marginTop: "20px",
  "@media (max-width: 800px)": {
    marginTop: "20px",
    padding: "8px",
  },
});

const BackButtonContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  left:0,
  "&:hover": {
    cursor: "pointer",
  },
});

const BackIcon = styled("img")({
  width:"40px",
  height:"40px",
  paddingRight:"5px",
  cursor: "pointer",
  "&:hover": {
    cursor: "pointer",
  }
});

const BackText = styled(Typography)({
  fontFamily:"Degular Display",
  fontWeight:500,
  fontSize:"16px",
  lineHeight:"22.4px",
  color:"#757676",
  "@media (max-width: 800px)": {
    display: "none",
  },
});

const MainContentBox = styled(Box)({
  marginTop:"23px", 
  padding:"5px",
  "@media (max-width: 800px)": {
    padding:"1px",
  },
});

const webStyle ={
  backBtnText: {
    marginLeft:"4px", 
    fontFamily:"Degular Display",
    fontWeight:500,
    fontSize:"16px",
    lineHeight:"22.4px",
    color:"#757676",
  },
  lastUpdatedText: {
    color:"#757676", 
    fontWeight:600, 
    fontSize:"14px",
    fontFamily:"Degular",
    marginBottom:"20px",
    marginLeft:"2px",
  },
  firstPara: {
    color:"#6B6B6B", 
    fontWeight:400,
    fontSize:"16px",
    fontFamily:"Degular",
    lineHeight:"24px",
  },

}
// Customizable Area End
