// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import { string } from "yup";

export const configJSON = require("./config");

export interface Props { }

export interface ErrorModalProps {
  onClose: () => void;
  errroDescription:string;
  className:string
}
interface AccountAttributes {
  first_name: string;
  last_name: string;
  full_name: string | null;
  email: string;
  activated: boolean;
  full_phone_number: string;
  type: string;
  user_type: string;
  user_type_name: string | null;
  created_at: string;
  updated_at: string;
  profile_img: string | null;
  country: string | null;
  timezone: string | null;
  bio: string | null;
  role_name: string | null;
  name_on_card: string | null;
  card_number: string | null;
  exp_month: string | null;
  exp_year: string | null;
  cvv_number: string | null;
  billing_address_same_acc: boolean;
  city: string | null;
  state: string | null;
  zip_code: string | null;
  talent_account: boolean;
  billing_cvv_number: string | null;
  attachment: {
    data: any | null;
  };
}

interface AccountData {
  id: string;
  type: string;
  attributes: AccountAttributes;
}

interface BeautyServices {
  id: number;
  beauty_service_name: string;
  created_at: string;
  updated_at: string;
}

interface TeamSize {
  id: number;
  team_size: string;
  created_at: string;
  updated_at: string;
}

interface LicenseInfo {
  certification_number: string | null;
  expiration_date: string | null;
  valid_state: string | null;
  certification_or_licenses: string | null;
  issuing_authority: string | null;
  institution_of_study: string | null;
}

interface AllFormApprovalDetailsAttributes {
  id: number;
  business_name: string;
  website: string;
  locality: string;
  last_step: string;
  flow_status: string | null;
  account: {
    data: AccountData;
  };
  beauty_services: BeautyServices[];
  team_size: TeamSize | null;
  work_addresses: any[];
  privacy_concern: any[];
  business_hours: any[];
  license_info: LicenseInfo;
}

interface AllFormApprovalDetailsData {
  id: string;
  type: string;
  attributes: AllFormApprovalDetailsAttributes;
}

interface AllFormApprovalDetailsTypes {
  data: AllFormApprovalDetailsData;
}
interface Answer {
    business_name:string,
    web:string
  };
  interface BeautyService {
    id: string; 
    type: string;
    attributes: {
      id:number;
      beauty_service_name: string;
    };
  }

  interface TemaSize {
    id: string; 
    type: string;
    attributes: {
      id:number;
      team_size: string;
    };
  }
  
  interface BreakArray {
    break_start_time: "",
    break_end_time: ""
  }
  interface WorkingDay {
      day: string,
      work_start_time: string,
      work_end_time: string,
      business_hour_breaks: BreakArray[]
}
interface BusinessHourResult {
  last_step: string,
  business_hours:WorkingDay[]
}
  interface BreakList {
    opening:string,
    closing:string,
    key:number
    };
  interface WeekDay {
    value:string,
    clicked:boolean
  }

interface S {
  currentLocation: {
    label: string;
    value: string;
    current_latitude: string;
    current_longitude: string;
  };
  destination: {
    destination_latitude: string;
    destination_longitude: string;
  } | null;
  cutomerData: { [keyVal: string]: string | number };
  customerID: string;
  SurveyQuestion: any;
  answerOfQuestion:Answer;
  swtichButton:boolean;
  yearFeildOpen:boolean,
  isChecked:boolean;
  formDetails:AllFormApprovalDetailsTypes| null;
  beautyServicesQuestionsList: BeautyService[];
  selectedBeautyServices: number[];
  teamSizesQuestionsList:TemaSize[];
  selectedTeamSize:null | number;
  nameError: boolean;  
  invalidBusinessName: boolean;
  websiteError: boolean;
  isSubmitted: boolean, 
  showErrorModal: boolean,
  businessTypeError:boolean,
  teamSizeError:boolean,
  selectedLocality:null | string,
  privacyConcerns:boolean,
  localityError:boolean,
  monthPlaceholder: any,
  weekDay:WeekDay[]
  monthFeildOpen:boolean,
  timeList:string[];
  openingHr:any,
  stateFields:string[],
  breakList:BreakList[],
  breakCount:number,
  stateList:string[],
  hourPopUp:boolean,
  copyPopUp:boolean,
  yearPlaceholder: any,
  datePlaceholder:string,
  workingDay:WorkingDay,
  dateFeildOpen:boolean
  licenseDate:any
  businessHourResult:BusinessHourResult
}

interface SS { }

interface BusinnesNameApi{
  workflow: {
    id: number | null;
    title: string | null;
    created_at: string;
    updated_at: string;
    business_name: string | null;
    website: string | null;
    locality: string | null;
    last_step: string | null;
    flow_status: string | null;
    account_id: number |null;
    team_size_id: string | null;
  };
  message: string;
}

interface BusinessNamePatchApi{
    last_step: string,
    business_name: string,
    website: string
}
interface BeautyTypeApi{
    last_step: string,
    beauty_services: Array<number>
}

interface TeamSizeApi{
    last_step: string,
    team_size_id: number | null
}

interface LocalityApi{
     last_step: string,
     locality: string
}

interface PrivacyConcernsApi{
      last_step: string,
      private_account: boolean

}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  headers?: Record<string, string>; 
}

export default class FormApprovalWorkflowController extends BlockComponent<
  Props,
  S,
  SS
> {
  getApicallData: string = "";
  postBusinessNameData:string="";
  postBeautyServicesData:string="";
  getBeautyServicesQuestionsApiData:string="";
  getTeamSizeQuestionsData:string="";
  postTeamSizeData:string="";
  getAllFormApprovalDetails:string="";
  postLocalityData:string="";
  postPrivacyConcernsData:string=""

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      currentLocation: {
        label: "",
        value: "",
        current_latitude: "",
        current_longitude: "",
      },
      answerOfQuestion:{
        business_name:"",
        web:""
    },
    stateFields:[""],
    yearFeildOpen:false,
    monthPlaceholder: "Month",
      destination: { destination_latitude: "", destination_longitude: "" },
      cutomerData: {},
      datePlaceholder:"Date",
      dateFeildOpen:false,
      customerID: "",
      isChecked:false,
      swtichButton:false,
      formDetails:null,
      beautyServicesQuestionsList:[],
      selectedBeautyServices:[],
      teamSizesQuestionsList:[],
      selectedTeamSize: null,
      nameError: false,  
      invalidBusinessName: false,  
      websiteError: false,
      isSubmitted: false,
      showErrorModal: false,
      businessTypeError:false,
      teamSizeError:false,
      selectedLocality:null,
      localityError:false,
      privacyConcerns:false,
      breakCount:0,
      yearPlaceholder: "Year",
      licenseDate:"Date",
      hourPopUp:false,
      stateList:["Puerto Rico, US","Illinois, US","New York, US"],
      copyPopUp:false,
      weekDay:[
        {
          value:"Sunday",
          clicked:false,
        },
        {
          value:"Monday",
          clicked:true,
        },
        {
          value:"Tuesday",
          clicked:false,
        },
        {
          value:"Wednesday",
          clicked:false,
        },
        {
          value:"Thursday",
          clicked:false,
        },
        {
          value:"Friday",
          clicked:false,
        },
        {
          value:"Saturday",
          clicked:false,
        },
      ],
      timeList:[
        '9:45 AM',
        '10:00 AM',
        '10:15 AM',
        '10:30 AM',
        '10:45 AM',
        '11:00 AM',
        '11:15 AM',
        '11:30 AM',
      ],
      monthFeildOpen:false,
      openingHr:"10:00 AM",
      breakList:[],
      workingDay:{
        day: "",
        work_start_time: "",
        work_end_time: "",
        business_hour_breaks: [
        {
        break_start_time: "",
        break_end_time: ""
    }]
      },
      businessHourResult:{
        last_step: "business_hours",
        business_hours: []
        },
      SurveyQuestion: localStorage.getItem("surveyQuestion") ? localStorage.getItem("surveyQuestion") : "Question1"
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.getCurrentLocation();
    this.getTeamSizeQuestions()
    this.getBeautySerivesQuestions()
    this.getFormApprovalAllDetails()
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived in");

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getBeautyServicesQuestionsApiData) {
        this.setState({ beautyServicesQuestionsList: responseJson.data });
      }
      if (apiRequestCallId === this.getTeamSizeQuestionsData) {
        this.setState({ teamSizesQuestionsList: responseJson.data });
      }
      
      if(apiRequestCallId===this.getAllFormApprovalDetails){
        this.handleAllFormApprovalDetails(responseJson)
      }
      if (apiRequestCallId === this.postBusinessNameData) {
        if (!responseJson || responseJson.errors) {
          this.setState({ showErrorModal: true });
      } else {
          this.nextQuestion("business_type");
      }
      }

      if(apiRequestCallId===this.postBeautyServicesData){
        if (!responseJson || responseJson.errors) {
          this.setState({ showErrorModal: true });
        } else {
            this.nextQuestion("team_size");
        }
      }
      
      if(apiRequestCallId===this.postTeamSizeData){
        if (!responseJson || responseJson.errors) {
          this.setState({ showErrorModal: true });
        } else {
          this.nextQuestion("locality");
        }
      }

      if(apiRequestCallId===this.postLocalityData){
        if(!responseJson || responseJson.errors){
          this.setState({showErrorModal:true})
        }else{
          this.nextQuestion("business_address")
        }
      }

      if(apiRequestCallId===this.postPrivacyConcernsData){
        if(!responseJson || responseJson.errors){
          this.setState({showErrorModal:true})
        }else{
          this.nextQuestion("business_hours")
        }
      }

      /*istanbul ignore next*/
      if (apiRequestCallId === this.getApicallData) {
        this.handleSetCustomerDetails(responseJson.data.attributes);
      }
    }
  }


  apiCall = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body,headers } = data;

    const requestMessageCatalogueColorCollection = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    const headerCatalogueColorCollection = {
      "Content-Type": contentType,
      ...headers,
    };
    
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headerCatalogueColorCollection)
    );
    requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && requestMessageCatalogueColorCollection.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    runEngine.sendMessage(requestMessageCatalogueColorCollection.id, requestMessageCatalogueColorCollection);
    return requestMessageCatalogueColorCollection.messageId;
  };

  handleAllFormApprovalDetails=(responseJson: AllFormApprovalDetailsTypes)=>{
    if (responseJson.data) {
      const attributes = responseJson.data.attributes;

      this.setState({
       formDetails: {
          data: {
            id: responseJson.data.id, 
            type: responseJson.data.type, 
            attributes: attributes,
          }
        },
        answerOfQuestion: {
          business_name: attributes.business_name,
          web: attributes.website,
        },
        selectedBeautyServices: attributes.beauty_services.map(
          (service: BeautyServices) =>service.id
        ),
        selectedTeamSize: attributes.team_size?.id || null,
        selectedLocality:attributes.locality,
        privacyConcerns:attributes.privacy_concern?.[0]?.activated || false
      });
    }
  }

  // web events

  getCurrentLocation = (getNavigator?: {
    geolocation: { getCurrentPosition: Function };
  }) => {
    const locationNavigator = getNavigator || navigator;
    locationNavigator?.geolocation?.getCurrentPosition(
      (position: { coords: {} }) => {
        this.setCurrentLocation(position.coords);
      }
    );
  };

  handleGetDestinationList = (
    data: { [k: string]: string },
    mockGeocodeByAddress?: Function
  ) => {
    const getAddress = mockGeocodeByAddress || geocodeByAddress;
    getAddress(data.label)
      .then((results: google.maps.GeocoderResult[]) => getLatLng(results[0]))
      .then(({ lat, lng }: { lat: number; lng: number }) =>
        this.handleSelectDestination({
          destination_latitude: String(lat),
          destination_longitude: String(lng),
        })
      );
  };

  setCurrentLocation = (data: { [keyVal: string]: string | number }) => {
    this.setState({
      currentLocation: {
        label: "Current Location",
        value: "Curren Location",
        current_latitude: String(data.latitude),
        current_longitude: String(data.longitude),
      },
    });
  };

  handleSelectDestination = (
    element: {
      destination_latitude: string;
      destination_longitude: string;
    } | null
  ) => {
    this.setState(
      {
        destination: element,
      },
      () => {
        this.handleGetData(element);
      }
    );
  };


  handleGetData = (
    data: { destination_latitude: string; destination_longitude: string } | null
  ) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token:
        "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6MTcxOCwiZXhwIjoxNjgyMTY0NzgyLCJ0b2tlbl90eXBlIjoibG9naW4ifQ.N0vknQq98iNbzOWN5RhEr0cBOEVIedCvBorY5ui0BLdjIFf6tTYNecWRuxyN7xypY6a2c0mm9prwNPbzIKen9g",
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getApicallData = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.searchAPI}?current_location_latitude=${this.state.currentLocation.current_latitude}&current_location_longitude=${this.state.currentLocation.current_longitude}&destination_latitude=${data?.destination_latitude}&destination_longitude=${data?.destination_longitude}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  handleSetCustomerDetails = (data: { [keyVal: string]: string | number }) => {
    this.setState({
      cutomerData: data,
    });
    if (window.location?.replace) {
      window.location.replace(
        `/GeoLocation?pickup_latitude=${data.pickup_latitude}&pickup_longitude=${data.pickup_longitude}&destination_latitude=${data.destination_latitude}&destination_longitude=${data.destination_longitude}`
      );
    }
  };
  switchToggle = () => {
    this.setState((prevState) => ({
      privacyConcerns: !prevState.privacyConcerns,
    }));
  };
  addBreak=()=>{
    this.setState({breakCount:this.state.breakCount+1})
    const breakData={
      opening:"",
      closing:"",
      key:this.state.breakCount
    }
    const data=[...this.state.breakList,breakData]
    this.setState({breakList:data})
  }

  handleBusinessNameApiCall = () => {
    const { business_name, web } = this.state.answerOfQuestion
    if (business_name && web) {
      const requestData = {
        last_step: "business_name",
        business_name: business_name,
        website: web,
      };
      this.postBusinessNameApi(requestData);
    }
  };

  handleBeautyServicesApiCall = () => {
    const selectedServices = this.state.selectedBeautyServices;

    if (selectedServices.length > 0) {
      const requestData: BeautyTypeApi = {
        last_step: "business_type",
        beauty_services: selectedServices,
      };
      this.postBeautyServicesApi(requestData);
    }
  };

  handleTeamSizeApiCall = () => {
    const selectedTeamSize = this.state.selectedTeamSize;

    if (selectedTeamSize!== null) {
      const requestData: TeamSizeApi = {
        last_step: "team_size",
        team_size_id: selectedTeamSize,
      };
      this.postTeamSizeApi(requestData);
    }
  };

  handleLocalityApiCall=()=>{
     const selectedLocality =this.state.selectedLocality
     if(selectedLocality){
       const requestData:LocalityApi={
             last_step: "locality",
             locality: selectedLocality
           }
           this.postLocalityApi(requestData)
     }
  }

  handlePrivacyConcernsApiCall=()=>{
    const { privacyConcerns } = this.state;
     const requestData:PrivacyConcernsApi={
          last_step: "privacy_concerns",
          private_account: privacyConcerns
     }
     this.postPrivacyConcernsApi(requestData)
  }

  postBusinessNameApi = async (data:BusinessNamePatchApi) => {
    const token = localStorage.getItem("userRole");
  const formApprovalId = localStorage.getItem("formApprovalId");

  const headers = {
    token: token || "",
  };

  this.postBusinessNameData = await this.apiCall({
    contentType: configJSON.validationApiContentType,
    method: "PATCH",
    endPoint: `${configJSON.businessNameApi}/${formApprovalId}`,
    body: data,
    headers,
  });
  };

  postBeautyServicesApi = async (data: BeautyTypeApi) => {
    const token = localStorage.getItem("userRole");
    const formApprovalId = localStorage.getItem("formApprovalId");
  
    const headers = {
      token: token || "",
    };
  
    this.postBeautyServicesData = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: "PATCH",
      endPoint: `${configJSON.businessNameApi}/${formApprovalId}`,
      body: data,
      headers,
    });
  };

  postTeamSizeApi = async (data: TeamSizeApi) => {
    const token = localStorage.getItem("userRole");
    const formApprovalId = localStorage.getItem("formApprovalId");
  
    const headers = {
      token: token || "",
    };
  
    this.postTeamSizeData = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: "PATCH",
      endPoint: `${configJSON.businessNameApi}/${formApprovalId}`,
      body: data,
      headers,
    });
  };

  postLocalityApi = async (data: LocalityApi) => {
    const token = localStorage.getItem("userRole");
    const formApprovalId = localStorage.getItem("formApprovalId");
  
    const headers = {
      token: token || "",
    };
  
    this.postLocalityData = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: "PATCH",
      endPoint: `${configJSON.businessNameApi}/${formApprovalId}`,
      body: data,
      headers,
    });
  };

  postPrivacyConcernsApi= async (data: PrivacyConcernsApi) => {
    const token = localStorage.getItem("userRole");
    const formApprovalId = localStorage.getItem("formApprovalId");
  
    const headers = {
      token: token || "",
    };
  
    this.postPrivacyConcernsData = await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: "PATCH",
      endPoint: `${configJSON.businessNameApi}/${formApprovalId}`,
      body: data,
      headers,
    });
  };

  

  inputvalue = (value: string, key: string) => {
    const businessNameRegex = /^[a-zA-Z0-9 ]+$/;
    const websiteRegex = /^(https?:\/\/)?(www\.)?[a-z0-9]+\.[a-z]{2,3}(\/\S*)?$/; 
  
    this.setState((prevState) => {
      let updatedAnswerOfQuestion = { ...prevState.answerOfQuestion, [key]: value };
  
      let nameError = prevState.nameError;
      let invalidBusinessName = prevState.invalidBusinessName;
      let websiteError = prevState.websiteError;
  
      if (key === "business_name") {
        if (value.trim() === "") {
          nameError = false; 
          invalidBusinessName = false;
        } else if (businessNameRegex.test(value)) {
          nameError = false; 
          invalidBusinessName = false; 
        }
      }
  
      if (key === "web") {
        if (value.trim() === "" || websiteRegex.test(value)) {
          websiteError = false; 
        }
      }
  
      return {
        answerOfQuestion: updatedAnswerOfQuestion,
        nameError,
        invalidBusinessName,
        websiteError,
      };
    });
  };
  
  nextQuestion = (value:string) => {
    this.setState({SurveyQuestion:value})
    localStorage.setItem("surveyQuestion",value)
  }
  handleBusinessNameContinueClick = () => {
    const { business_name, web } = this.state.answerOfQuestion;
  
    const businessNameRegex = /^[a-zA-Z0-9 ]+$/; 
    const websiteRegex = /^(https?:\/\/)?(www\.)?[a-z0-9]+\.[a-z]{2,3}(\/\S*)?$/; 
  
    let nameError = false;
    let invalidBusinessName = false;
    let websiteError = false;
  
    if (!business_name || business_name.trim() === "") {
      nameError = true;
    } else if (!businessNameRegex.test(business_name)) {
      invalidBusinessName = true;
    }
      if (!web || web.trim() === "") {
      websiteError = true;
    } else if (!websiteRegex.test(web)) {
      websiteError = true;
    }
  
    this.setState({ nameError, invalidBusinessName, websiteError, isSubmitted: true }, () => {
      if (!nameError && !invalidBusinessName && !websiteError) {
        this.handleBusinessNameApiCall();
      }
    });
  };

  handleBusinessTypeContinueClick=()=>{
    if (this.state.selectedBeautyServices.length === 0) {
      this.setState({ businessTypeError: true }); 
    } else {
      this.handleBeautyServicesApiCall();
    }
  }

  handleTeamSizeContinueClick=()=>{
    if (this.state.selectedTeamSize === null) {
      this.setState({ teamSizeError: true }); 
    } else {
      this.handleTeamSizeApiCall();
    }
  }

  handleLocalityContinueClick=()=>{
      if(!this.state.selectedLocality){
         this.setState({localityError:true})
      }else{
        this.handleLocalityApiCall()
      }
  }

  handlePrivacyConcernsContinueClick=()=>{
     this.handlePrivacyConcernsApiCall()
  }
  
  getBeautySerivesQuestions=async()=>{
    this.getBeautyServicesQuestionsApiData=await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: "GET",
      endPoint: configJSON.beautyServiceQuestions,
    })
  }

  getTeamSizeQuestions=async()=>{
    this.getTeamSizeQuestionsData=await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: "GET",
      endPoint: configJSON.teamSizeQuestions,
    })
  }

  getFormApprovalAllDetails=async()=>{
    const token = localStorage.getItem("userRole");
    const formApprovalId = localStorage.getItem("formApprovalId");
  
    const headers = {
      token: token || "",
    };

    this.getAllFormApprovalDetails=await this.apiCall({
      contentType: configJSON.validationApiContentType,
      method: "GET",
      endPoint: `${configJSON.businessNameApi}/${formApprovalId}`,
      headers,
    })

  }
  deleteList = (key:number)=>{
    this.setState({breakList:this.state.breakList.filter((item)=> {return item.key!==key}
    )})
  }
  handleDateIconClick=()=>{
    this.setState({dateFeildOpen:!this.state.dateFeildOpen})
  }
  handleDateFieldOpen = () => {
    this.setState({ dateFeildOpen: true });
  };
  handleDateFieldClose = () => {
    this.setState({ dateFeildOpen: false });
  };
  openHrPopUp = ()=>{
    this.setState({hourPopUp:!this.state.hourPopUp})
  }
  openCopyPopUp = ()=>{
    this.setState({copyPopUp:!this.state.copyPopUp,hourPopUp:false})
  }
  convertTimeToUTC(time:string) {
    const currentDate = new Date();
    const dateTimeString = `${currentDate.toISOString().split('T')[0]} ${time}`;
    const date = new Date(dateTimeString);
    const utcDate = date.toISOString().replace("T", " ").split(".")[0] + " UTC";
    return utcDate;
  }
  handleDaySwitch=(value:string)=>{
    
    this.setState((prevState) => ({
      weekDay: prevState.weekDay.map((item) => {
        if (item.value === value) {
          return {
            ...item,  
            clicked: !item.clicked,  
          };
        }
        return item; 
      }),
    }));
    let mockData={
      day: value,
      work_start_time: this.convertTimeToUTC("10:00 AM").toString(),
      work_end_time:  this.convertTimeToUTC("7:00 PM").toString(),
      business_hour_breaks: []
    }
    this.setState((prevState) => {
      const updatedBusinessHours = prevState.businessHourResult.business_hours.filter(
        (item) => item.day !== value
      );
      if (updatedBusinessHours.length === prevState.businessHourResult.business_hours.length) {
        updatedBusinessHours.push(mockData);
      }
      return {
        businessHourResult: {
          ...prevState.businessHourResult,
          business_hours: updatedBusinessHours
        }
      };
    });
  }
  addField = () => {
    this.setState((prevState) => ({
      stateFields: [...prevState.stateFields, ""], // Add an empty string for the new field
    }));
  };
  handleFieldChange = (index:number, event:any) => {
    const fields = [...this.state.stateFields];
    fields[index] = event.target.value;
    this.setState({ stateFields:fields });
  };
  handleMonthIconClick = () => {
    this.setState({
      monthFeildOpen: !this.state.monthFeildOpen})
  };
  handleMonthFieldOpen = () => {
    this.setState({ monthFeildOpen: true });
  };
  handleMonthFieldClose = () => {
    this.setState({ monthFeildOpen: false });
  };
  handleYearFieldOpen = () => {
    this.setState({ yearFeildOpen: true });
  };
  handleYearFieldClose = () => {
    this.setState({ yearFeildOpen: false });
  };
  handleYearIconClick = () => {
    this.setState({yearFeildOpen:!this.state.yearFeildOpen})
  };
  
}


// Customizable Area End
