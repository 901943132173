import React from "react";
// Customizable Area Start
import {
    Box,
    Button,
    Typography,
    Grid,
    styled,
    Hidden,
    TextField
} from "@material-ui/core";
import { quilaImage, blackArrow, email, forgotLockIcon, quilaLogo, backBtnImg, forgotMobileIcon, checkMaiImg } from "./assets";
// Customizable Area End
import ForgotPasswordController, {
    Props, configJSON
} from "./ForgotPasswordController";

export default class ForgetPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderForget = () => {
        return (
            <>
                 <style>
                {`
                    .css-view-1dbjc4n {
                    overflow-x: hidden; 
                    }
                `}
              </style>
            <Grid >
                <BoxContainer>
                  <Box style={forgotPasswordStyle.backBtnContainer}>
                <BackBtnImg src={backBtnImg} data-test-id="loginBack" onClick={this.handleLoginBackBtn}/> <BackText><Typography className="back_text">{configJSON.backText}</Typography></BackText>
                  </Box>
                  <QuilaLogo />
                </BoxContainer>
                {this.state.checkEmail?this.renderCheckYourEmail():
                <MainContainer>
                    <ForgetContainer>
                        <KeyContainer>
                            <KeyImage src={forgotLockIcon} alt="Key Image"
                            />
                        </KeyContainer>
                        <ForgetText>
                            <Typography className="forgot_text" data-test-id="forgotpasswordText">{configJSON.forgetPassword}</Typography>
                        </ForgetText>
                        <SendText data-test-id="emailSend">
                            {configJSON.emailSend}
                        </SendText>
                        <TextForm noValidate onSubmit={(event)=>this.handleButtonClickEmail(event)} data-test-id="submitTestID">
                            <Typography className="email_lable">{configJSON.emailText}</Typography>
                            {this.renderTextInput()}
                            <SubmitButton
                                type="submit"
                                fullWidth
                                variant="contained"
                            >
                                {configJSON.managerPasswordMsg}
                            </SubmitButton>
                        </TextForm>
                        <Hidden>
                            <BackButtonContainer data-test-id ="BacktoLogintestId"container onClick={this.openEmailLoginPage}>
                                <BackText><Typography className="backto_login_text">{configJSON.backToLogin}</Typography></BackText>
                            </BackButtonContainer>
                        </Hidden>
                    </ForgetContainer>
                </MainContainer>}
                

            </Grid>
            </>
        )
    };

   renderCheckYourEmail=()=>{
    return(
        <MainContainer>
        <ForgetContainer>
            <KeyContainer>
                <KeyImage src={checkMaiImg} alt="Key Image"
                />
            </KeyContainer>
            <ForgetText>
                <Typography className="check_email_text" data-test-id="checkMail">{configJSON.checkEmail}</Typography>
            </ForgetText>
            <SendText>
                <Typography className="check_email">{configJSON.checkEmailDesc}</Typography>
                <Typography className="email_text">{this.state.inputValue}</Typography>
            </SendText>
            <TextForm noValidate onClick={this.handleLoginBackBtn} data-test-id="handleloginback"  className="text_form">
                <SubmitButton
                    type="submit"
                    fullWidth
                    variant="contained"
                >
                    {configJSON.gobacktologin}
                </SubmitButton>
            </TextForm>
            <Hidden>
                <BackButtonContainer data-test-id ="BacktoLogintestId"container >
                    <BackText><Typography className="dont_recive_text">{configJSON.dontRecieveEmail}</Typography><Typography className="resend_text" data-test-id="resendBtn" onClick={this.handleResendBtn}>{configJSON.resendText}</Typography></BackText>
                </BackButtonContainer>
            </Hidden>
        </ForgetContainer>
    </MainContainer>
    )
   }

    renderTextInput = () => {
        return (
            <>
        <Box>
            {this.state.forgetPassErgMsg && (
                <Box sx={{...webStyle.customTooltip,...webStyle.customTooltipWidth}}
                >
                 <Typography style={webStyle.validationText}>{this.state.forgetPassErgMsg}</Typography>
                    <Box sx={webStyle.positionContainer}
                    />
                </Box>
                )}
            </Box>
            
      <Box>
        {this.state.emailFormatOne && (
          <Box sx={{...webStyle.customTooltip,...webStyle.customTooltipWidth}}
          >
            <Typography style={webStyle.validationText}>{configJSON.emailErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </Box>
      <Box style={webStyle.emailContainer}>
            <EmailTextInput
                data-test-id="emailInputID"
                error={Boolean(this.state.emailFormatOne || this.state.forgetPassErgMsg)}
                label={this.state.inputValue ? "" : "Enter email"}
                variant="outlined"
                className="input_field_config"
                value={this.state.inputValue}
                fullWidth
                onChange={(e) => this.setEmail(e.target.value)}
                InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    style: {
                        color: this.state.emailFormatOne || this.state.forgetPassErgMsg ? "#E53881" : "#757676",
                        opacity: 1
                    }
                }}
                InputProps={{
                    style: {
                        color: this.state.emailFormatOne || this.state.forgetPassErgMsg ? "#E53881" : "#757676",
                        borderRadius: "8px"
                    }
                }}
                inputProps={{
                    style: {
                        border: this.state.emailFormatOne || this.state.forgetPassErgMsg ? "1px solid #E53881" : "",
                        borderRadius: "8px"
                    }
                }}
            />
            </Box>
      </>
        )
    }

    renderOpenModal = () => {
        return (
            <Grid>
                <Button onClick={this.handleCloseModal}>
                    <BackIcon src={blackArrow} />
                </Button>
                <QualImage src={quilaImage} alt="quila" />
                <MainContainer>
                    <EmailContainer>
                        <KeyContainer>
                            <KeyImage src={email} alt="Key Image"
                            />
                        </KeyContainer>
                        <ForgetText>
                            {configJSON.checkEmail}
                        </ForgetText>
                        <SendText >
                            {configJSON.resetNewLink}
                        </SendText>
                        <EmailType>{this.state.inputValue}</EmailType>
                        <a href={"https://mail.google.com/mail/u/0/?tab=rm&ogbl#inbox"} target="_blank" rel="noopener noreferrer">
                        <EmailButton
                            type="submit"
                            fullWidth
                            variant="contained"
                        >
                            {configJSON.openEmailApp}
                        </EmailButton>
                        </a>
                        <ResendContainer container spacing={2} >
                            <EmailText>{configJSON.dontRecieveEmail}</EmailText>
                            <ResendText onClick={(event)=>this.handleButtonClick(event)} data-test-id="submitTestIDResednBtn">{configJSON.clickToResend}</ResendText>
                        </ResendContainer>
                    </EmailContainer>
                </MainContainer>
            </Grid>
        )
    }


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Box>
                {this.renderForget()}
            </Box>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const webStyle={
    customTooltip: {
        marginBottom: '-30px',
        backgroundColor: '#E53881',
        color: 'white',
        padding: '10px 15px',
        position: 'absolute',
        zIndex:99,
        borderRadius:"6px",
      },
      customTooltipWidth:{
      },
      positionContainer: {
         position: 'absolute',
         top: '100%',
         left: '20%',
         transform: 'translateX(-50%)',
         borderTop: '10px solid #E53881',
         borderLeft: '10px solid transparent',
         borderRight: '10px solid transparent',          
      },
      validationText:{
        fontSize:"12px",
        fontWeight:400,
        fontFamily:"Inter",
        lineHeight:"13px"
      },
      emailContainer:
        {position:'relative' as "relative",marginBottom:"-8px"}
}

const forgotPasswordStyle={
    topContainer:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        padding:"20px 25px 20px 25px",
    },
    backBtnContainer:{
        display:"flex",
        alignItems:'center',

        gap:"8px",
    },
}
const MainContainer = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    "& .input_field_config":{
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
        },
    }
})

const ForgetContainer = styled(Grid)({
    padding: "16px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent:'center',
    marginTop: '25px',
    "@media (max-width: 599px)": {
        padding: '20px 20px',
         marginTop:"-35px"
    },
})

const BoxContainer=styled(Box)({
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        padding:"20px 25px 20px 25px",
        "@media (max-width:600px)":{
            padding:"20px 18px",
        }
})

const TextForm = styled("form")({
    width: '390px',
    
    
    "& .email_lable":{
        color:"#334155",
        fontSize:"12px",
        fontWeight:600,
        fontFamily:"Degular",
        marginBottom:"-20px",
        marginTop:"20px",
    },
    "@media (max-width: 599px)": {
        width: '100%', 
    },
    "& .text_form":{
        marginTop:"-25px",
        width:'100%'
      },

})

const SubmitButton = styled(Button)({
    margin: '18px 0',
    backgroundColor: '#6551EA',
    borderRadius:"6px",
    color: 'white',
    textTransform: "none",
    height: '44px',
    fontWeight: 600,
    fontSize:"18px",
    fontFamily:"Degular",
    width:'100%',
    top: '18px',
    "&:hover": {
        backgroundColor: '#6551EA'
    },
    "@media (max-width: 599px)": {
        margin: '12px 0',
        width: '100%' 
    }
})

const BackButtonContainer = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '20px'
})


const BackText = styled(Typography)({
    display:'flex',
    alignItems:"center",
    justifyContent:'center',
    "& .backto_login_text":{
        color: "#757676",
        fontWeight: 600,
        fontSize: '14px',
        marginTop:"-8px",
        fontFamily:"Inter",
        lineHeight:"24px",
        cursor:"pointer"
    },
    "& .back_text":{
 display:"block",
        "@media (max-width:598px)":{
            display:"none"
        },
        fontSize:"16px",
        fontWeight:400,
        fontFamily:"Inter",
        lineHeight:"24px",
        color:"#757676",
    },
    "& .dont_recive_text":{
        fontSize:"14px",
        fontWeight:400,
        fontFamily:"Inter",
        lineHeight:"24px",
        color:'#757676'
    },
    "& .resend_text":{
        fontSize:"16px",
        fontWeight:600,
        fontFamily:"Inter",
        lineHeight:"24px",
        color:'#6551EA',
        marginLeft:"8px",
        cursor:"pointer"
    }
})

const SendText = styled(Typography)({
    fontSize:"16px",
    fontWeight:500,
    fontFamily:"Degular Display",
    lineHeight:"22.4px",
    marginTop:"10px",
    color:"#757676",
    textAlign:'center',
   "& .check_email":{
      fontSize:"16px",
      fontWeight:400,
      fontFamily:"Degular",
      lineHeight:"24px",
      color:"#757676"
   },
   "& .email_text":{
    fontSize:"16px",
    fontWeight:600,
    fontFamily:"Degular",
    lineHeight:"24px",
    color:"#757676",
    textAlign:"center"
   }

})

const KeyImage = styled('img')({
    width:'61px',
    height:"61px"
})

const BackBtnImg=styled("img")({
     cursor:"pointer"
})

const QuilaLogo = styled("img")({
    width: "63px",
    height: "33px",
    objectFit: "contain",
    content: `url(${quilaLogo})`,
    "@media (max-width: 599px)": {
        content: `url(${forgotMobileIcon})`,
        width:"40px",
        height:"39px"
    },
});

const BackIcon = styled("img")({
    display: "none",
    "@media (max-width: 599px)": {
        padding: "55px 10% 0",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
    },
    "@media (max-width: 435px)": {
        padding: "10px 5% 0",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        marginTop: "20px"
    }
})

const KeyContainer = styled(Grid)({
    width: "56px",
    height: '56px',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '12px',
    alignItems: 'center'
})

const ForgetText = styled(Typography)({
    "& .forgot_text":{
       fontFamily:"Degular Display",
       fontWeight:600,
       fontSize:"28px",
       lineHeight:"39.2px",
       color:'#6551EA',
       marginTop:"15px"
    },
    "& .check_email_text":{
        fontFamily:"Degular Display",
        fontWeight:600,
        fontSize:"28px",
        lineHeight:"32px",
        color:'#000000',
        marginTop:"35px",
    }
})

const QualImage = styled("img")({
    margin: '4px',
    objectFit: 'cover',
    display: 'flex',
    "@media (max-width: 599px)": {
        display: 'none',
    },

})

const EmailTextInput = styled(TextField)({
    marginTop:'25px',
})

const EmailButton = styled(Button)({
    margin: '18px 0',
    backgroundColor: '#101828',
    color: 'white',
    textTransform: "none",
    height: '44px',
    fontWeight: 600,
    top: '16px',
    marginTop: "8px",
    width: '358px',
    "&:hover": {
        backgroundColor: '#101828'
    }
})

const EmailText = styled(Typography)({
    color: '#475467',
    fontSize: '14px',
    fontWeight: 400
})

const ResendText = styled(Typography)({
    color: '#6551EA',
    fontSize: '14px',
    fontWeight: 600,
    marginLeft: '6px',
    cursor:"pointer"
})

const EmailContainer = styled(Grid)({
    padding: "16px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '358px',
    "@media (max-width: 599px)": {
        marginTop: '-320px'
    },
})

const ResendContainer = styled(Grid)({
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '40px',
    width: '358px'
})

const EmailType = styled(Typography)({
    color: '#757676',
    fontWeight: 600
})




// Customizable Area End
