import React from "react";
// Customizable Area Start
import {
    Grid,
    Button,
    Typography,
    styled,
    TextField,
    Box,
    Hidden
} from "@material-ui/core";
import { quilaImage, blackArrow,checkCircle, forgotLockIcon, quilaLogo, backBtnImg, forgotMobileIcon } from "./assets";
// Customizable Area End
import ForgotPasswordController, {
    Props,
    configJSON,
} from "./ForgotPasswordController";

export default class NewPassword extends ForgotPasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderResetPassword = () => {
        return (
            <>
            <style>
           {`
               .css-view-1dbjc4n {
               overflow-x: hidden; 
               }
           `}
         </style>
       <Grid >
           <BoxContainer  >
             <Box style={forgotPasswordStyle.backBtnContainer}>
           <BackBtnImg src={backBtnImg} data-test-id="newPassword" onClick={this.handleNewPasswordBackBtn}/> <BackText><Typography className="back_text">{configJSON.backText}</Typography></BackText>
             </Box>
             <QuilaLogo />
           </BoxContainer>
           <MainContainer>
               <ForgetContainer>
                   <KeyContainer>
                       <KeyImage src={forgotLockIcon} alt="Key Image"
                       />
                   </KeyContainer>
                   <ForgetText>
                       <Typography className="forgot_text">{configJSON.createNew}</Typography>
                   </ForgetText>
                   <SendText>
                       {configJSON.passdesc}
                   </SendText>
                   <TextForm noValidate data-test-id="submitTestID" onSubmit={this.handleButtonClick}>
                       <Typography className="email_lable">{configJSON.passLable}</Typography>
                       {this.renderTextInput()}
                       <SubmitButton
                           type="submit"
                           fullWidth
                           variant="contained"
                       >
                           {configJSON.managerPasswordMsg}
                       </SubmitButton>
                   </TextForm>
                   <Hidden>
                       <BackButtonContainer data-test-id ="BacktoLogintestId"container onClick={this.openEmailLoginPage}>
                           <BackText><Typography className="backto_login_text">{configJSON.backToLogin}</Typography></BackText>
                       </BackButtonContainer>
                   </Hidden>
               </ForgetContainer>
           </MainContainer>
       </Grid>
       </>
        )
    }

    renderTextInput = () => {
        return (
            <>
      <PasswordBoxContainer>
        {this.state.createPassError&& (
          <Box className="password_error_width" sx={{...webStyle.customTooltip,...webStyle.customTooltipWidth}}
          >
            <Typography style={webStyle.validationText}>{configJSON.passErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </PasswordBoxContainer>
      <Box style={{position:'relative'}}>
            <TextField
                data-test-id="passwordInputID"
                type="password"
                error={this.state.createPassError}
                label={this.state.inputFirst ? "" : configJSON.passPlaceHolder}
                variant="outlined"
                className="input_field_config"
                value={this.state.inputFirst}
                fullWidth
                onChange={(e) => this.setPassword(e.target.value)}
                InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    style: {
                        color: this.state.createPassError ? "#E53881" : "#757676",
                        opacity: 1
                    }
                }}
                InputProps={{
                    style: {
                        color: this.state.createPassError ? "#E53881" : "#757676",
                        borderRadius: "8px"
                    }
                }}
                inputProps={{
                    style: {
                        border: this.state.createPassError ? "1px solid #E53881" : "",
                        borderRadius: "8px"
                    }
                }}
            />
            </Box>
      </>
        )
    }

    renderNewPassword = () => {
        return (
            <EmailTextInput 
            data-test-id="passwordInputID"
                type="password"
                error={this.state.numberOne || this.state.specCharOne}
                required={this.state.numberOne || this.state.specCharOne ? true : false}
                label={this.state.inputFirst ? "" : "Create new password"}
                variant="outlined"
                value={this.state.inputFirst}
                fullWidth
                onChange={(e) => this.setPassword(e.target.value)}
                InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    style: {
                        color: this.state.numberOne || this.state.specCharOne ? "red" : "#757676",
                        opacity: 1
                    }
                }}
                InputProps={{
                    style: {
                        color: this.state.numberOne || this.state.specCharOne ? "red" : "#757676",
                        border: this.state.numberOne || this.state.specCharOne ? "1px solid red" : "",
                        backgroundColor: this.state.numberOne || this.state.specCharOne ? "rgba(229, 57, 53, 0.15)" : "#fff",
                        borderRadius: "7px"
                    }
                }}
            />
        )
    }

    renderTextinput = () => {
        return (
            <EmailTextInput data-test-id="passwordID"
                type="password"
                error={this.state.numberTwo || this.state.specCharTwo}
                required={this.state.numberTwo || this.state.specCharTwo ? true : false}
                label={this.state.inputSecond ? "" : "Confirm new password"}
                variant="outlined"
                value={this.state.inputSecond}
                fullWidth
                onChange={(e) => this.setConfirmPassword(e.target.value)}
                InputLabelProps={{
                    disableAnimation: true,
                    shrink: false,
                    style: {
                        color: this.state.numberTwo || this.state.specCharTwo ? "red" : "#757676",
                        opacity: 1
                    }
                }}
                InputProps={{
                    style: {
                        color: this.state.numberTwo || this.state.specCharTwo ? "red" : "#757676",
                        border: this.state.numberTwo || this.state.specCharTwo ? "1px solid red" : "",
                        backgroundColor: this.state.numberTwo || this.state.specCharTwo ? "rgba(229, 57, 53, 0.15)" : "#fff",
                        borderRadius: "7px"
                    }
                }}
            />
        )
    }

    renderOpenModal = () => {
        return (
            <Grid>
             <Button onClick={this.handleCloseModal}>
                <BackIcon src={blackArrow} />
                </Button>
                <QualImage src={quilaImage} alt="quila" />
                <MainContainer>
                    <EmailContainer>
                        <KeyContainer>
                            <KeyImage src={checkCircle} alt="Key Image"
                            />
                        </KeyContainer>
                        <ForgetText>Password reset</ForgetText>
                        <GreyText>
                        <SendText>
                            {configJSON.successReset}
                        </SendText>
                        <SendText>{configJSON.clicktoLogin}</SendText>
                        </GreyText>
                        <EmailButton data-test-id="NavigateLogin" onClick={this.openEmailLoginPage}
                            type="submit"
                            fullWidth
                            variant="contained">
                            {configJSON.continue}
                        </EmailButton>
                    </EmailContainer>
                </MainContainer>
            </Grid>
        )
    }
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <>
                {this.renderResetPassword()}
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const MainContainer = styled(Grid)({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',
    marginTop:"20px",
    "& .input_field_config":{
        '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
            border: 'none', 
        },
    }
})

const ForgetContainer = styled(Grid)({
    padding: "16px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '22px',
    "@media (max-width: 599px)": {
        marginTop: '-50px'
    },
})

const TextForm = styled("form")({
    width: '370',
    "& .email_lable":{
        color:"#334155",
        fontSize:"12px",
        fontWeight:600,
        fontFamily:"Degular",
        marginBottom:"5px",
        marginTop:"15px",
    },
    "@media (max-width: 599px)": {
        width: '100%', 
    }
})

const SubmitButton = styled(Button)({
    margin: '18px 0',
    backgroundColor: '#6551EA',
    borderRadius:"6px",
    color: 'white',
    textTransform: "none",
    height: '44px',
    fontWeight: 700,
    fontSize:"16px",
    fontFamily:"Inter",
    width:'100%',
    top: '18px',
    "&:hover": {
        backgroundColor: '#6551EA'
    },
    "@media (max-width: 599px)": {
        margin: '12px 0',
        width: '100%' 
    }
})

const ForgetText = styled(Typography)({
    textAlign:"center",
    "& .forgot_text":{
        fontFamily:"Degular Display",
        fontWeight:600,
        fontSize:"28px",
        lineHeight:"39.2px",
        color:'#6551EA',
        marginTop:"30px"
     },
     "& .pass_text":{
        fontFamily:"Inter",
        fontWeight:400,
        fotnSize:"30px",
        lineHeight:"32px",
        color:'#6551EA',
        marginTop:"-10px"
     }
})

const SendText = styled(Typography)({
    textAlign: 'center',
    color: '#757676',
    fontSize: '16px',
    fontWeight: 500,
    fontFamily:"Degular Display",
    marginTop:"5px"
})

const KeyImage = styled('img')({
    width:"61px",
    height:"61px"
})



const KeyContainer = styled(Grid)({
    width: "56px",
    height: '56px',
    justifyContent: 'center',
    display: 'flex',
    borderRadius: '12px',
    alignItems: 'center'
})

const QualImage = styled("img")({
    margin: '4px',
    objectFit: 'cover',
    display: 'flex',
    "@media (max-width: 599px)": {
        display: 'none',
    },

})


const BackIcon = styled("img")({
    display: "none",
    "@media (max-width: 599px)": {
        padding: "55px 10% 0",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
    },
    "@media (max-width: 435px)": {
        padding: "10px 5% 0",
        justifyContent: "center",
        alignItems: "center",
        display: 'flex',
        marginTop: "20px"
    }
})

const EmailTextInput = styled(TextField)({
    borderRadius: "4px",
    border: "#757676",
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    marginTop: '25px',
    '&::placeholder': {
        color: 'blue'
    }

})

const EmailButton = styled(Button)({
    margin: '18px 0',
    backgroundColor: '#101828',
    color: 'white',
    textTransform: "none",
    height: '44px',
    fontWeight: 600,
    top: '16px',
    marginTop: "8px",
    width: '358px',
    "&:hover": {
        backgroundColor: '#101828'
    }
})

const GreyText = styled(Grid)({
    marginTop:'12px'
})


const EmailContainer = styled(Grid)({
    padding: "16px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '358px',
    "@media (max-width: 599px)": {
        marginTop: '-400px'
    },
})

const forgotPasswordStyle={
    topContainer:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        padding:"20px"
    },
    backBtnContainer:{
        display:"flex",
        alignItems:'center',
         
        gap:"8px",
    },
}

const BackText = styled(Typography)({
    "& .backto_login_text":{
        color: "#757676",
        fontWeight: 600,
        fontSize: '14px',
        marginTop:"-8px",
        fontFamily:"Inter",
        lineHeight:"24px",
    },
    "& .back_text":{
 display:"block",
        "@media (max-width:598px)":{
            display:"none"
        },
        fontSize:"16px",
        fontWeight:400,
        fontFamily:"Inter",
        lineHeight:"24px",
        color:"#757676",
    }
})
const QuilaLogo = styled("img")({
    width: "63px",
    height: "33px",
    objectFit: "contain",
    content: `url(${quilaLogo})`,
    "@media (max-width: 599px)": {
        content: `url(${forgotMobileIcon})`,
         width:"40px",
        height:"39px"
    },
});

const BoxContainer=styled(Box)({
    display:"flex",
    alignItems:"center",
    justifyContent:"space-between",
    padding:"20px 25px 20px 25px",
    "@media (max-width:600px)":{
        padding:"20px 18px",
    }
})
const BackBtnImg=styled("img")({
     cursor:"pointer"
}) 
const BackButtonContainer = styled(Grid)({
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'center',
    marginTop: '20px'
})
const webStyle={
    customTooltip: {
        marginTop: '-50px',
        width:"60%",
        backgroundColor: '#E53881',
        color: 'white',
        padding: '8px 12px',
        position: 'absolute',
        zIndex:99,
        borderRadius:"6px",
      },
      customTooltipWidth:{
      },
      positionContainer: {
         position: 'absolute',
         top: '100%',
         left: '20%',
         transform: 'translateX(-50%)',
         borderTop: '10px solid #E53881',
         borderLeft: '10px solid transparent',
         borderRight: '10px solid transparent',          
      },
      validationText:{
        fontSize:"10px",
        fontWeight:400,
        fontFamily:"Inter",
        lineHeight:"13px"
      }
}
const PasswordBoxContainer=styled(Box)({
    "& .password_error_width":{
      width:"20%",
      "@media (max-width:598px)":{
         width:"70%"
      },
      "@media (min-width:599px) and (max-width:1000px)":{
          width:"40%"
      },
      "@media (min-width:720px) and (max-width:997px)":{
        width:"25%"
    },
    "@media (min-width:414px) and (max-width:597px)":{
        width:"40%"
    },
    "@media (min-width:1454px)":{
        width:"20%"
    },
    }
})
// Customizable Area End
