Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.errorTitle = "Error";

exports.apiVerifyOtpContentType = "application/json";
exports.apiVerifyOtpEndPoint =
  "account/accounts/sms_confirmation?pin=";

exports.apiVerifyForgotPasswordOtpEndPoint =
  "forgot_password/otp_confirmation";

exports.apiVerifyOtpMethod = "POST";
exports.emailOtpEndPoint="account_block/accounts/send_otp"
exports.getOtpEndPoint="account_block/accounts/email_confirmations/show"

exports.errorOtpNotValid = "OTP is not valid.";

exports.btnTxtSubmitOtp = "Submit otp";

exports.placeHolderOtp = "OTP";

exports.labelInfo =
  "We Just sent a 4 digits OTP to phone. Enter your OTP code below.";

exports.submitButtonColor = "#6200EE";
exports.checkEmailText = "Check your email";
exports.checkPhoneText = "Check your phone";
exports.verificationText = "We sent a verification link to";
exports.emailText = "camilaycuesta@quila.com";
exports.verifyText = "Verify email";
exports.enterCodeBtnText = "Enter code manually";
exports.receiveEmailtext = "Didn’t receive the email?";
exports.receivePhonetext="Didn’t receive any code?";
exports.resendText = "Click to resend";
exports.emailVerifiedText  = "Email verified";
exports.resetPassText = "Your password has been successfully reset.";
exports.continueBtnText = "Continue";
exports.loginText = "Click below to log in.";
exports.get = "GET";
exports.otpEndPoint = "account/accounts/email_confirmation?otp=";
exports.resendOtpEndPoint = "account_block/accounts/send_otp";
exports.otpMsg = "Please enter OTP";
exports.otpErorrMSg = "Please enter the 4 digit otp";
exports.congratsMsg="Congratulations You’re All Set";
exports.verification="Choose verification method"
exports.smsHeading="Select where to receive your 4-digit code, by email or SMS, to confirm your identity and securely access your account."
exports.phoneSubHeading="You’re unable to access this page.Please return to the home screen."
exports.emailSubHeading="You’e account has been successfully verified.";
exports.emailSubHeading2="Enjoy our services onwards."
exports.receiveMail="Receive via Email";
exports.receiveSms="Receive via SMS";
exports.sentMail="We have sent a one-time code to the Email associated with your account"
exports.sentOnPhone="We have sent a one-time code to the Phone Number associated with your account";
exports.resendSms="Resend SMS in 0:";
exports.resendEmail="Resend Email in 0:";
exports.WrongCode="Wrong Code";


// Customizable Area End