export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const quilaLogo = require("../assets/QuilaLogo.png");
export const googleLogo = require("../assets/GoogleIcon.svg");
export const sideImg = require("../assets/sideImg.png");
export const backIcon = require("../assets/back.svg");
export const visibleEyeIcon = require("../assets/visibleIcon.svg")
export const visibleEyeRedIcon = require("../assets/visibleRedIcon.svg")
export const sideImage = require("../assets/sideImageLoginPage.jpg")
export const pageNotFound = require("../assets/pageNotFound.png")
export const backBtn = require("../assets/backIcon.png")
export const blueSparkImg = require("../assets/blueSpark_img.png")
export const googleLogoImg = require("../assets/googleLogo.svg");