Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Followers";
exports.labelBodyText = "Followers Body";
exports.searchBoxText = "Search a follower";
exports.labelTitleTextFollowing = "Following";
exports.labelTitleTextFollowers = "Followers";
exports.followersApiEndPoint = "bx_block_followers/follows/followers";
exports.followingApiEndpoint = "bx_block_followers/follows/following";
exports.unFollowApisApiEndPoint = "bx_block_followers/follows"
exports.addFollowersApiEndPoint = "bx_block_followers/follows"
exports.addFollowingApiEndPoint = "bx_block_followers/follows"
exports.getFollowersApiMethodType = "GET";
exports.getFollowingApiMethodType = "GET";
exports.deleteApiMethodType = "DELETE"
exports.postApiMethodType = "POST";
exports.userListEndPoint ='account_block/accounts'
exports.userListFollowersEndPoint ='/bx_block_followers/follows/following'
exports.userListFollowingEndPoint ='/bx_block_followers/follows/followers'

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End