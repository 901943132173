import React from "react";

// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  TextField,
  styled,
  Select,
  MenuItem,
  FormControl,
  Button
} from "@material-ui/core";
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox, { CheckboxProps } from '@material-ui/core/Checkbox';
import {
  googleLogoImg,
  signupImg,
  backBtn,
  backgroundWomenImage,
  backBtnImg,
  userImg,
  notFoundImg,
  mobileImg,
  backArrow
} from "./assets"
import EmailAccountRegistrationController, {
  Props
} from "./EmailAccountRegistrationController";
export const configJSON = require("./config");
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

// Customizable Area End

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  renderDate = () => {
    const customIcon = <ExpandMoreIcon style={{ position: "absolute", top: "50%", right: "5px", transform: "translateY(-50%)" }}
    />;
    return (
      <Box className="input_field_box" gridGap={10}>
        <FormControl className="date_select_field">
          <Select
            data-test-id="dobTestID"
            error={this.state.dobMsg}
            style={this.state.dobMsg ? webStyle.dateBox : {}}
            defaultValue={Date}
            className="date_select_field"
            variant="outlined"
            displayEmpty
            labelId="Date"
            inputProps={{ "aria-label": "Without label" }}
            value={this.state.dobPlaceholder}
            onChange={(event: React.ChangeEvent<{ value: unknown }>) => this.handleDateChange({ target: { value: parseInt(event.target.value as string, 10) } })}
            open={this.state.dateFeildOpen} 
            onClose={this.handleDateFieldClose}
            onOpen={this.handleDateFieldOpen} 
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "5px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => <div data-test-id="dobDate" onClick={this.handleDateIconClick} style={webStyle.cursor}>
            {customIcon} 
          </div>}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.dobPlaceholder}>{this.state.dobPlaceholder}</MenuItem>
            {this.state.dayValue.map((item: number) => (
              <MenuItem key={item} value={item} style={webStyle.dateStyle}
              >
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl className="date_select_field">
          <Select
            data-test-id="dobMonthTestID"
            error={this.state.monthMsg}
            style={this.state.monthMsg ? webStyle.dateBox : {}}
            className="date_select_field"
            variant="outlined"
            displayEmpty
            labelId="Date"
            inputProps={{ "aria-label": "Without label" }}
            placeholder="date"
            value={this.state.monthPlaceholder}
            onChange={this.handleMonthChange}
            open={this.state.monthFeildOpen} 
            onClose={this.handleMonthFieldClose} 
            onOpen={this.handleMonthFieldOpen} 
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "5px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => <div data-test-id="dobDate" onClick={this.handleMonthIconClick} style={webStyle.cursor}>
            {customIcon} 
          </div>}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.monthPlaceholder}>{this.state.monthPlaceholder}</MenuItem>
            {this.state.monthValue.map((item: { id: number; monthTitle: string }) => (

              <MenuItem key={item.id} value={item.id} style={webStyle.dateStyle}
              >
                {item.monthTitle}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <>
        <Box style={webStyle.errorContainer}>
        {this.state.yearMsg&& (
          <Box sx={{...webStyle.customTooltip, width: this.state.dobError
            ? { xs: "80%", lg: "60%" }
            : "100%",marginTop:"-30px",  marginLeft: this.state.dobError
            ? { xs: "95px", lg: "250px" }
            : "",
         
          }}
          >
            <Typography style={webStyle.validationText}>{!this.state.dobError?configJSON.dobErrorTextMsg:configJSON.dobErrorText}</Typography>
            <Box sx={{...webStyle.positionContainer,left:!this.state.dobError?"20%":{xs:"70%",md:"50%"}}}
            />
          </Box>
        )}
      </Box>
        <FormControl className="date_select_field" style={webStyle.cursor}>
          <Select
            data-test-id="dobYearTestID"
            error={this.state.yearMsg}
            className="date_select_field"
            style={this.state.yearMsg ? webStyle.dateBox : {}}
            variant="outlined"
            displayEmpty
            labelId="MonthDate"
            inputProps={{ "aria-label": "Without label" }}
            onChange={this.handleYearChange}
            open={this.state.yearFeildOpen} 
            onClose={this.handleYearFieldClose} 
             onOpen={this.handleYearFieldOpen}  
            placeholder="Month"
            value={this.state.yearPlaceholder}
            MenuProps={{
              anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
              },
              transformOrigin: {
                vertical: "top",
                horizontal: "left"
              },
              PaperProps: {
                style: {
                  borderRadius: "5px",
                  height: "185px"
                }
              },
              getContentAnchorEl: null,
              keepMounted: true
            }}
            IconComponent={() => <div data-test-id="dobDate" onClick={this.handleYearIconClick} style={{cursor:"pointer"}}>
            {customIcon} 
          </div>}
          >
            <MenuItem style={{ display: "none" }} disabled value={this.state.yearPlaceholder}>{this.state.yearPlaceholder}</MenuItem>
            {this.state.yearValue.map((item: number) => (
              <MenuItem key={item} value={item} style={webStyle.dateStyle}>
                {item}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        </>
      </Box>
    )
  }

  renderSignupBox = () => {
    const getBorderBoxStyle = () => {
      return this.state.isLogin ? "1px solid #6551ea" : "1px solid #757676";
    };

    const getBoxTextColor = () => {
      return this.state.isLogin ? "#6551ea" : "#757676";
    };
    return (
      <LoginSignupBox>
        <LoginText
         data-test-id="toggleID"
          style={{
            borderBottom: getBorderBoxStyle(),
            color: getBoxTextColor(),
          }}
          onClick={() => this.toggleForm(false)}>
          {configJSON.loginText}
        </LoginText>
        <LoginText style={{ borderBottom: this.state.isLogin ? "1px solid #757676" : "1px solid #6551ea", color: this.state.isLogin ? "#757676" : "#6551ea" }}
         >{configJSON.btnTextSignUp}</LoginText>
      </LoginSignupBox>
    )
  };

  renderFirstNameText =()=>{
    return(
      <>
      <Box style={webStyle.errorContainer}>
        {this.state.fnameError && (
          <Box sx={{...webStyle.customTooltip,}}
          >
            <Typography style={webStyle.validationText}> {
                  configJSON.firstNameErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </Box>
      <>
      <TextField
      data-test-id="fisrtnameID"
      error={this.state.fnameError}
      label={
        this.state.fname ? (
          ""
        ) : (
          <span>
            {configJSON.firstName}
            <span>
             {this.state.fnameError&&"*"} 
            </span>
          </span>
        )
      }
      variant="outlined"
      value={this.state.fname}
      style={webStyle.textInput}
      className="input_config"
      onChange={(e) => this.setFname(e.target.value)}
      InputLabelProps={{
        disableAnimation: true,
        shrink: false,
        style: {
          color: this.state.fnameError ? "#E53881" : "#757676",
          opacity: 1,
        }
      }}
      InputProps={{
        style: {
          color: this.state.fnameError ? "#E53881" : "#757676",
          borderRadius:"6px",
        }
      }}
      inputProps={{
        style: {
            border: this.state.fnameError ? "1px solid #E53881" : "",
            borderRadius: "6px",
        }
    }}
    />
     </>
    </>
    )
  }

  renderEmailText = () => {
    return (
      <>
      <EmailBoxContainer >
        {this.state.emailError&& (
          <Box className="email_error_width" sx={{...webStyle.customTooltip,...webStyle.emailErrorWidth}}
          >
            <Typography style={webStyle.validationText}>{configJSON.emailErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </EmailBoxContainer>
      <EmailBoxContainer >
        {this.state.apiEmailError && (
          <Box className="api_error_width" sx={{...webStyle.customTooltip, ...webStyle.apiEmailErrorWidth}}>
            <Typography style={webStyle.validationText}>
              {this.state.apiEmailError}
            </Typography>
            <Box sx={webStyle.positionContainer}/>
          </Box>
        )}
      </EmailBoxContainer>
      <Box style={webStyle.emailContainer}>
      <TextField
        data-test-id="emailTestID"
        error={Boolean(this.state.emailError || this.state.apiEmailError)}
        label={
          this.state.emailID ? (
            ""
          ) : (
            <span>
              {configJSON.emailLable}
              <span>
               {this.state.emailError&&"*"} 
              </span>
            </span>
          )
        }
        variant="outlined"
        className="input_config"
        style={webStyle.inputEmailStyle}
        value={this.state.emailID}
        onChange={(e) => this.setEmail(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.emailError || this.state.apiEmailError ? "#E53881" : "#757676",
            opacity: 1,
          }
        }}
        InputProps={{
          style: {
            color: this.state.emailError || this.state.apiEmailError ? "#E53881" : "#757676",
            borderRadius:"6px"
          }
        }}
        inputProps={{
          style: {
              border: this.state.emailError || this.state.apiEmailError ? "1px solid #E53881" : "",
              borderRadius: "6px"
          }
      }}
        />
        </Box>
      </>
    )
  }

  renderPasswordText = () => {
    return (
      <>
      <PasswordBoxContainer >
        {this.state.createPassError && (
          <Box className="password_error_width" sx={{...webStyle.customTooltip,...webStyle.passwordErrorWidth}}
          >
            <Typography style={webStyle.validationText}>{configJSON.passMsg}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </PasswordBoxContainer>
      <>
      <TextField
        data-test-id="paswordTestID"
        type="password"
        error={this.state.createPassError}
        label={this.state.createPassword ? " " : "Create a password (8-16 characters)"}
        variant="outlined"
        style={webStyle.inputEmailStyle}
        value={this.state.createPassword}
        className="input_config"
        onChange={(e) => this.setPassword(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.createPassError ? "#E53881" : "#757676",
            opacity: 1,
          }
        }}
        InputProps={{
          style: {
            color: this.state.createPassError ? "#E53881" : "#757676",
            borderRadius:"6px"
          }
        }}
        inputProps={{
          style: {
              border: this.state.createPassError ? "1px solid #E53881" : "",
              borderRadius: "6px"
          }
      }}
      />
      </>
      </>
    )
  }

  renderLastNameText = () => {
    return (
      <>
      <ErroTextContainer>
      <Box style={webStyle.errorContainer}>
        {this.state.lnameError && (
          <Box sx={{...webStyle.customTooltip,...webStyle.lastNameMarginLeft}} className="last_name_error_box"
          >
            <Typography style={webStyle.validationText}>{configJSON.lastNameErrorText}</Typography>
            <Box sx={webStyle.positionContainer}
            />
          </Box>
        )}
      </Box>
      </ErroTextContainer>
      <>
      <TextField
        data-test-id="lasttnameID"
        error={this.state.lnameError}
        label={
          this.state.lname ? (
            ""
          ) : (
            <span>
              {configJSON.lastName}
              <span>
               {this.state.lnameError&&"*"} 
              </span>
            </span>
          )
        }
        variant="outlined"
        style={webStyle.textInput}
        value={this.state.lname}
        className="input_config"
        onChange={(e) => this.setLname(e.target.value)}
        InputLabelProps={{
          disableAnimation: true,
          shrink: false,
          style: {
            color: this.state.lnameError ? "#E53881" : "#757676",
            opacity: 1,
          }
        }}
        InputProps={{
          style: {
            color: this.state.lnameError ? "#E53881" : "#757676",
            borderRadius:"6px"
          }
        }}
        inputProps={{
          style: {
              border: this.state.lnameError ? "1px solid #E53881" : "",
              borderRadius: "6px"
          }
      }}
      />
      </>
      </>
    )
  }


 renderSignUpBeforePage = () => {
  return (
    <BackgroundContainer>
      <Box style={signUpBeforeStyles.topContainer}>
        <Box style={signUpBeforeStyles.topSubContainer}>
          <Box style={signUpBeforeStyles.backBtnContainer}>
            <Box style={signUpBeforeStyles.backArrowContainer}>
            <BackBtnImage
              src={backArrow} 
              alt="backBtnImg"
              style={signUpBeforeStyles.backBtnImgStyle}
            />
            </Box>
            <Box>
              <Typography style={signUpBeforeStyles.backBtnText} className="back_text">
                {configJSON.back}
              </Typography>
            </Box>
          </Box>
          <Box style={signUpBeforeStyles.backBtnContainer}>
            <Box>
              <Typography style={{...signUpBeforeStyles.backBtnText,cursor:"pointer"}} onClick={this.handleNaviagteToLogin}>
                {configJSON.loginText}
              </Typography>
            </Box>
            <img
              src={userImg}
              alt="userImg"
              style={signUpBeforeStyles.backImgStyle}
            />
          </Box>
        </Box>
      </Box>
      <ButtonContainer>
        <Box className="btnContainer">
          <Button
            className="btnStyle"
            data-test-id="beautyTestId1"
            onClick={()=>this.handleButtonClick(0)}
          >
            {configJSON.forBeautyProfessionals} 
          </Button>
          <Button
            className="btnStyle1"
            data-test-id="beautyTestId2"
            onClick={()=>this.handleButtonClick(1)}
          >
            {configJSON.forBeautyEnthusiasts}
          </Button>
        </Box>
      </ButtonContainer>
    </BackgroundContainer>
  );
};

  renderPageNotFound=()=>{
    return(
       <PageNotFoundContainer className="page_not_found_container">
        <Box style={pageNotFoundStyles.subContainer}>
          <img src={notFoundImg} alt="notFound" style={pageNotFoundStyles.imgStyle}/>
          <Box style={pageNotFoundStyles.guestModeContainer}>
          <Typography className="desktop-text" style={pageNotFoundStyles.notFoundText} data-test-id="pageNotFound">{configJSON.pageNotFoundText}</Typography>
          <Box className="mobile-text">
          <Typography style={pageNotFoundStyles.notFoundText} >{configJSON.opps}</Typography>
          <Typography className="oops_text" style={{...pageNotFoundStyles.notFoundText,...pageNotFoundStyles.textWidth}}>{configJSON.pageNotFound}</Typography>
          </Box>
          <Typography style={pageNotFoundStyles.notFoundDesc} className="not_found_desc">{configJSON.notFoundDesc}</Typography>
          </Box>
          
          <Button className="tryAgainBtn" onClick={this.handleTryAgain}>{configJSON.pleaseTryAgain}</Button>
          </Box>
       </PageNotFoundContainer>
    )
  }

  renderSkipAccountRegistration=()=>{
    return(
   <SkipAccountContainer>
        <Box style={skipAccountRegistrationStyles.subContainer}>
          <Box className="guest_text_container" style={skipAccountRegistrationStyles.guestModeContainer}>
          <Typography style={skipAccountRegistrationStyles.guestModeText} data-test-id="guestMode">{configJSON.guestMode}</Typography>
          <Typography style={skipAccountRegistrationStyles.guestModeDesc} className="guest-mode_desc">{configJSON.guestModeDes}</Typography>
          </Box>
          <Box className="btnContainer">
          <Button className="continueBookingBtn" data-test-id="continueBtn" onClick={this.handleContinueBoooking}>{configJSON.continueBooking}</Button>
          </Box>
          </Box>
       </SkipAccountContainer>
    )
    
  }

  renderContent = () => {
    const { skipRegistration, isRedirectingToPageNotFound } = this.state;
  
    if (skipRegistration) {
      return this.renderSkipAccountRegistration();
    } else {
      return (
        <MainTextinputContainer>
          <form onSubmit={this.handleCheck} data-test-id="signInBtn">
            <Box className="login_box">
              <Box className="tab_box">
                <Typography className="get_to_know_text" data-test-id="lestgettoknow">
                  {configJSON.letsgettoknow}
                </Typography>
                <Typography className="create_your_account_text">
                  {configJSON.createyouraccount}
                </Typography>
              </Box>
              <Typography style={webStyle.lableText}>{configJSON.nameText}</Typography>
              <Box className="input_field_box">
                {this.renderFirstNameText()}
                {this.renderLastNameText()}
              </Box>
              <Typography style={webStyle.lableText}>{configJSON.emailText}</Typography>
              {this.renderEmailText()}
              <Typography style={webStyle.lableText}>{configJSON.passText}</Typography>
              {this.renderPasswordText()}
              <Typography style={{ ...webStyle.lableText, color: this.state.yearMsg ? "#E53881" : "#334155" }}>
                {configJSON.dobText}
              </Typography>
              <Box style={webStyle.dateContainer}>
              {this.renderDate()}
              </Box>
              <Box style={webStyle.singupBtnContainer}>
                <Box style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Typography className="login_desc_txt" style={{ textAlign:"center"}}>
                  {configJSON.descriptionText}{' '}
                  <span className="login_desc_span_txt" data-test-id="termsAndConditions" onClick={this.handleTermsAndConditions}>{configJSON.descriptionTextTwo}</span>
                </Typography>
                </Box>
                {this.state.apiError&& (
                  <Box sx={{...webStyle.customTooltip,...webStyle.apiErrorWidth,zIndex:"99"}}
                  >
                    <Typography style={webStyle.apiValidationText}>{this.state.apiError}</Typography>
                    <Box sx={webStyle.positionContainer}
                    />
                  </Box>
                )}
                <Button className="singup_btn" type="submit">
                  {configJSON.btnTextSignUp}
                </Button>
                <Box className="social_login_box">
                  <img className="social_login_img" src={googleLogoImg} alt="Google Logo" />
                  <Typography className="social_login_txt">{configJSON.btnTxtSocialLogin}</Typography>
                </Box>
              </Box>
              <Typography style={webStyle.skipRegistration} data-test-id="skipRegistration" onClick={this.handleSkipRegistration}>
                {configJSON.skipAccountText}
              </Typography>
            </Box>
          </form>
        </MainTextinputContainer>
      );
    }
  };
  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <>
      <style>
      {`
        .css-view-1dbjc4n {
          overflow-x: hidden; 
        }
      `}
    </style>
 <Box className="css-view-1dbjc4n">
      {this.state.showSignUpForm ? (
        <Grid container>
          <Grid item xs={12} sm={6} md={6} lg={6} xl={6} >
            <Box>
              <BoxContainer>
                <Box style={webStyle.backBtnContainer}>
                  <img
                    src={backBtn}
                    alt="backBtn"
                    style={webStyle.backBtnImgStyle}
                    data-test-id="backBtnClick" onClick={this.handleBackButtonClick}
                  />
                  <BackText>{configJSON.back}</BackText>
                </Box>
                <Box>
                  <Typography style={webStyle.loginTextt} data-test-id="login" onClick={this.handleNaviagteToLogin}>
                    {configJSON.loginText}
                  </Typography>
                </Box>
              </BoxContainer>
              <Box style={webStyle.scrollbar}>
              {this.renderContent()}
              </Box>
            </Box>
          </Grid>
          <Grid item xs={false} sm={6} md={6} lg={6} xl={6}>
            <Box>
              <SideImaViewContainer src={signupImg} alt="login image" />
            </Box>
          </Grid>
        </Grid>
      ) : (
        this.renderSignUpBeforePage()
      )}
    </Box>
      </>
      // Customizable Area End
    );
  }
}




// Customizable Area Start

function StyledCheckbox(props: CheckboxProps) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      inputProps={{ 'aria-label': 'decorative checkbox' }}
      {...props}
    />
  );
}

const useStyles = makeStyles({
  root: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  icon: {
    borderRadius: 6,
    width: 16,
    height: 16,
    boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
    backgroundColor: '#f5f8fa',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
    '$root.Mui-focusVisible &': {
      outline: '2px auto rgba(19,124,189,.6)',
      outlineOffset: 2,
    },
    'input:disabled ~ &': {
      boxShadow: 'none',
      background: 'rgba(206,217,224,.5)',
    },
  },
  checkedIcon: {
    backgroundColor: '#6551EA',
    backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
    '&:before': {
      display: 'block',
      width: 16,
      height: 16,
      backgroundImage:
        "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
        " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
        "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
      content: '""',
    },
    'input:hover ~ &': {
      backgroundColor: '#6551EA',
    },
  },
});


const MainTextinputContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5%",
  margin:"32px",
  padding:"0px",
  "@media (min-width:1024px)":{
    padding:"0px 50px",
  },
  "& .login_box": {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    "@media (max-width:598px)": {
      width: "340px"
    }
  },

  "& .tab_box": {
    marginBottom: "37px"
  },

  "& .tab_btn_txt": {
    textAlign: "center",
    borderBottom: "1px solid #757676",
    padding: "10px 0",
    cursor: "pointer",
    width: "48%",
    fontFamily: "normal",
    fontSize: "18px",
    fontWeight: 700,
    color: "#757676"
  },

  "& .input_field_box": {
    display: "flex",
    justifyContent: "space-between",
    marginBottom:"20px",
    position:"relative"
  },

  "& .input_field_config": {
    color: "red",
    "& .MuiOutlinedInput-root": {
      height: "55px",
      borderRadius:"8px"
    },

    "& .MuiOutlinedInput-input": {
      padding: "11px 14px 10px",
      color:"#757676",
      fontWeight:400,
      fontFamily:"Inter",
      fontSize:"16px"
    }
  },
"& .input_config":{
    '& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline': {
        border: 'none', 
    },
},
  "& .date_select_field": {
    width: "100%",
    height: "44px",
    color: "#757676",
    borderRadius: "6px",
    "@media (max-width:598px)": {
      width: "108px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px"
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline":{
       border:"none",
    }
  },

  "& .date_select_fieldError": {
    width: "100px",
    height: "44px",
    color: "red",
    background: "rgba(229, 57, 53, 0.15)",
    borderRadius: "6px",
    "@media (max-width:598px)": {
      width: "90px"
    },
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px",
      background: "rgba(229, 57, 53, 0.15)"
    }
  },

  "& .year_select_field": {
    width: "123px",
    height: "44px",
    color: "#757676",
    "& .MuiOutlinedInput-input": {
      padding: "11px 14px"
    }
  },

  "& .divider_box": {
    display: "flex",
    justifyContent: "space-between",
    width: "inherit",
    alignItems: "center",
    marginBottom: "26px",
    marginTop:"14px"
  },

  "& .divider_txt": {
    color: "#757676",
    fontFamily: "Inter",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "140%",
    letterSpacing: "-0.12px"
  },

  "& .divider_line": {
    height: "1.1px",
    width: "45%",
    backgroundColor: "#D9D9D9"
  },

  "& .social_login_box": {
    border: "1px solid #D9D9D9",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "44px",
    borderRadius: "6px",
    marginBottom: "56px",
    cursor: "pointer",
    marginTop:"15px"
  },

  "& .social_login_img": {
    position: "absolute",
    left: "12px",
    top: "12px"
  },

  "& .social_login_txt": {
    fontSize: "18px",
    fontWeight: 600,
    lineHeight: "25.2px",
    fontStyle: "normal",
    fontFamily:"Degular"
  },

  "& .login_desc_txt": {
    fontSize: "10px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "14px",
    marginBottom: "26px",
    fontFamily:"Inter",
    width:"100%",
    color:'#757676'
  },

  "& .login_desc_span_txt": {
    color: "#6551EA",
    fontWeight:400,
    fontSize: "10px",
    lineHeight: "14px",
    cursor:"pointer"
  },

  "& .singup_btn": {
    height: "44px",
    borderRadius: "6px",
    backgroundColor: "#6551EA",
    color: "#FFF",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "25.2px",
    width:"100%",
    textTransform:"capitalize",
    fontFamily:"Degular",
    position:"relative"
  },
  "& .get_to_know_text":{
    color: "#6551EA",
    fontSize:"28px",
    fontWeight:600,
    lineHeight: "39.2px",
    fontFamily:"Degular Display"
  },
  "& .create_your_account_text":{
    color: "#757676",
    fontSize:"14px",
    lineHeight: "19.6px",
    fontFamily:"Degular Display",
    fontWeight:500,
    marginTop:"4px"
  },
 "& .terms_container":{
  display:"flex",
  alignItems:"center",
  marginLeft:"-10px",
  marginTop:"-10px",
  marginBottom:"30px"
 },
 "& .terms_text":{
  fontSize:"12px",
  fontWeight:400,
  fontFamily:"Inter",
  lineHeight:"18px",
  color:"#757676"
 }

});

const BoxContainer=styled(Box)({
  display:"flex",
  alignItems:"center",
  justifyContent:"space-between",
  padding:"20px 25px 20px 25px",
  "@media (max-width:600px)":{
      padding:"20px 18px",
  }
})


const ErroTextContainer=styled(Box)({
   "& .last_name_error_box":{
    width:'88%',
    "@media (min-width:600px) and (max-width:825px)":{
       width:"100%"
    }
   }
})

const ButtonContainer=styled(Box)({
  "& .btnStyle":{
    backgroundColor:"#6320F2",
    color:"#fff",
    padding:"5px 80px",
    marginBottom:"15px",
    textTransform: "capitalize" as "capitalize",
    borderRadius:"8px",
    fontSize:"18px",
    fontWeight:600,
    height:'44px',
    "@media (max-width:599px)":{
       padding:"5px 58px",
    },
    fontFamily:"Degular",
    "&:hover":{
    backgroundColor: "#4104C2",  
    color: "rgba(255, 255, 255, 0.4)", 
    }
  },
  "& .btnStyle1":{
    backgroundColor:"#6320F2",
    color:"#fff",
    padding: '5px 86.99px',
    marginBottom:"15px",
    textTransform: "capitalize" as "capitalize",
    borderRadius:"8px",
    fontSize:"18px",
    fontWeight:600,
    height:'44px',
    "@media (max-width:599px)":{
       padding:"5px 63px",
    },
    fontFamily:"Degular",
    "&:hover":{
    backgroundColor: "#4104C2",  
    color: "rgba(255, 255, 255, 0.4)",
    }
  },
  "& .btnContainer":{
    position: 'absolute' as "absolute",
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    flexDirection: 'column' as "column",
    alignItems: 'center',
    zIndex: 1, 
    width:"100%",
    '@media (max-width: 598px)': {
      width: '100%',
    },
  },
})

const LoginSignupBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "inherit",
  marginBottom: "32px"

})

const LoginText = styled(Typography)({
  textAlign: "center",
  padding: "10px 0",
  cursor: "pointer",
  width: "48%",
  fontSize: "17px",
  fontWeight: "bold",
  fontFamily:"Degular Display !important"
})


const BackBtnImage = styled("img")({
  width: "100%",
  cursor:"pointer",
  objectFit: "cover",
  "@media (max-width:598px)": {
    flexDirection: "column",
    width: "100%"
  }
})

const SideImaViewContainer = styled("img")({
  width: "100%",
  height: "100vh",
  objectFit: "cover",
  opacity: "1",
  display: "flex",
  "@media (max-width: 599px)": {
    display: "none"
  }
})
const BackText = styled(Typography)({
  color: "#757676",
  fontSize: "16px",
  display: "block",
  fontWeight:500,
  fontFamily:"Degular Display",
  "@media (max-width: 598px)": {
   display:"none"
  }
})
  

const webStyle = {
  textInput: {
    width: "48%",
  },

  inputEmailStyle: {
    width: "100%",
    marginBottom: "20px"
  },

  passwordText: {
    color: "#757676",
    fontSize: "12px",
    marginBottom: "15px",
    marginLeft: "10px"
  },

  passwordTextError: {
    color: "red",
    fontSize: "12px",
    marginBottom: "15px",
    marginLeft: "10px"
  },

  dobStyleError: {
    color: "red",
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "5px",
    fontWeight: 500
  },

  dobStyle: {
    color: "#757676",
    fontSize: "12px",
    marginBottom: "10px",
    marginLeft: "5px",
    fontWeight: 500
  },
  scrollbar:
  {overflowY:"scroll" as "scroll",height:"85vh",scrollbarWidth: "none" as "none" },
  dateStyle: {
    fontWeight: 400,
    fontStyle: "normal",
    lineHeight: "140%",
    fontSize: "14px"
  },

  dateBox: {
    color: "#E53881",
    border:"1px solid #E53881",
    borderRadius: "6px",
  },
  lableText:{
    color:"#334054",
    fontSize:"12px",
    lineHeight: "16.8px",
    marginBottom:"4px",
    fontWeight:600,
    fontFamily:"Degular",
  },
  singupBtnContainer:{
    marginTop:"80px"
  },
  topContainer:{
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    padding:"20px 40px",
  },
  loginTextt:{
    color:"#757676",
    fontSize:"16px",
    fontFamily:"Degular Display",
    fontWeight:500,
    cursor:"pointer"
  },
  
  backBtnContainer:{
    display:"flex",
    alignItems:"center",
    justifyContent:"center",
    gap:10
  },
  customTooltip: {
    marginTop: '-25px',
    backgroundColor: '#E53881',
    color: 'white',
    padding: '8px 10px',
    position: 'relative',
    ZIndex:99,
    borderRadius:"6px",
  },
  positionContainer: {
     position: 'absolute',
     top: '100%',
     left: '20%',
     transform: 'translateX(-50%)',
     borderTop: '10px solid #E53881',
     borderLeft: '10px solid transparent',
     borderRight: '10px solid transparent',          
  },
  validationText:{
    fontSize:"10px",
    color:"#fff",
    fontWeight:500,
    fontFamily:"Degular"
  },
  apiValidationText:{
    fontSize:"10px",
    color:"#fff",
    fontWeight:500
  },
  skipRegistration:{
    color:"#6551EA",
    fontSize:"12px",
    textAlign:"center" as "center",
    cursor:"pointer",
    fontFamily:"Inter",
    fontWeight:500,
    lineHeight:"16.8px",
    marginTop:"-18px"
  },
  backBtnImgStyle:{
    width:'40px',
    height:"40px",
    cursor:"pointer"
  },
  cursor:{
    cursor:"pointer"
  },
  errorContainer:{position:"absolute" as "absolute",zIndex:99,},
  emailErrorWidth:{width:"60%"},
  apiEmailErrorWidth:{width:"45%"},
  emailContainer:{position:'relative' as "relative"},
  passwordErrorWidth:{width:"70%"},
  apiErrorWidth:{width:"65%"},
  lastNameMarginLeft:{marginLeft:"15px",},
  dateContainer:{position:'relative' as "relative"}
}

const BackgroundContainer=styled(Box)({ 
  width: '100%',
  height: '100vh',
  position: 'relative',
  backgroundImage: `url(${backgroundWomenImage})`,
  backgroundSize: 'cover', 
  backgroundPosition: 'center', 
  backgroundRepeat: 'no-repeat', 
  overflow: 'hidden',
  '@media (max-width: 598px)': {
    backgroundImage: `url(${mobileImg})`,
    backgroundPosition:"unset"
  },
  "& .back_text":{
    display:"block",
    "@media (max-width:598px)":{
      display:"none"
    }
  }
})
const signUpBeforeStyles = {
  topContainer: {
    position: 'absolute' as 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    zIndex: 1,
  },
  topSubContainer: {
    padding: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  backBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 10,
  },
  backImgStyle: {
    cursor: 'pointer',
  },
  backBtnImgStyle:{
     width:'5px',
     height:"10px"
  },
  backBtnText: {
    color: '#fff',
    fontSize: '16px',
    fontFamily: 'Degular',
    fontWeight: 500,
    lineHeight:"22.4px"
  },
  backArrowContainer:{
    backgroundColor:"rgba(217, 217, 217, 0.15)",
    width:'40px',
    height:'40px',
    borderRadius:"100px",
    cursor:"pointer",
    display:"grid",
    placeItems:"center",
    filter: "blur(0px)"
  }
};

const pageNotFoundStyles={
  subContainer:{
    textAlign:"center" as "center",
    padding:"20px"
  },
  imgStyle:{
    marginBottom:30
  },
  notFoundText:{
    marginBottom:10,
    color:"#000",
    fontSize:"48px",
    fontWeight:700,
    fontFamily:"Degular",
    lineHeight:"76.8px",
    width:'100%'
  },
  guestModeContainer:{
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  notFoundDesc:{
    marginBottom:80,
    color:"#757676",
    fontSize:"16px",
    fontWeight:400,
    fontFamily:"Degular",
    lineHeight:"22.4px",
    textAlign:"center" as "center"
  },
  tryAgainBtn:{
  backgroundColor:"#6551EA",
  color:"#fff",
  borderRadius:"6px",
  textTransform:"capitalize" as "capitalize",
  fontWeight:600,
  fontSize:"18px",
  fontFamily:"Degular",
  width:"100%",
  height:"44px"
  },
  width:{width:"60%"},
  textWidth:{fontSize:"40px"}
}

const skipAccountRegistrationStyles={
  subContainer:{
    textAlign:"center" as "center",
    padding:"20px"
  },
  imgStyle:{
    marginBottom:30
  },
  guestModeText:{
    marginBottom:10,
    color:"#000",
    fontSize:"48px",
    fontWeight:700,
    fontFamily:"Degular",
    lineHeight:"38.4px",
    width:'100%'
  },
  guestModeContainer:{
    display:"flex",
    flexDirection:"column" as "column",
    alignItems:"center",
  },
  guestModeDesc:{
    color:"#757676",
    fontSize:"18px",
    fontWeight:400,
    fontFamily:"Degular",
    lineHeight:"25.4px",
    textAlign:"center" as "center",
    marginTop:"10px"
  },

  textWidth:{fontSize:"40px"}
}

const PageNotFoundContainer=styled(Box)({
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  marginTop:"75px",
  "& .page_not_found_container":{
    padding:"10px",
    "@media (max-width:599px)":{
       padding:"18px"
    }
  },
  "& .not_found_desc":{
    textAlign:"center",
      width:"80%",
      "@media (max-width:599px)":{
      width:"100%",
      textAlign:"center"
    }
  },
  "& .oops_text":{
    marginTop:"-30px",
 },
  "& .tryAgainBtn":{
    backgroundColor:"#6551EA",
    color:"#fff",
    borderRadius:"6px",
    textTransform:"capitalize" as "capitalize",
    fontWeight:600,
    fontSize:"18px",
    fontFamily:"Degular",
    width:"60%",
    "@media (max-width:599px)":{
      width:"100%",
      marginTop:"150px"
    }
  },
  
  "& .desktop-text": {
    display: "block",
    "@media (max-width: 599px)": {
      display: "none",
    },
  },
  "& .mobile-text": {
    display: "none",
    
    "@media (max-width: 599px)": {
      display: "block",
    },
  },
})

const SkipAccountContainer=styled(Box)({
  display:"flex",
  alignItems:"center",
  justifyContent:"center",
  padding:"10px",
  height:"95%",
  "& .guest-mode_desc":{
    width:"55%",
 "@media (max-width:599px)":{
      width:"110%"
  },
},
"& .continueBookingBtn":{
    backgroundColor:"#6551EA",
    color:"#fff",
    borderRadius:"6px",
    textTransform:"capitalize" as "capitalize",
    fontWeight:600,
    fontSize:"18px",
    fontFamily:"Degular",
    height:"44px",
    width:"60%",
   
    "@media (max-width:599px)":{
      width:"100%",
    }
  },
  "& .btnContainer":{
    marginTop:60,
  "@media (max-width:599px)":{
      marginTop:150
    }
  },
  "& .guest_text_container":{
     marginTop:0,
     padding:0,
     "@media (max-width:599px)":{
      marginTop:150,
      padding:"12px"
    }
  }
})

const EmailBoxContainer=styled(Box)({
    "& .email_error_width":{
      width:"40%",
      "@media (max-width:598px)":{
         width:"70%"
      }
    }
})

const PasswordBoxContainer=styled(Box)({
    "& .password_error_width":{
      width:"70%",
      "@media (max-width:598px)":{
         width:"90%"
      }
    }
})
// Customizable Area End